<template>
  <div>
    <ProcessA
      v-if="tpl == 'a' || tpl == 'A'"
      :stepData="data"
      @update-done="isDone = $event"
      @update-value="stepData = $event"
    />
    <ProcessB
      v-if="tpl == 'b' || tpl == 'B'"
      :stepData="data"
      :detail="detail"
      @update-done="isDone = $event"
      @update-value="stepData = $event"
    />
    <ProcessC
      v-if="tpl == 'c' || tpl == 'C'"
      :stepData="data"
      @update-done="isDone = $event"
      @update-value="stepData = $event"
    />
    <ProcessD
      v-if="tpl == 'd' || tpl == 'D'"
      :stepData="data"
      :detail="detail"
      @update-done="isDone = $event"
      @update-value="stepData = $event"
    />
    <ProcessE
      v-if="tpl == 'e' || tpl == 'E'"
      :stepData="data"
      @update-done="isDone = $event"
      @update-value="stepData = $event"
    />
    <ProcessF
      v-if="tpl == 'f' || tpl == 'F'"
      :stepData="data"
      @update-done="isDone = $event"
      @update-value="stepData = $event"
    />
    <ProcessG
      v-if="tpl == 'g' || tpl == 'G'"
      :stepData="data"
      @update-done="isDone = $event"
      @update-value="stepData = $event"
    />
    <ProcessH
      v-if="tpl == 'h' || tpl == 'H'"
      :stepData="data"
      @update-done="isDone = $event"
      @update-value="stepData = $event"
    />
    <ProcessI
      v-if="tpl == 'i' || tpl == 'I'"
      :stepData="data"
      @update-done="isDone = $event"
      @update-value="stepData = $event"
    />
    <ProcessJ
      v-if="tpl == 'j' || tpl == 'J'"
      :stepData="data"
      :detail="detail"
      @update-done="isDone = $event"
      @update-value="stepData = $event"
    />
    <ProcessK
      v-if="tpl == 'k' || tpl == 'K'"
      :stepData="data"
      @update-done="isDone = $event"
      @update-value="stepData = $event"
    />
    <ProcessL
      v-if="tpl == 'l' || tpl == 'L'"
      :stepData="data"
      @update-done="isDone = $event"
      @update-value="stepData = $event"
    />
    <ProcessM
      v-if="tpl == 'm' || tpl == 'M'"
      :stepData="data"
      @update-done="isDone = $event"
      @update-value="stepData = $event"
    />
    <ProcessN
      v-if="tpl == 'n' || tpl == 'N'"
      :stepData="data"
      :detail="detail"
      @update-done="isDone = $event"
      @update-value="stepData = $event"
    />
    <ProcessO
      v-if="tpl == 'o' || tpl == 'O'"
      :stepData="data"
      @update-done="isDone = $event"
      @update-value="stepData = $event"
    />
    <ProcessP
      v-if="tpl == 'p' || tpl == 'P'"
      :stepData="data"
      @update-done="isDone = $event"
      @update-value="stepData = $event"
    />
    <ProcessQ
      v-if="tpl == 'q' || tpl == 'Q'"
      :stepData="data"
      @update-done="isDone = $event"
      @update-value="stepData = $event"
    />
  </div>
</template>

<script>
import ProcessA from "@/components/trial/process/process_a.vue";
import ProcessB from "@/components/trial/process/process_b.vue";
import ProcessC from "@/components/trial/process/process_c.vue";
import ProcessD from "@/components/trial/process/process_d.vue";
import ProcessE from "@/components/trial/process/process_e.vue";
import ProcessF from "@/components/trial/process/process_f.vue";
import ProcessG from "@/components/trial/process/process_g.vue";
import ProcessH from "@/components/trial/process/process_h.vue";
import ProcessI from "@/components/trial/process/process_i.vue";
import ProcessJ from "@/components/trial/process/process_j.vue";
import ProcessK from "@/components/trial/process/process_k.vue";
import ProcessL from "@/components/trial/process/process_l.vue";
import ProcessM from "@/components/trial/process/process_m.vue";
import ProcessN from "@/components/trial/process/process_n.vue";
import ProcessO from "@/components/trial/process/process_o.vue";
import ProcessP from "@/components/trial/process/process_p.vue";
import ProcessQ from "@/components/trial/process/process_q.vue";
export default {
  props: {
    tpl: {
      type: String,
    },
    data: {
      type: Object,
    },
    detail: Object,
    code: String,
  },
  components: {
    ProcessA,
    ProcessB,
    ProcessC,
    ProcessD,
    ProcessE,
    ProcessF,
    ProcessG,
    ProcessH,
    ProcessI,
    ProcessJ,
    ProcessK,
    ProcessL,
    ProcessM,
    ProcessN,
    ProcessO,
    ProcessP,
    ProcessQ,
  },
  data() {
    return {
      stepData: {},
      isDone: false,
    };
  },
  methods: {},
  watch: {
    isDone(val) {
      if (val !== false) {
        if (typeof val === "boolean") {
          this.$emit("update-done", {stepCode: this.code});
        } else if (typeof val === "string") {
          this.$emit("update-done", {stepCode: this.code, imgs: val});
        } else if (typeof val === "object") {
          this.$emit("update-done", {...val, stepCode: this.code});
        }
        this.isDone = false;
      }
    },
  },
  created() {
    this.stepData = this.data;
  },
};
</script>

<style lang="less" scoped></style>
