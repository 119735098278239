const filterPlatform = (platform) => {
    const platformMap = {
        'TAO_BAO': '淘宝/天猫任务',
        'PDD': '拼多多任务',
        'JD': '京东任务',
        'DOU_YIN': '抖音任务',
    }
    return platformMap[platform] || platform
}

const priceFormat = (value) => {
    if (value == undefined || value == '') {
      return 0
    }
    if (typeof value == "number") {
      return value.toFixed(2);
    } else {
      return 0;
    }
}

const filterTags = (item) => {
    const tags = [];
    if (item.receiveNeedAudit == 1) {
        tags.push("接单需要审核");
    } else {
        tags.push("接单无需审核");
    }
    if (item.searchMethod == "NORMAL_SEARCH") {
        tags.push("普通搜索");
    } else if (item.searchMethod == "DIRECT_SEARCH") {
        tags.push("直通车搜索");
    } else if (item.searchMethod == "SCAN_SEARCH") {
        tags.push("扫码搜索");
    }
    if (item.refundMethod == "PLATFORM") {
        tags.push("平台返款");
    } else if (item.refundMethod == "SELLER") {
        tags.push("商家返款");
    }
    //noScreenShotMode 0 截图模式 1 无截图模式
    if (item.noScreenShotMode == 0) {
        tags.push("有截图模式");
    } else if (item.noScreenShotMode == 1) {
        tags.push("无截图模式");
    }
    // approvedPayMethod NO_HB_NO_CREDIT 禁止花呗、禁止信用卡，YES_HB_NO_CREDIT 支持花呗、禁止信用卡，YES_HB_YES_CREDIT 支持花呗、允许信用卡
    if (item.approvedPayMethod == "NO_HB_NO_CREDIT") {
        tags.push("禁止花呗、禁止信用卡");
    } else if (item.approvedPayMethod == "YES_HB_NO_CREDIT") {
        tags.push("支持花呗、禁止信用卡");
    } else if (item.approvedPayMethod == "YES_HB_YES_CREDIT") {
        tags.push("支持花呗、允许信用卡");
    }
    // device PHONE PC
    if (item.device == "PHONE") {
        tags.push("手机");
    } else if (item.device == "PC") {
        tags.push("电脑");
    }
    // compareNum 0 无需比货 否则 货比xx家
    if (item.compareNum == 0) {
        tags.push("无需比货");
    } else {
        tags.push(`货比${item.compareNum}家`);
    }
    // needChat 0 无需聊天 1 拍前聊天
    if (item.needChat == 0) {
        tags.push("无需聊天");
    } else if (item.needChat == 1) {
        tags.push("拍前聊天");
    }
    // needBrowseComments 0 无需浏览评价 1 需要浏览评价
    if (item.needBrowseComments == 0) {
        tags.push("无需浏览评价");
    } else if (item.needBrowseComments == 1) {
        tags.push("需要浏览评价");
    }
    //needAddCart 0 无需加购 1 需要加购
    if (item.needAddCart == 0) {
        tags.push("无需加购");
    } else if (item.needAddCart == 1) {
        tags.push("需要加购");
    }
    // needCollect 0 无需收藏 1 需要收藏
    if (item.needCollect == 0) {
        tags.push("无需收藏");
    } else if (item.needCollect == 1) {
        tags.push("需要收藏");
    }
    // needShowSpecifiedImg 0 无需晒图1 需要晒图
    if (item.needShowSpecifiedImg == 0) {
        tags.push("无需晒图");
    } else if (item.needShowSpecifiedImg == 1) {
        tags.push("需要晒图");
    }
    // needShowSpecifiedVideo 0 无需晒视频 1 需要晒视频
    if (item.needShowSpecifiedVideo == 0) {
        tags.push("无需晒视频");
    } else if (item.needShowSpecifiedVideo == 1) {
        tags.push("需要晒视频");
    }
    //appointDays 0 立即下单 否则 预约下单
    if (item.appointDays == 0) {
        tags.push("立即下单");
    } else {
        tags.push(`预约${item.appointDays}天下单`);
    }

    //needAddComment 0 无需追评 1 需要追评
    if (item.needAddComment == 0) {
        tags.push("无需追评");
    } else if (item.needAddComment == 1) {
        tags.push("需要追评");
    }

    // confirmDays 0 马上确认 1-5为1-5天 90按物流确认 91系统自确认
    if (item.confirmDays == 0) {
        tags.push("马上确认");
    } else if (item.confirmDays == 90) {
        tags.push("按物流确认");
    } else if (item.confirmDays == 91) {
        tags.push("系统自动确认");
    } else if (item.confirmDays > 0 && item.confirmDays < 6) {
        tags.push(`${item.confirmDays}天后确认`);
    }
    // needAnswer 0 无需回答问题 1 需要回答问题
    if (item.needAnswer == 0) {
        tags.push("无需回答问题");
    } else if (item.needAnswer == 1) {
        tags.push("需要回答问题");
    }
    // needCommercialInsurance 0 无需商保 1 需要商保
    if (item.needCommercialInsurance == 0) {
        tags.push("无需商保");
    } else if (item.needCommercialInsurance == 1) {
        tags.push("需要商保");
    }
    // creditLevel 信用等级 0 不限、淘宝1-5为1-5心，6-10为1-5钻，11为皇冠；拼多多没有；京东1铜牌，2银牌，3金牌，4钻石；抖音1-5为1-5心，6为1钻
    if (item.creditLevel == 0) {
        tags.push("不限信用等级");
    } else if (item.platformType == "TAO_BAO") {
        if (item.creditLevel >= 1 && item.creditLevel <= 5) {
            tags.push(`淘宝${item.creditLevel}心`);
        } else if (item.creditLevel >= 6 && item.creditLevel <= 10) {
            tags.push(`淘宝${item.creditLevel - 5}钻`);
        } else if (item.creditLevel == 11) {
            tags.push(`淘宝皇冠`);
        }
    } else if (item.platformType == "JD") {
        if (item.creditLevel == 1) {
            tags.push(`京东铜牌`);
        } else if (item.creditLevel == 2) {
            tags.push(`京东银牌`);
        } else if (item.creditLevel == 3) {
            tags.push(`京东金牌`);
        } else if (item.creditLevel == 4) {
            tags.push(`京东钻石`);
        }
    } else if (item.platformType == "DOU_YIN") {
        if (item.creditLevel >= 1 && item.creditLevel <= 5) {
            tags.push(`抖音${item.creditLevel}心`);
        } else if (item.creditLevel == 6) {
            tags.push(`抖音1钻`);
        }
    }

    if (item.platformType == "TAO_BAO") {
        // taoScoreNum 限制淘气值
        if (item.taoScoreNum > 0) {
            tags.push(`淘气值${item.taoScoreNum}以上`);
        } else if (item.taoScoreNum == 0) {
            tags.push(`淘气值不限`);
        }
    }
    // sexs 0限女号 1限男号
    if (item.sexs == undefined || item.sexs == '' || item.sexs == '01' || item.sexs == '10') {
        tags.push("不限性别");
    } else if (item.sexs == '1') {
        tags.push("限男号");
    } else {
        tags.push("限女号");
    }

    // 复购限制
    if (item.repeatTookInterval == 0) {
        tags.push("无复购限制");
    } else {
        tags.push("复购限" + item.repeatTookInterval + '天');
    }

    // 评价建议
    if (item.commentPrompt) {
        tags.push("评价建议：" + item.commentPrompt);
    } else {
        tags.push("评价建议：" + '无');
    }
    // 商家留言
    if (item.messagePrompt) {
        tags.push("商家留言：" + item.messagePrompt);
    } else {
        tags.push("商家留言：" + '无');
    }

    return tags
}

const filterPayMethod = (item) => {
    const payMethods = {
        "ALIPAY": "支付宝",
        "WX": "微信",
        "BANK": "银行卡",
        "IAP": "苹果",
    }
    return payMethods[item]
}

const filterCommentLevel = (item) => {
    const commentLevels = {
        "GOOD": "好评",
        "NORMAL": "中评",
        "BAD": "差评",
    }
    return commentLevels[item]
}

export default {
    filterPlatform,
    priceFormat,
    filterTags,
    filterPayMethod,
    filterCommentLevel
}