<template>
    <div class="tab">
        <div
            :class="
                currentTabIndex == 0 ? 'tab-item tab-item_active' : 'tab-item'
            "
            @click="currentTabIndex = 0"
        >
            发送信件
        </div>
        <div
            :class="
                currentTabIndex == 1 ? 'tab-item tab-item_active' : 'tab-item'
            "
            @click="currentTabIndex = 1"
        >
            收件箱
        </div>
        <div
            :class="
                currentTabIndex == 2 ? 'tab-item tab-item_active' : 'tab-item'
            "
            @click="currentTabIndex = 2"
        >
            已发送
        </div>
        <div
            :class="
                currentTabIndex == 3 ? 'tab-item tab-item_active' : 'tab-item'
            "
            @click="currentTabIndex = 3"
        >
            草稿箱
        </div>
        <div
            :class="
                currentTabIndex == 4 ? 'tab-item tab-item_active' : 'tab-item'
            "
            @click="currentTabIndex = 4"
        >
            回收站
        </div>
        <div
            :class="
                currentTabIndex == 5 ? 'tab-item tab-item_active' : 'tab-item'
            "
            @click="currentTabIndex = 5"
        >
            信件导出
        </div>
        <div
            :class="
                currentTabIndex == 6 ? 'tab-item tab-item_active' : 'tab-item'
            "
            @click="currentTabIndex = 6"
        >
            信件清理
        </div>
        <div
            :class="
                currentTabIndex == 7 ? 'tab-item tab-item_active' : 'tab-item'
            "
            @click="currentTabIndex = 7"
        >
            信件设置
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentTabIndex: 0,
        }
    }
};
</script>

<style lang="less" scoped>
.tab {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    .tab-item {
        cursor: pointer;
        text-align: center;
        border: 1px solid #57a0ff;
        color: #57a0ff;
        width: 140px;
        height: 40px;
        line-height: 40px;
        &:nth-child(odd) {
            border-right: none;
            border-left: none;
        }
        &:first-child {
            border-left: 1px solid #57a0ff;
        }
    }

    .tab-item_active {
        background-color: #57a0ff;
        color: #fff;
    }
}
@media (max-width: 520px) {
    .tab {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        justify-content: flex-start;
        overflow-x: auto;
        .tab-item {
            width: 100px;
        }
    }
    ::-webkit-scrollbar {
        display: none;
    }
}
</style>
