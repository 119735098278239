<template>
  <div class="p_main">
    <div class="form">
      <el-form :model="formData" :rules="formRules" status-icon ref="formData" label-width="100px">
        <el-form-item label="充值金额" prop="money">
          <el-input style="width: 200px" v-model.number="formData.money" size="small"></el-input>
          <span style="margin-left: 1rem">元</span>
        </el-form-item>

        <el-form-item label="支付平台" prop="payType">
          <!-- 支付宝图片 -->
          <img
            :src="require('@/assets/image/alipay.svg')"
            class="ml-4"
            style="width: 100px; height: 50px; margin-right: 1rem"
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('formData')" size="small">去充值</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="flex items-center">
      <div class="text-red-500 w-90px text-right">注意：</div>
      <div class="bg-green-400 !text-white !px-2 py-2">
        请务必认真查看付款信息，付款说明（备注）会有相应的数字，付款时一定要写，不然不会自动到账。如忘记写不用担心请联系客服。
      </div>
    </div>
  </div>
</template>

<script>
import { recharge } from "@/api/user";
export default {
  data() {
    return {
      formData: {
        money: null,
        payType: "ALIPAY",
      },
      formRules: {
        money: [
          {
            required: true,
            message: "请填写充值金额",
            trigger: "blur",
          },
        ],
        payType: [
          {
            required: true,
            message: "请选择支付平台",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data } = await recharge(this.formData.money, this.formData.payType);
          console.log(data);
          this.$router.push("/user/pay?ordersId=" + data + "&price=" + this.formData.money);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.p_main {
  padding: 1rem;
  background-color: #fff;

  // .form {}

  .notice {
    padding: 1rem;
    span {
      color: red;
    }
  }
}
</style>
