import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

import user from './modules/user'
import config from './modules/config'

const store = new Vuex.Store({
	modules: {
		user,
		config
	},
	state: {
		statusbarHeight: 0
	},
	mutations: {
		setStatusbarHeight (state, value) {
			state.statusbarHeight = value
		}
	},
	plugins: [
		createPersistedState(),
	],
})

export default store