<template>
  <div class="process-main">
    <div class="process-c-item">请将下面的图片保存到手机相册</div>
    <div class="process-c-item">在淘宝中作为评价图片上传！</div>
    <div class="flex flex-row flex-nowrap">
      <div class="process-c-item flex flex-col" v-for="img in stepData.specifiedImgs.split(',')" :key="img">
        <el-image style="width: 100px; height: 100px; margin-right: 5px;" :src="img" fit="cover"></el-image>
        <!-- 下载图片 -->
        <el-button class="w-20 mt-2" type="success" size="small" round @click="downloadImg(img)"> 下载图片 </el-button>
      </div>
    </div>
    <div class="process-c-item">
      评价：
      <span class="mr-4">{{ stepData.commentPrompt }}</span>
      <el-button type="success" size="small" round @click="copy(stepData.commentPrompt)"> 复制评价 </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    stepData: {
      type: Object,
    },
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        console.log(newValue, oldValue);
        this.$emit("update-done", true);
      },
      deep: true,
    },
  },
  created() {
    // this.data = this.stepData
  },
  mounted() {
    setTimeout(() => {
      this.$emit("update-done", true);
    }, 0);
  },
  data() {
    return {
      isDone: false,
      data: {
        content: "非常好，服务满意",
      },
    };
  },
  methods: {
    downloadImg(url) {
      let a = document.createElement("a");
      a.href = url;
      a.download = "评价图片.jpg";
      a.target = "_blank";

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    copy(str) {
      this.$copyText(str).then(
        () => {
          this.$message.success("已复制");
        },
        () => {
          this.$message.error("复制失败");
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.process-main {
  .process-c-item {
    margin-bottom: 1rem;
  }
}
</style>
