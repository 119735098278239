<template>
  <div class="p_main">
    <div class="msg-main">
      <div class="msg-header">
        <div class="msg-title">{{ item.name }}</div>
        <div class="msg-meta">编号：{{ item.id }}</div>
        <div class="msg-meta">时间：{{ item.createTime }}</div>
      </div>
      <div class="msg-content">{{ item.content }}</div>
      <div class="msg-action">
        <el-button type="primary" size="small" round @click="$router.back()">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { readNotice } from "@/api/notice";

export default {
  data() {
    return {
      item: {},
    };
  },
  mounted() {
    this.item = this.$route.query.item;
    this.setRead();
  },
  methods: {
    async setRead() {
      await readNotice(this.item.id);
    },
  },
};
</script>

<style lang="less" scoped>
.p_main {
  padding: 1rem;
  background-color: #fff;

  .msg-main {
    .msg-border {
      padding: 1rem 2rem;
      border-bottom: 1px solid #ccc;
    }

    .msg-header {
      background-color: #f5f5f5;
      .msg-border;
      line-height: 1.5rem;
      .msg-title {
        color: #000;
        font-size: 1.1rem;
      }
      .msg-meta {
        color: #999;
      }
    }
    .msg-content {
      .msg-border;
    }
    .msg-action {
      background-color: #f5f5f5;
      .msg-border;
    }
  }
}
</style>
