<template>
  <div class="p_main">
    <div class="filter">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="业务：">
          <el-select v-model="searchForm.business" size="small" clearable>
            <el-option v-for="item in businessTypeList" :key="item.label" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>

        <el-form-item label="类型：">
          <el-select v-model="searchForm.type" size="small" clearable>
            <el-option label="收入" value="IN" />
            <el-option label="支出" value="OUT" />
          </el-select>
        </el-form-item>

        <el-form-item label="起止时间：">
          <el-date-picker
            size="small"
            v-model="searchForm.date"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="updateData"> 搜索 </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table w-[100%]">
      <el-table :data="tableData" border style="width: 100%" v-loading="loading">
        <el-table-column prop="amount" label="金额" width="180" >
          <template slot-scope="scope">
            <span>{{ scope.row.amount | priceFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="balance" label="余额" width="180" >
          <template slot-scope="scope">
            <span>{{ scope.row.balance | priceFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="businessType" label="业务" width="180">
          <template slot-scope="scope">
            <span>{{ businessTypeMap[scope.row.businessType] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="direction" label="类型" width="180">
          <template slot-scope="scope">
            <span v-if="scope.row.direction === 'IN'">收入</span>
            <span v-else>支出</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="日期" />
        <el-table-column prop="remark" label="备注" />
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :current-page.sync="page.current"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
const businessTypeMap = {
  PUBLISH_FEE: "发布费",
  PUBLISH_FEE_REFUND: "发布费退还商家",
  ADD_RUSH_FEE: "增加抢单费",
  BUYER_COMMISSION: "试客佣金",
  BUYER_COMMISSION_BUYER_RECEIVED: "试客佣金试客分佣",
  BUYER_COMMISSION_PLATFORM_RECEIVED: "试客佣金平台分佣",
  GOODS_FEE_REFUND: "本金返款",
  GOODS_FEE_GAP_REFUND: "本金差额退还商家",
  RECHARGE: "充值",
  WITHDRAW: "提现",
  WITHDRAW_SERVICE_FEE: "提现服务费",
  WITHDRAW_CANCEL: "提现取消",
  LOWER_TRADE_REBATE: "下级购物返佣",
  RECOM_NEW_REWARD: "拉新奖励",
};
const businessTypeMap2 = {
  PUBLISH_FEE: "发布费",
  BUYER_COMMISSION_BUYER_RECEIVED: "试客佣金",
  GOODS_FEE_REFUND: "本金返款",
  GOODS_FEE_GAP_REFUND: "本金差额退还商家",
  RECHARGE: "充值",
  WITHDRAW: "提现",
  WITHDRAW_CANCEL: "提现取消",
};
// 资金明细
import { get } from "@/api/assetFlow";
import moment from "moment";
export default {
  data() {
    return {
      searchForm: {
        business: null,
        type: null,
        date: [],
      },
      tableData: [],
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      businessTypeMap,
      businessTypeMap2,
      loading: false,
    };
  },
  created() {
    if (!this.user.isLogin) {
      this.$router.push("/login");
    }
  },
  watch: {
    "page.current"(val, oldVal) {
      if (!this.loading) {
        if (val !== oldVal) {
          this.updateData();
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    businessTypeList() {
      return Object.keys(this.businessTypeMap2).map((key) => ({
        label: this.businessTypeMap2[key],
        value: key,
      }));
    },
  },
  methods: {
    async updateData() {
      this.loading = true;

      try {
        const {
          data: { list, page },
        } = await get(
          {
            businessType:
              this.searchForm.business == null || this.searchForm.business == "" ? undefined : this.searchForm.business,
            direction: this.searchForm.type == null || this.searchForm.type == "" ? undefined : this.searchForm.type,
            queryStartTime:
              this.searchForm.date == null || this.searchForm.date.length == 0
                ? undefined
                : moment(this.searchForm.date[0]).format("YYYY-MM-DD"),
            queryEndTime:
              this.searchForm.date == null || this.searchForm.date.length == 0
                ? undefined
                : moment(this.searchForm.date[1]).format("YYYY-MM-DD"),
          },
          this.page
        );
        this.tableData = list;
        this.page = page;
      } finally {
        this.$nextTick(() => {
          this.loading = false;
        });
      }
    },
  },
  mounted() {
    this.updateData();
  },
};
</script>

<style lang="less" scoped>
.p_main {
  padding: 1rem;
  background-color: #fff;

  // .filter {}

  .table {
    margin-bottom: 2rem;
  }
  .pagination {
    text-align: right;
  }
}
</style>
