<template>
  <div class="p_main">
    <div class="user-header">
      <div class="user-header-left">
        <el-image class="avatar" :src="$store.state.user.avatar" fit="cover" />
        <div class="meta">
          <div class="meta-name">
            {{ $store.state.user.loginName }}
            <span class="text-red-500 mx-1" v-if="user.vipType">（{{user.vipType.name}}）</span>
          </div>
          <div class="meta-tag">
            <!-- <div class="meta-tag-item flex flex-row items-center" v-if="user.isPhoneVerified">
              <span>手机认证</span><img src="@/assets/icon/renzheng.png" alt="" />
            </div> -->
            <div class="meta-tag-item flex flex-row items-center" v-if="user.isNameVerified">
              <span>实名认证</span><img src="@/assets/icon/renzheng.png" alt="" />
            </div>
            <div class="meta-tag-item flex flex-row items-center" v-else>
              <span>未实名认证</span>
            </div>
            <!-- <div class="meta-tag-item flex flex-row items-center">
              <span>绑定买号</span><img src="@/assets/icon/renzheng.png" alt="" />
            </div> -->
          </div>
        </div>
      </div>
      <!-- <div class="user-header-rights">
        <div class="rights-item">
          <img src="@/assets/icon/test1.png" alt="" />
          <div>可处理试用</div>
        </div>
        <div class="rights-item">
          <img src="@/assets/icon/test2.png" alt="" />
          <div>可处理投诉</div>
        </div>
      </div> -->
    </div>
    <div class="user-content">
      <div class="flex-row">
        <div class="money-box">
          <div class="money pb-10 pt-16">{{ user.cash.toFixed(2) }} <span class="unit">元</span></div>
          <div class="money-action">
            <div class="money-action-item cursor-pointer" @click="$router.push('/user/recharge')">充值</div>
            <div class="money-action-item cursor-pointer" @click="$router.push('/user/withdrawal')">提现</div>
            <div class="money-action-item cursor-pointer" @click="$router.push('/user/billing')">明细</div>
          </div>
        </div>

        <div v-if="user.role == 'SELLER'" class="btns money-box flex-row" @click="toNewTrial" style="margin-left: 30px; align-items: center; justify-content: center;">
          <div class="money" style="font-size: 20px;">发布新的试用</div>
        </div>
      </div>
      
      <div class="btns flex gap-x-6 flex-row flex-nowrap !p-4 !pl-0 !justify-start">
        <!-- 按钮组：修改资料 -->
        <div
          class="rounded-12px bg-blue-500 flex flex-col text-white w-110px h-110px text-center justify-center"
          @click="$router.push('/user/profile')"
        >
          <i class="el-icon-edit text-24px mb-1"></i>
          修改资料
        </div>
        <!-- 按钮组：实名认证 -->
        <div
          class="rounded-12px bg-blue-500 flex flex-col text-white w-110px h-110px text-center justify-center"
          @click="toCertify"
        >
          <i class="el-icon-s-check text-24px mb-1"></i>
          实名认证
        </div>
      </div>
      <div class="mob-btns">
        <div class="btn-item" v-if="user.role == 'BUYER'" @click="$router.push('/buy')">
          <div class="btn-item-text">买号管理</div>
          <div class="btn-item-icon"><i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="btn-item" @click="$router.push('/user/profile')">
          <div class="btn-item-text">修改资料</div>
          <div class="btn-item-icon"><i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="btn-item" @click="toCertify">
          <div class="btn-item-text">实名认证</div>
          <div class="btn-item-icon"><i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="btn-item" @click="$router.push('/user/message')">
          <div class="btn-item-text">站内信</div>
          <div class="btn-item-icon"><i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="btn-item" v-if="user.role == 'SELLER'" @click="$router.push('/seller/bindshop')">
          <div class="btn-item-text">店铺管理</div>
          <div class="btn-item-icon"><i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="btn-item" v-if="user.role == 'SELLER'" @click="$router.push('/seller/template')">
          <div class="btn-item-text">模板管理</div>
          <div class="btn-item-icon"><i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="btn-item" @click="logout">
          <el-button class="btn-item-text">退出登录</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loginUserInfo } from "@/api/user";
export default {
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    if (this.user.isLogin) {
      this.getLoginUserInfo()
    }
  },
  created() {
    if (!this.user.isLogin) {
      this.$router.push("/login");
    }
  },
  methods: {
    async getLoginUserInfo() {
      const { data } = await loginUserInfo();
      this.$store.commit("user/LOGIN", data)
    },
    toNewTrial() {
      this.$router.push("/seller/trialCreate");
    },
    logout() {
      this.$store.dispatch("user/LOGOUT");
      this.$message.success("退出成功");
      this.$router.push("/login");
    },
    toCertify() {
      if (this.user.isNameVerified) {
        this.$message({
          message: "您已实名认证",
          type: "warning",
        });
      } else {
        this.$router.push("/user/certify");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.p_main {
  padding: 2rem;
  background-color: #fff;

  .user-header {
    display: flex;
    margin-bottom: 2rem;
    .user-header-left {
      flex: 1;
      display: flex;
      align-items: center;
      .avatar {
        width: 100px;
        height: 100px;
        margin-right: 1rem;
      }
      .meta {
        line-height: 250%;
        .meta-name {
          font-size: 1.1rem;
        }
        .meta-tag {
          display: flex;

          .meta-tag-item {
            margin-right: 1rem;
            span {
              vertical-align: middle;
            }
            img {
              width: 1rem;
              height: 1rem;
              margin-left: 3px;
              vertical-align: middle;
            }
          }
        }
      }
    }
    .user-header-rights {
      padding-left: 1rem;
      border-left: 1px dashed #ccc;
      display: flex;
      align-items: center;
      text-align: center;
      .rights-item {
        line-height: 200%;
        margin-right: 1rem;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 60px;
          height: 60px;
        }
      }
    }
  }

  .user-content {
    border: 2px solid #57a0ff;
    padding: 4rem;

    .money-box {
      width: 360px;
      background-color: #ff9a18;
      color: #fff;
      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 1rem;
      .money-name {
        padding: 1rem;
        font-size: 1.2rem;
      }
      .money {
        text-align: center;
        font-size: 2rem;
        .unit {
          font-size: 1.2rem;
        }
      }
      .money-lock {
        text-align: center;
      }

      .money-action {
        margin-top: 1rem;
        text-align: center;
        display: flex;
        justify-content: center;
        background-color: #e88202;
        .money-action-item {
          padding: 1rem;
          font-size: 1rem;
          &:first-child {
            position: relative;
            &::after {
              position: absolute;
              content: "";
              width: 2px;
              height: 30%;
              right: 0;
              top: 37.5%;
              background-color: #fff;
            }
          }
          &:last-child {
            position: relative;
            &::after {
              position: absolute;
              content: "";
              width: 2px;
              height: 30%;
              left: 0;
              top: 37.5%;
              background-color: #fff;
            }
          }
        }
      }
    }

    .mob-btns {
      display: none;
    }

    .btns {
      padding: 2rem;
      display: flex;
      justify-content: space-between;
      .btn-item {
        text-align: center;
        padding: 0 1rem;
        font-size: 1rem;
        img {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}

@media (max-width: 520px) {
  .p_main {
    padding: 0rem;

    .user-header {
      display: flex;
      padding: 0.5rem;
      margin-bottom: 1rem;
      .user-header-left {
        .avatar {
          margin-right: 1rem;
          width: 80px;
          height: 80px;
        }
        .meta {
          line-height: 200%;
          .meta-name {
            font-size: 1rem;
          }
          .meta-tag {
            .meta-tag-item {
              margin-right: 0.5rem;
            }
          }
        }
      }
      .user-header-rights {
        display: none;
      }
    }

    .user-content {
      border: none;
      padding: 0;

      .money-box {
        width: 100%;
        border-radius: 0;
        margin-bottom: 0;
      }

      .btns {
        display: none;
      }

      .mob-btns {
        display: block;
        .btn-item {
          display: flex;
          padding: 1rem;
          position: relative;

          &::after {
            position: absolute;
            content: "";
            width: 90%;
            height: 1px;
            background-color: #eee;
            left: 10%;
            bottom: 0;
          }
          .btn-item-text {
            flex: 1;
          }
          .btn-item-icon {
            width: 1rem;
          }
        }
      }
    }
  }
}
</style>
