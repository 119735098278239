<template>
  <div class="p_main">
    <div class="tab">
      <div :class="currentTabIndex == 0 ? 'tab-item tab-item_active' : 'tab-item'" @click="currentTabIndex = 0">
        个人资料
      </div>
      <div :class="currentTabIndex == 1 ? 'tab-item tab-item_active' : 'tab-item'" @click="currentTabIndex = 1">
        密码管理
      </div>
    </div>

    <div class="grid" v-if="currentTabIndex == 0">
      <div class="grid-item">
        <div class="grid-item-label">说明</div>
        <div class="grid-item-value" style="color: red">会员资料设置后禁止自行修改，如需修改请联系管理员</div>
      </div>
      <div class="grid-item">
        <div class="grid-item-label">会员名</div>
        <div class="grid-item-value">{{ userInfo.loginName }}</div>
      </div>
      <!-- <div class="grid-item">
        <div class="grid-item-label">*真实姓名</div>
        <div class="grid-item-value">
          <el-input class="grid-input" size="small" v-model.trim="userInfo.realName" disabled />
        </div>
      </div> -->
      <div class="grid-item">
        <div class="grid-item-label">真实姓名</div>
        <div class="grid-item-value">{{ userInfo.realName }}</div>
      </div>
      <div class="grid-item">
        <div class="grid-item-label">手机号码</div>
        <div class="grid-item-value">{{ userInfo.phone }}</div>
      </div>
      <div class="grid-item">
        <div class="grid-item-label">性别</div>
        <div class="grid-item-value">
          <el-radio-group v-model="userInfo.sex">
            <el-radio label="FEMALE">先生</el-radio>
            <el-radio label="MALE">女士</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-item-label">所在地区</div>
        <div class="grid-item-value">
          <el-cascader v-model="region" class="grid-input" size="small" :options="regionOptions"></el-cascader>
        </div>
      </div>
      <!-- <div class="grid-item">
        <div class="grid-item-label">*QQ</div>
        <div class="grid-item-value">
          <el-input class="grid-input" size="small" value="12121212" />
          <span class="grid-input-help"> 如果无法临时会话，请</span>
          <span class="grid-input-help !text-blue-400 ml-1" @click="toSetTempChat">点这里设置</span>
        </div>
      </div> -->
      <div class="grid-item">
        <div class="grid-item-label">微信</div>
        <div class="grid-item-value">
          <el-input class="grid-input" size="small" v-model.trim="userInfo.wxAccount" />
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-item-label">QQ</div>
        <div class="grid-item-value">
          <el-input class="grid-input" size="small" v-model.trim="userInfo.qq" />
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-item-label">邮箱</div>
        <div class="grid-item-value">
          <el-input class="grid-input" size="small" v-model.trim="userInfo.email" />
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-item-label">支付宝收款账号</div>
        <div class="grid-item-value">
          <el-input class="grid-input" size="small" v-model.trim="userInfo.alipayAccount" />
          <span class="grid-input-help">收款人姓名必须和实名认证的姓名一致</span>
        </div>
      </div>

      <div class="grid-item relative">
        <div class="grid-item-label h-[100%]">支付宝收款码</div>
        <div class="grid-item-value py-3">
          <uploader v-model="userInfo.alipayReceiveQrcode" :type="'receivecode'" />
        </div>
      </div>

      <div class="grid-item relative">
        <div class="grid-item-label h-[100%]">微信收款码</div>
        <div class="grid-item-value py-3">
          <uploader v-model="userInfo.wxReceiveQrcode" :type="'receivecode'" />
        </div>
      </div>

      <!-- <div class="grid-item relative">
        <div class="grid-item-label h-[100%]">微信号二维码</div>
        <div class="grid-item-value py-3">
          <uploader v-model="userInfo.wxAccount" />
        </div>
      </div> -->

      <div class="grid-item">
        <div class="grid-item-label grid-item_mob_hide">&nbsp;</div>
        <div class="grid-item-value">
          <el-button type="primary" size="small" @click="updateInfo">保存</el-button>
          <el-button type="primary" size="small" @click="$router.go(-1)">返回</el-button>
        </div>
      </div>
    </div>

    <div class="grid" v-if="currentTabIndex == 1">
      <div class="grid-item">
        <div class="grid-item-label">现有密码</div>
        <div class="grid-item-value">
          <el-input class="grid-input" size="small" v-model.trim="passwords.oldPassword"></el-input>
          <span class="grid-input-help">如要更改密码，需输入现有密码</span>
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-item-label">新登录密码</div>
        <div class="grid-item-value">
          <el-input class="grid-input" size="small" v-model.trim="passwords.newPassword"></el-input>
          <span class="grid-input-help">如不更改密码，请留空</span>
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-item-label">重复新密码</div>
        <div class="grid-item-value">
          <el-input class="grid-input" size="small" v-model.trim="passwords.repeat"></el-input>
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-item-label grid-item_mob_hide">&nbsp;</div>
        <div class="grid-item-value">
          <el-button type="primary" size="small" @click="changePassword">保存</el-button>
        </div>
      </div>
    </div>
    <div class="grid mt-4" v-if="currentTabIndex == 1">
      <div class="grid-item">
        <div class="grid-item-label">现有支付密码</div>
        <div class="grid-item-value">
          <el-input class="grid-input" size="small" v-model.trim="capitalPasswords.oldPassword"></el-input>
          <span class="grid-input-help">
            支付密码默认和注册设置密码相同 &nbsp;
            <router-link to="">找回支付密码</router-link>
          </span>
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-item-label">新支付密码</div>
        <div class="grid-item-value">
          <el-input class="grid-input" size="small" v-model.trim="capitalPasswords.newPassword"></el-input>
          <span class="grid-input-help">如不更改支付密码，请留空</span>
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-item-label">重复新支付密码</div>
        <div class="grid-item-value">
          <el-input class="grid-input" size="small" v-model.trim="capitalPasswords.repeat"></el-input>
        </div>
      </div>
      <div class="grid-item">
        <div class="grid-item-label grid-item_mob_hide">&nbsp;</div>
        <div class="grid-item-value">
          <el-button type="primary" size="small" @click="changeCapitalPassword">保存</el-button>
          <el-button type="primary" size="small" @click="$router.go(-1)">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Uploader from "@/components/uploader.vue";
import { regionList, updateInfo, loginUserInfo, changePassword, changeCapitalPassword } from "@/api/user";
// 个人资料
export default {
  components: {
    Uploader,
  },
  data() {
    return {
      userInfo: {
        qq: "",
        wxAccount: "",
        email: "",
        alipayAccount: "",
      },
      currentTabIndex: 0,
      regionOptions: [],
      region: [],
      passwords: {
        oldPassword: "",
        newPassword: "",
        repeat: "",
      },
      capitalPasswords: {
        oldPassword: "",
        newPassword: "",
        repeat: "",
      },
    };
  },
  mounted() {
    console.log("mounted");
    this.updateRegion();
    this.getLoginUserInfo();
  },
  methods: {
    toSetTempChat() {
      window.open("https://www.baidu.com", "_blank");
    },
    async getLoginUserInfo() {
      const { data } = await loginUserInfo();
      this.userInfo = data;
      this.region = [
        String(this.userInfo.provinceCode),
        String(this.userInfo.cityCode),
        String(this.userInfo.districtCode),
      ];
    },
    async updateRegion() {
      const { data } = await regionList();
      this.regionOptions = data;
      if (this.regionOptions == [] || this.regionOptions == undefined || this.regionOptions.length == 0) {
        const { data } = await regionList();
        this.regionOptions = data;
      }
    },
    async updateInfo() {
      const params = {
        // realName: this.userInfo.realName,
        sex: this.userInfo.sex,
        provinceCode: this.region[0],
        cityCode: this.region[1],
        districtCode: this.region[2],
        qq: this.userInfo.qq,
        email: this.userInfo.email,
        wxAccount: this.userInfo.wxAccount,
        alipayAccount: this.userInfo.alipayAccount,
        alipayReceiveQrcode: this.userInfo.alipayReceiveQrcode,
        wxReceiveQrcode: this.userInfo.wxReceiveQrcode,
      };

      await updateInfo(params);
      this.$message.success("保存成功");
    },
    async changePassword() {
      if (this.passwords.newPassword == "") {
        this.$message.error("请输入新密码");
        return;
      } else {
        if (this.passwords.newPassword != this.passwords.repeat) {
          this.$message.error("两次输入的新密码不一致");
          return;
        }
        if (this.passwords.oldPassword == "") {
          this.$message.error("请输入现有密码");
          return;
        }
        await changePassword(this.passwords.oldPassword, this.passwords.newPassword);
        this.$message.success("密码修改成功");
      }
    },
    async changeCapitalPassword() {
      if (this.capitalPasswords.newPassword == "") {
        this.$message.error("请输入新支付密码");
        return;
      }
      if (this.capitalPasswords.newPassword != this.capitalPasswords.repeat) {
        this.$message.error("两次输入的新支付密码不一致");
        return;
      }
      if (this.capitalPasswords.oldPassword == "") {
        this.$message.error("请输入现有支付密码");
        return;
      }
      await changeCapitalPassword(this.capitalPasswords.oldPassword, this.capitalPasswords.newPassword);
      this.$message.success("支付密码修改成功");
    },
  },
};
</script>

<style lang="less" scoped>
.p_main {
  padding: 1rem;
  background-color: #fff;

  .tab {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    .tab-item {
      cursor: pointer;
      text-align: center;
      border: 1px solid #57a0ff;
      color: #57a0ff;
      width: 140px;
      height: 40px;
      line-height: 40px;
      &:last-child {
        border-left: none;
      }
      &:first-child {
        border-right: none;
      }
    }

    .tab-item_active {
      background-color: #57a0ff;
      color: #fff;
    }
  }

  .grid {
    border: 1px solid #ccc;
    .grid-item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ccc;
      min-height: 50px;
      line-height: 50px;

      &:last-child {
        border-bottom: none;
      }
      .grid-item-label {
        width: 30%;
        text-align: right;
        border-right: 1px solid #ccc;
        padding-right: 1rem;
      }
      .grid-item-value {
        padding-left: 1rem;
        flex: 1;
      }

      .grid-input {
        width: 200px;
      }
      .grid-input-help {
        margin-left: 5px;
        color: #999;
      }
    }
  }
}

@media (max-width: 520px) {
  .p_main {
    .grid {
      .grid-item {
        display: block;
        height: auto;
        line-height: 100%;
        padding: 1rem 0.5rem;
        .grid-item-label {
          width: 100%;
          text-align: left;
          border-right: none;
          padding-right: 0;
          margin-bottom: 0.5rem;
          height: 20px;
        }
        .grid-item_mob_hide {
          display: none;
        }
        .grid-item-value {
          padding-left: 0;
          width: 100%;
        }
        .grid-input {
          width: 100%;
        }
        .grid-input-help {
          margin-left: 0;
          margin-top: 0.5rem;
          display: block;
        }
      }
    }
  }
}
</style>
