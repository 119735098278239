<template>
  <div class="authentication-form px-4 pb-12 pt-6 pr-14 rounded-lg">
    <div class="text-2xl font-bold mb-4 pl-4">实名认证</div>
    <el-form :model="form" :rules="rules" ref="form" label-width="120px">
      <el-form-item label="身份证号码" prop="idcardNo">
        <el-input v-model.trim="form.idcardNo" placeholder="请输入身份证号码"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model.trim="form.name" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="银行卡号" prop="bankAccount">
        <el-input v-model.trim="form.bankAccount" placeholder="请输入银行卡号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm" :loading="loading">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { realNameVerify } from "@/api/user";
export default {
  data() {
    return {
      form: {
        idcardNo: "",
        name: "",
        bankAccount: "",
      },
      rules: {
        idcardNo: [
          {
            required: true,
            message: "请输入身份证号码",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        bankAccount: [
          {
            required: true,
            message: "请输入银行卡号",
            trigger: "blur",
          },
        ],
      },
      loading: false,
    };
  },
  methods: {
    submitForm() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          try {
            await realNameVerify({
              ...this.form,
            });

            this.$message({
              message: "认证成功，请提交基本信息",
              type: "success",
            });
            
            var that = this
            setTimeout(function() {
              that.$router.push('/user/profile')
            }, 1000)
          } finally {
            this.loading = false;
          }
        } else {
          console.log("认证信息填写不完整");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.authentication-form {
  max-width: 500px;
  margin: auto;
  background: #fff;
}
</style>
