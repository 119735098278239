import request from "@/plugin/request";

export const getList = (queryParams, page = {
    size: 100,
    current: 1
}) => request.post('/shop/getList', {
    page, queryParams
})

export const deleteShop = (id) => request.get('/shop/delete?id=' + id, { id })

export const addShop = (platformType, name) => request.post('/shop/add', { platformType, name })

export const switchStatus = (id, status) => request.post('/shop/statusSwitch', { id, status })