<template>
  <div class="box">
    <div class="label">商品类目：</div>
    <div class="items">
      <div
        :class="selectIndex == index ? 'item item-active' : 'item'"
        v-for="(item, index) in computedList"
        :key="index"
        @click="change(index)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array, // {value: 1, text: 'XXX'}
    },
    onchange: {
      type: Function,
    },
  },
  data() {
    return {
      selectIndex: 0,
    };
  },
  computed: {
    computedList() {
      // 插入一个全部
      return [{ id: 0, name: "全部类目" }].concat(this.list);
    },
  },
  methods: {
    change(index) {
      this.selectIndex = index;
      const current = this.computedList[index];
      this.onchange(current);
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  display: flex;

  padding: 1rem;
  .label {
    width: 100px;
  }
  .items {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 10px;
    .item {
      cursor: pointer;
    }
    .item-active {
      color: #57a0ff;
    }
  }
}

@media only screen and (max-width: 640px) {
  .box {
    display: block;
    .label {
      display: none;
    }
    .items {
      display: flex;
      white-space: nowrap;
      overflow: auto;
      text-align: center;
      grid-gap: 10px;
      margin: 0;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
</style>
