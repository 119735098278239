import request from "@/plugin/request";

export const goodsCateList = () => request.post('/goodsCate/getList', {
    page: {
        size: 100,
        current: 1
    }, queryParams: {}
})

export const onSellList = (page, queryParams) => request.post('/task/getOnSellList', {
    page, queryParams
})

export const applyTrail = (data) => request.post('/task/apply', data)

export const linkMatch = (data) => request.post('/task/linkMatch', data)