<template>
  <div class="flex flex-row upload-group">
    <uploader
      :value="item"
      @input="(e) => change(item, e, index)"
      v-for="(item, index) in value"
      :key="item + '_' + index + '_' + i"
      :disabled="disabled"
       :type="type"
    />
    <uploader :type="type" @input="addImage" v-if="!disabled && value.length < max" :key="'upload' + i" ref="upload" />
  </div>
</template>

<script>
import uploader from "./uploader";

export default {
  props: {
    value: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 5,
    },
    type: {
      type: String,
      default: "",
    },
  },
  components: {
    uploader,
  },
  data() {
    return {
      i: 0,
    };
  },
  methods: {
    change(oldValue, newValue, index) {
      if (newValue == "") {
        const newValues = JSON.parse(JSON.stringify(this.value));
        newValues.splice(index, 1);
        this.$emit("input", newValues);
      } else {
        this.$emit(
          "input",
          this.value.map((item, i) => (i === index ? newValue : item))
        );
      }
      this.i += 1;
      this.$forceUpdate();
    },
    addImage(image) {
      this.$emit("input", [...this.value, image]);
      this.$emit("upload", image);
      this.i += 1;
    },
  },
};
</script>

<style scoped>
.upload-group {
  column-gap: 12px;
  flex-wrap: wrap;
  flex-grow: 1;
  column-count: 3;
  flex-shrink: 1;
  row-gap: 12px;
}
</style>
