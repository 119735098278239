<template>
  <div class="process-main">
    <span v-if="detail.appointDays > 0">请于 {{ time }} 后，拍下宝贝</span>
    【单价：
    <span class="text-red-500">{{ detail.goodsPrice }}</span>
    ，购买数量：
    <span class="text-red-500">{{ detail.goodsNum }}</span>
    ，规格：
    <span class="text-red-500">{{ detail.spec }}</span>
    】
    <div class="process-b-item" v-if="detail.attachGoodsList && detail.attachGoodsList.length > 0">
      <span class="text-red-500">此任务为多链接任务，除了宝贝外还有下面这些附带宝贝，要一起下单</span>
      <div class="mb-1" v-for="item in detail.attachGoodsList">
          <div class="process-b-item-content flex-row">
            <el-image
              class="mr-4"
              style="width: 100px; height: 100px"
              :src="item.imgs"
              :key="imgs"
              fit="cover"
            ></el-image>
            <div class="flex-col" style="margin-top: 10px;">
              <div>单价：{{ item.goodsPrice }}</div>
              <div>数量：{{ item.goodsNum }}</div>
              <div>规格：{{ item.spec }}</div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "",
  props: {
    stepData: {
      type: Object,
    },
    detail: Object,
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        console.log(newValue, oldValue);
        this.$emit("update-done", true);
      },
      deep: true,
    },
  },
  computed: {
    time() {
      const date = new Date(this.detail.searchTime);
      const time = date.getTime() + this.detail.appointDays * 1000 * 3600 * 24;
      return moment(time).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  mounted() {
    this.$emit("update-done", true);
  },
  data() {
    return {
      isDone: false,
      data: {},
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.process-main {
  .process-b-item {
    padding: 0.5rem 0;

    .process-b-item-content {
      margin-top: 0.5rem;
    }
    .process-b-item-content_flex {
      display: flex;
      align-items: center;
    }
  }
}

.avatar-uploader /deep/.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
