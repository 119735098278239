import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store"

Vue.use(VueRouter);

import Index from "@/view/index/Index.vue";
import Trial from "@/view/trial/Index.vue";
import BuyList from "@/view/buy/Index.vue";
import User from "@/view/user/Index.vue";
import MyTrial from "@/view/my/MyTrial.vue";
import Recharge from "@/view/my/Recharge.vue";
import Withdrawal from "@/view/my/Withdrawal.vue";
import Billing from "@/view/my/Billing.vue";
import Profile from "@/view/my/Profile.vue";
import Message from "@/view/message/Index.vue";
import MessageDetail from "@/view/message/Detail.vue";
import Popularize from "@/view/popularize/Index.vue";
import Login from "@/view/login/Login.vue";
import Register from "@/view/login/Register.vue";
import Password from "@/view/login/Password.vue";
import NotFound from "@/view/errors/NotFound.vue";

// 商家端
import SellerTrial from "@/view/seller/trial/Index.vue";
import TrialCreate from "@/view/seller/trial/Create.vue";
import SellerTemplate from "@/view/seller/template/Index.vue";
import BindShop from "@/view/seller/bindshop/Index.vue";
import BlackList from "@/view/seller/blacklist/Index.vue";
import CertifyVue from "@/view/my/Certify.vue";
import Pay from "@/view/my/Pay.vue";

const routes = [
    {
        path: "/",
        name: "Index",
        meta: {
            title: "首页",
        },
        component: Index,
    },
    {
        path: "/trial",
        name: "Trial",
        meta: {
            role: 'user',
            title: "试用大厅",
        },
        component: Trial,
    },
    {
        path: "/buy",
        name: "Buy",
        meta: {
            role: 'user',
            title: "买号列表",
        },
        component: BuyList,
    },
    {
        path: "/user/index",
        name: "User",
        meta: {
            role: 'user',
            title: "会员中心",
        },
        component: User,
    },
    {
        path: "/user/trial",
        name: "MyTrial",
        meta: {
            layout: "my-trial",
            role: 'user',
            title: "我的试用",
        },
        component: MyTrial,
    },
    {
        path: "/user/recharge",
        name: "Recharge",
        meta: {
            role: 'user',
            title: "充值",
        },
        component: Recharge,
    },
    {
        path: "/user/withdrawal",
        name: "Withdrawal",
        meta: {
            role: 'user',
            title: "提现",
        },
        component: Withdrawal,
    },
    {
        path: "/user/billing",
        name: "Billing",
        meta: {
            role: 'user',
            title: "资金明细",
        },
        component: Billing,
    },
    {
        path: "/user/profile",
        name: "Profile",
        meta: {
            role: 'user',
            title: "个人资料",
        },
        component: Profile,
    },
    {
        path: "/user/certify",
        name: "Certify",
        meta: {
            role: 'user',
            title: "实名认证",
        },
        component: CertifyVue,
    },
    {
        path: "/user/pay",
        name: "Pay",
        meta: {
            role: 'user',
            title: "支付",
        },
        component: Pay,
    },
    {
        path: "/user/message",
        name: "Message",
        meta: {
            role: 'user',
            title: "站内信",
        },
        component: Message,
    },
    {
        path: "/user/message/:id",
        name: "MessageDetail",
        meta: {
            role: 'user',
            title: "详情 - 站内信",
        },
        component: MessageDetail,
    },
    {
        path: "/popularize",
        name: "Popularize",
        meta: {
            layout: "my-trial",
            title: "推广",
        },
        component: Popularize,
    },
    /** 商家端 */
    {
        path: "/seller/trial",
        name: "SellerTrial",
        meta: {
            layout: "my-trial",
            role: 'seller',
            title: "试用中心",
        },
        component: SellerTrial,
    },
    {
        path: "/seller/trialCreate",
        name: "TrialCreate",
        meta: {
            role: 'seller',
            title: "发布试用",
        },
        component: TrialCreate,
    },
    {
        path: "/seller/template",
        name: "SellerTemplate",
        meta: {
            role: 'seller',
            title: "模板管理",
        },
        component: SellerTemplate,
    },
    {
        path: "/seller/blacklist",
        name: "BlackList",
        meta: {
            role: 'seller',
            title: "黑名单",
        },
        component: BlackList,
    },
    {
        path: "/seller/bindshop",
        name: "SellerTemplate",
        meta: {
            role: 'BindShop',
            title: "绑定店铺",
        },
        component: BindShop,
    },
    {
        path: "/login",
        name: "Login",
        meta: {
            layout: "login",
            title: "登录",
        },
        component: Login,
    },
    {
        path: "/register",
        name: "Register",
        meta: {
            layout: "login",
            title: "注册",
            keepAlive: true,
        },
        component: Register,
    },
    {
        path: "/password",
        name: "Password",
        meta: {
            title: "忘记密码",
        },
        component: Password,
    },
    {
        path: "/agreement",
        name: "Agreement",
        meta: {
            layout: "login",
            title: "用户协议",
        },
        component: () => import('@/view/login/Agreement.vue'),
    },
    {
        path: "/*",
        name: "NotFound",
        meta: {
            layout: "error",
            title: "404",
        },
        component: NotFound,
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) => {
    console.log(to, from);
    document.title = to.meta.title ?? "";
    if (to.meta.role != null && store.state.user.role != to.meta.role) {
        console.log('没权限访问这个页面');
    }
    next();
});

export default router;
