<template>
    <div class="mobile-nav">
        <router-link to="/trial" class="nav-item" v-if="role == 'BUYER'">
            <img class="nav-item_icon" src="@/assets/icon/trial_act.png" v-if="navActive=='Trial'" />
            <img class="nav-item_icon" src="@/assets/icon/trial.png" v-else />
            <div class="nav-item_text" :style="{color:navActive=='Trial'?'#1296db':''}">试用大厅</div>
        </router-link>
        <router-link to="/user/trial" class="nav-item" v-if="role == 'BUYER'">
            <img class="nav-item_icon" src="@/assets/icon/trialed_act.png" v-if="navActive=='MyTrial'" />
            <img class="nav-item_icon" src="@/assets/icon/trialed.png" v-else />
            <div class="nav-item_text" :style="{color:navActive=='MyTrial'?'#1296db':''}">已申请试用</div>
        </router-link>
        <router-link to="/seller/trial" class="nav-item" v-if="role == 'SELLER'">
            <img class="nav-item_icon" src="@/assets/icon/trial_act.png" v-if="navActive=='SellerTrial'" />
            <img class="nav-item_icon" src="@/assets/icon/trial.png" v-else />
            <div class="nav-item_text" :style="{color:navActive=='SellerTrial'?'#1296db':''}">已发布试用</div>
        </router-link>
        <router-link to="/seller/trialCreate" class="nav-item" v-if="role == 'SELLER'">
            <img class="nav-item_icon" src="@/assets/icon/add_act.png" v-if="navActive=='TrialCreate'" />
            <img class="nav-item_icon" src="@/assets/icon/add.png" v-else />
            <div class="nav-item_text" :style="{color:navActive=='TrialCreate'?'#1296db':''}">发布试用</div>
        </router-link>
        <router-link to="/popularize" class="nav-item">
            <img class="nav-item_icon" src="@/assets/icon/popularize_act.png" v-if="navActive=='Popularize'" />
            <img class="nav-item_icon" src="@/assets/icon/popularize.png" v-else />
            <div class="nav-item_text" :style="{color:navActive=='Popularize'?'#1296db':''}">推广奖励</div>
        </router-link>
        <router-link to="/user/index" class="nav-item">
            <img class="nav-item_icon" src="@/assets/icon/my_act.png" v-if="navActive=='User'" />
            <img class="nav-item_icon" src="@/assets/icon/my.png" v-else />
            <div class="nav-item_text" :style="{color:navActive=='User'?'#1296db':''}">会员中心</div>
        </router-link>
    </div>
</template>
<script>
export default {
    data() {
        return {
            navActive: 'Trial'
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.user;
        },
        role() {
            return this.$store.state.user.role;
        },
    },
    watch: {
        '$route': function(to) {
            this.navActive = to.name
        }
    },
    mounted() {
        this.navActive = this.$route.name
    },
}
</script>

<style lang="less" scoped>
    .mobile-nav {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        background-color: #fff;
        border-top: 1px solid #ccc;
        .nav-item {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .nav-item_icon {
                width: 25px;
                height: 25px;
            }
            .nav-item_text {
                color: #666;
                font-size: 0.7rem;
            }
        }
    }
</style>