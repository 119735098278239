import { render, staticRenderFns } from "./TrialProcess.vue?vue&type=template&id=bbe12ac8&scoped=true&"
import script from "./TrialProcess.vue?vue&type=script&lang=js&"
export * from "./TrialProcess.vue?vue&type=script&lang=js&"
import style0 from "./TrialProcess.vue?vue&type=style&index=0&id=bbe12ac8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbe12ac8",
  null
  
)

export default component.exports