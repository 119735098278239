<template>
  <div>
    <div class="page-main">
      <div class="p_header">
        <el-button type="success" size="small" @click="dialogShow = true">新增买号</el-button>
        <span> 每一个购物平台可绑定5个账号。注意不同的买号下单时要用不同的地址，不然容易出现降权的问题。 </span>
      </div>
      <div class="p_main">
        <el-table :data="tableData" border style="width: 100%" v-loading="loadingTableData">
          <el-table-column prop="platformType" label="分类" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.platformType == 'TAO_BAO'" >淘宝</span>
              <span v-if="scope.row.platformType == 'PDD'" >拼多多</span>
              <span v-if="scope.row.platformType == 'JD'" >京东</span>
              <span v-if="scope.row.platformType == 'DOU_YIN'" >抖音</span>
            </template>
          </el-table-column>
          <el-table-column prop="account" label="账号" align="center"> </el-table-column>
          <el-table-column prop="credit" label="信用" align="center">
            <template slot-scope="scope">
              <TrialLevel :platformType="scope.row.platformType" :creditLevel="scope.row.creditLevel" />
            </template>
          </el-table-column>
          <!-- <el-table-column prop="day" label="日已做" align="center"> </el-table-column>
          <el-table-column prop="week" label="周已做" align="center"> </el-table-column>
          <el-table-column prop="mother" label="月已做" align="center"> </el-table-column> -->
          <el-table-column prop="auditStatus" label="审核状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.auditStatus == 'WAITING'" style="color: #ff575c">审核中</span>
              <span v-if="scope.row.auditStatus == 'SUCCESS'" style="color: #7561ff">通过</span>
              <span v-if="scope.row.auditStatus == 'FAIL'" style="color: #7561ff">拒绝</span>
            </template>
          </el-table-column>
          <el-table-column prop="action" label="操作" width="100" align="center">
            <template slot-scope="scope">
              <!-- <el-button class="action-btn" @click="handleClick(scope.row)" type="success" size="small">
                助手配置
              </el-button> -->
              <el-button
                class="action-btn"
                v-if="scope.row.status == 0"
                type="success"
                size="small"
                @click="toEnableAccount(scope.row)"
                >启用</el-button
              >
              <el-button class="action-btn" v-else type="danger" size="small" @click="toDisableAccount(scope.row)"
                >禁用</el-button
              >
            </template>
          </el-table-column>
          <el-table-column prop="delete" label="删除" align="center" width="100px">
            <template slot-scope="scope">
              <el-button type="danger" icon="el-icon-delete" circle @click="deleteAccount(scope.row)"></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="mt-6" background layout="prev, pager, next" :total="page.total"> </el-pagination>
      </div>
    </div>

    <!-- 弹窗 START -->
    <el-dialog title="买号新增" :visible.sync="dialogShow" class="dialog" center>
      <div>
        <el-form ref="formData" :model="formData" :rules="formRules" label-width="100px" :inline-message="true">
          <div class="form-title">买家信息</div>

          <el-form-item label="账号分类" prop="platformType">
            <el-select class="form-input" size="small" v-model="formData.platformType" placeholder="请选择">
              <el-option v-for="item in platformTypeOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="账号" prop="account" v-if="formData.platformType != 'PDD'">
            <el-input class="form-input" v-model.trim="formData.account" size="small"></el-input>
          </el-form-item>

          <el-form-item label="绑定手机号" prop="account" v-if="formData.platformType == 'PDD'">
            <el-input class="form-input" type="number" v-model.trim="formData.account" size="small"></el-input>
          </el-form-item>

          <el-form-item label="账号性别" prop="sex">
            <el-radio-group v-model="formData.type">
              <el-radio label="1">男号</el-radio>
              <el-radio label="0">女号</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="信用积分" prop="creditLevel" v-if="formData.platformType != 'PDD'">
            <el-select class="form-input" size="small" v-model="formData.creditLevel" placeholder="请选择">
              <el-option v-for="item in creditLevelOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年龄段" prop="ageLevel">
            <el-select class="form-input" size="small" v-model="formData.ageLevel" placeholder="请选择">
              <el-option v-for="item in ageLevelOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="淘气值" prop="taoScoreNum" v-if="formData.platformType == 'TAO_BAO'">
            <el-input class="form-input" v-model.trim="formData.taoScoreNum" size="small" type="number"></el-input>
          </el-form-item>

          <el-form-item label="常购类目" prop="buyCategory" v-if="formData.platformType == 'TAO_BAO'">
            <el-checkbox-group :max="5" v-model="formData.buyCategory">
              <el-checkbox :label="category.id" v-for="category in buyCategoryOptions" :key="category.id">{{
                category.name
              }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <div class="form-title">收货信息</div>

          <el-form-item label="姓名" prop="contactName">
            <el-input class="form-input" v-model.trim="formData.contactName" size="small"></el-input>
          </el-form-item>

          <el-form-item label="手机" prop="contactPhone">
            <el-input class="form-input" type="number" v-model.trim="formData.contactPhone" size="small"></el-input>
          </el-form-item>

          <el-form-item label="收货地址" prop="region">
            <el-cascader
              class="form-input"
              size="small"
              v-model="formData.region"
              :options="regionOptions"
            ></el-cascader>
          </el-form-item>

          <el-form-item prop="address">
            <el-input class="form-input" v-model.trim="formData.address" size="small" placeholder="详细地址"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button class="form-btn" type="primary" @click="formSubmit('formData')" size="small">确定</el-button>
            <el-button type="info" @click="resetForm('formData')" size="small">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 弹窗 END -->
  </div>
</template>

<script>
import TrialLevel from "@/components/trial/TrialLevel.vue";
import { getBuyAccountList, switchStatus, deleteAccount, addAccount } from "@/api/buyAccount";
import { regionList } from "@/api/user";
import { statisBuyerTaskNum } from "@/api/task";
import { goodsCateList } from "@/api/goods";

export default {
  data() {
    return {
      tableData: [],
      loadingTableData: false,
      dialogShow: false,
      formData: {
        platformType: null,
        account: "",
        phone: "",
        sex: 1,
        ageLevel: null,
        creditLevel: 0,
        taoScoreNum: '',
        buyCategory: [],
        contactName: "",
        contactPhone: "",
        region: "",
        address: "",
      },
      platformTypeOptions: [
        { value: "TAO_BAO", label: "天猫/淘宝任务" },
        { value: "PDD", label: "拼多多任务" },
        { value: "JD", label: "京东任务" },
        { value: "DOU_YIN", label: "抖音任务" },
      ],
      ageLevelOptions: [
        { value: "18-25", label: "18-25" },
        { value: "25-30", label: "25-30" },
        { value: "30-35", label: "30-35" },
      ],
      buyCategoryOptions: [],
      regionOptions: [],
      formRules: {
        platformType: [
          {
            required: true,
            message: "请输入账号分类",
            trigger: "blur",
          },
        ],
        account: [{ required: true, message: "请输入账号", trigger: "change" }],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        // phone: [
        //   {
        //     required: true,
        //     message: "请输入手机号",
        //     trigger: "blur",
        //   },
        // ],
        sex: [
          {
            required: true,
            message: "请选择账号性别",
            trigger: "blur",
          },
        ],
        // ageLevel: [
        //   {
        //     required: true,
        //     message: "请选择年龄段",
        //     trigger: "blur",
        //   },
        // ],
        // taoScoreNum: [
        //   {
        //     required: true,
        //     message: "请输入淘气值",
        //     trigger: "blur",
        //   },
        // ],
        // buyCategory: [
        //   {
        //     required: true,
        //     message: "请选择常购类目",
        //     trigger: "blur",
        //   },
        // ],
        contactName: [
          {
            required: true,
            message: "请输入收货人姓名",
            trigger: "blur",
          },
        ],
        contactPhone: [
          {
            required: true,
            message: "请输入收货手机号",
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: "请选择收货地区",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur",
          },
        ],
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
        pages: 0,
      },
    };
  },
  components: {
    TrialLevel,
  },
  computed: {
    creditLevelOptions() {
      //0不限，淘宝，1-5为1-5心，6-10为1-5钻，11为皇冠，拼多多没有，京东，1为铜牌，2为银牌，3为金牌，4为钻石，抖音，1-5为1-5心，6为1钻
      switch (this.formData.platformType) {
        case "TAO_BAO":
          return [
            { value: 0, label: "无" },
            // { value: 1, label: "1心" },
            // { value: 2, label: "2心" },
            { value: 3, label: "3心" },
            { value: 4, label: "4心" },
            { value: 5, label: "5心" },
            { value: 6, label: "1钻" },
            { value: 7, label: "2钻" },
            { value: 8, label: "3钻" },
            { value: 9, label: "4钻" },
            { value: 10, label: "5钻" },
            { value: 11, label: "皇冠" },
          ];
        case "PDD":
          return [];
        case "JD":
          return [
            { value: 0, label: "无" },
            { value: 1, label: "铜牌" },
            { value: 2, label: "银牌" },
            { value: 3, label: "金牌" },
            { value: 4, label: "钻石" },
          ];
        case "DOU_YIN":
          return [
            { value: 0, label: "无" },
            { value: 1, label: "1心" },
            { value: 2, label: "2心" },
            { value: 3, label: "3心" },
            { value: 4, label: "4心" },
            { value: 5, label: "5心" },
            { value: 6, label: "1钻" },
          ];
      }
      return [{ value: 0, label: "请选择账号分类" }];
    },
    user() {
      return this.$store.state.user;
    },
  },
  created() {
    if (!this.user.isLogin) {
      this.$router.push("/login");
    }
  },
  watch: {
    async dialogShow(val) {
      if (val && this.regionOptions.length == 0) {
        await this.loadCategories();
        const { data: regions } = await regionList();
        this.regionOptions = regions;
      }
    },
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    async loadCategories() {
      const {
        data: { list: catesList },
      } = await goodsCateList();
      this.buyCategoryOptions = catesList;
    },
    async getTableList() {
      this.loadingTableData = true;
      try {
        const {
          data: { list: accounts, page: pages },
        } = await getBuyAccountList({}, this.page);
        this.loadingTableData = false;
        this.tableData = accounts;
        this.page = pages;
      } finally {
        this.loadingTableData = false;
      }
    },
    async deleteAccount(account) {
      const action = await this.$msgbox({
        title: "提示",
        message: "确定删除该账号吗？",
        showCancelButton: true,
        customClass:'cfm-model',
      }).catch(() => {
        return "cancel";
      });

      if (action == "confirm") {
        await deleteAccount(account.id);
        this.$message.success("删除成功");
        this.getTableList();
      }
    },
    toEnableAccount(account) {
      this.$confirm("确定启用该账号吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await switchStatus(account.id, 1);
        this.$message.success("启用成功");
        this.getTableList();
      });
    },
    toDisableAccount(account) {
      this.$confirm("确定禁用该账号吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await switchStatus(account.id, 0);
        this.$message.success("禁用成功");
        this.getTableList();
      });
    },
    // 表单提交
    formSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.formData = {
            ...this.formData,
            minAge: this.formData.ageLevel ? this.formData.ageLevel.split("-")[0] : undefined,
            maxAge: this.formData.ageLevel ? this.formData.ageLevel.split("-")[1] : undefined,
            usualCateIds: this.formData.buyCategory.join(","),
            provinceCode: this.formData.region[0],
            cityCode: this.formData.region[1],
            districtCode: this.formData.region[2],
          };
          await addAccount(this.formData);
          this.$message.success("添加成功");
          this.dialogShow = false;
          this.resetForm(formName);
          this.getTableList();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style>
  .cfm-model  {
    width: 360px !important;
  }
</style>

<style lang="less" scoped>
.page-main {
  background-color: #fff;
  padding: 1rem;

  .p_header {
    margin-bottom: 1rem;
    span {
      margin-left: 1rem;
    }
  }

  .p_main {
    margin-top: 2rem;
    .action-btn {
      border-radius: 22px;
    }
  }
}
.dialog {
  .form-title {
    padding-bottom: 0.5rem;
    margin: 2rem 0;
    border-bottom: 1px dashed #ccc;
  }
  .form-input {
    width: 300px;
  }
}
@media (max-width: 520px) {
  .dialog {
    /deep/ .el-dialog {
      width: 90%;
    }
    form-title {
      padding-bottom: 0.5rem;
      margin: 2rem 0;
    }
    /deep/ .el-form-item {
      margin-bottom: 0.2rem;
    }
    .form-input {
      width: 100%;
    }
  }
  .page-main {
    background-color: #fff;
    padding: 1rem;

    .p_header {
      span {
        display: block;
        margin: 1rem 0 0;
      }
    }
  }
}
</style>
