<template>
  <div class="process-main">
    <div class="process-c-item">1.请回答商品问题</div>
    <div class="process-c-item">回答商品问题提示：{{ stepData.answerPrompt }}
      <el-button type="success" size="small" round style="margin-left: 10px;"
        @click="copy(stepData.answerPrompt)"
        >复制评价
      </el-button>
    </div>
    <div class="process-g-item" v-if="!stepData.noScreenShotMode">2.请上传回答问题截图</div>
    <div class="process-g-item mt-3" v-if="!stepData.noScreenShotMode">
      <uploader v-model="data.image" :type="'dotask'"></uploader>
    </div>
  </div>
</template>

<script>
import uploader from "@/components/uploader";
export default {
  components: {
    uploader,
  },
  props: {
    stepData: {
      type: Object,
    },
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        if (newValue.image !== "") {
          this.$emit("update-done", newValue.image);
        }
      },
      deep: true,
    },
  },
  created() {
    // this.data = this.stepData
  },
  mounted() {
    if (this.stepData.noScreenShotMode) {
      setTimeout(() => {
        this.$emit("update-done", true);
      }, 0);
    } else {
      this.data.image = this.stepData.answerImgs || "";
    }
  },
  data() {
    return {
      isDone: false,
      data: {
        image: "",
      },
    };
  },
  methods: {
    copy(str) {
      this.$copyText(str).then(
        () => {
          this.$message.success("已复制");
        },
        () => {
          this.$message.error("复制失败");
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.process-main {
  .process-c-item {
    margin-bottom: 1rem;
  }
}
</style>
