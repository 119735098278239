<template>
  <div>
    <el-container class="min-h-[100%] absolute w-full overflow-y-scroll" :style="{paddingBottom: isMobile?'50px':0}"> 
      <!-- 头部公共组件 START -->
      <el-header id="header" height="auto">
        <Header />
      </el-header>
      <!-- 头部公共组件 END -->

      <!-- 动态区域 START -->
      <el-main>
        <div class="v-container">
          <div id="main"><router-view></router-view></div>
        </div>
      </el-main>
      <!-- 动态区域 END -->

      <div v-if="!isMobile && showSidebar" class="fixed right-10 top-50 bg-gray-50 text-blue-400 text-12px flex flex-col">
        <!-- QQ 在线咨询 -->
        <a
          :href="'http://wpa.qq.com/msgrd?v=3&site=qq&menu=yes&uin=' + systemConfig.CUSTOMER_SERVICE_QQ"
          target="_blank"
          rel="noopener noreferrer"
          class="text-center px-4 py-2 border-bottom-1 border-dashed"
        >
          <img src="@/assets/image/qq.png" alt="QQ在线咨询" title="QQ在线咨询" class="h-95px mx-auto mb-2" />
          QQ在线咨询
        </a>
        <!-- 下载 APP -->
        <a target="_blank" rel="noopener noreferrer" class="text-center px-4 py-2 border-bottom-1 border-dashed">
          <img :src="systemConfig.CUSTOMER_SERVICE_WX_IMG" alt="下载APP" title="下载APP" class="h-95px mx-auto mb-2" />
          微信咨询
        </a>
        <!-- 关闭 -->
        <div class="text-center px-4 py-2 text-gray-500" @click="showSidebar = false">关闭</div>
      </div>

      <div v-if="isMobile && showSidebar" class="fixed flex-col right-0 top-50 bg-gray-50 text-blue-400 text-12px flex flex-col" 
        style="background-color: lightgray; align-items: center; border-radius: 3px; padding: 8px 4px;"
        @click="showCustomerDialog">
        <img src="@/assets/image/qq-icon.png" alt="QQ在线咨询" title="QQ在线咨询" style="width: 20px; height: 20px;" />
        <span style="writing-mode: vertical-rl; color: black; font-size: 15px; font-weight: bold;">在线咨询</span>
      </div>

      <!-- 底部公共组件 START -->
      <el-footer id="footer" style="height: auto">
        <Footer />
      </el-footer>
      <!-- 底部公共组件 END -->
    </el-container>
    <MobileNav v-if="isMobile" />

    <el-dialog title="联系客服" :visible.sync="dialogVisible" @close="dialogVisible" width="80%" center>
      <div class="flex-row" style="align-items: center; ">
        <span>QQ：{{systemConfig.CUSTOMER_SERVICE_QQ}}</span>
        <el-button round size="small" type="success" @click="copy(systemConfig.CUSTOMER_SERVICE_QQ)" style="margin-left: 30px;">复制</el-button>
      </div>
      <div class="flex-row" style="align-items: center; justify-content: center; margin-top: 10px;">
        <span>微信：{{systemConfig.CUSTOMER_SERVICE_WX}}</span>
        <el-button round size="small" type="success" @click="copy(systemConfig.CUSTOMER_SERVICE_WX)" style="margin-left: 30px;">复制</el-button>
      </div>
      <div class="flex-row" style="align-items: center; justify-content: center; margin-top: 10px; color: red; font-size: 15px;">
        悟空给的佣金高过大多同类平台，所以请珍惜认真按要求做任务，不然可能禁号
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";
import MobileNav from "@/components/public/MobileNav.vue";

export default {
  components: {
    Header,
    Footer,
        MobileNav,
  },
  data() {
    return {
      showSidebar: true,
      isMobile: false,
      dialogVisible: false,
    };
  },
  computed: {
    systemConfig() {
      return this.$store.state.config.config;
    },
  },
  mounted() {
    this.isMobile = document.documentElement.clientWidth <= 520;
  },
  methods: {
    showCustomerDialog() {
      this.dialogVisible = true
    },
    copy(str) {
      this.$copyText(str).then(
        () => {
          this.$message.success("已复制");
        },
        () => {
          this.$message.error("复制失败");
        }
      );
    },

  },
};
</script>

<style lang="less" scoped>
#header {
  padding: 0;
  height: auto;
}

#main {
  padding: 1rem 0;
  padding-top: 160px;
}

#footer {
  padding: 0;
}
@media (max-width: 520px) {
  #main {
    padding-top: 70px;
  }
}
</style>

<style scoped>
.el-main {
  padding: 0;
}
</style>
