import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/less/global.less';
import router from '@/router'
import store from '@/store'
import VueClipboard from 'vue-clipboard2'
import filter from '@/plugin/filter'
import 'windi.css'

import DefaultLayout from '@/layout/Default.vue'
import MyTrialLayout from '@/layout/MyTrial.vue'
import LoginLayout from '@/layout/Login.vue'
import ErrorLayout from '@/layout/Error.vue'
Vue.component('default-layout', DefaultLayout)
Vue.component('my-trial-layout', MyTrialLayout)
Vue.component('login-layout', LoginLayout)
Vue.component('error-layout', ErrorLayout)

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueClipboard)

Object.keys(filter).forEach(key => {
  Vue.filter(key, filter[key])
})
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
