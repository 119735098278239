<template>
  <div>
    <div class="step-bar <sm:hidden">
      <el-steps :active="processActive" finish-status="success" simple>
        <el-step
          v-for="(step, index) in computedSteps"
          :key="index"
          :title="step.name"
          :description="step.description"
        ></el-step>
      </el-steps>
    </div>
    <div class="step-main">
      <div class="flex flex-row step-description">
        <div class="!w-7 !h-7 !leading-7 text-white text-lg text-center rounded-1/1 bg-red-500">
          {{ processActive + 1 }}
        </div>
        <div class="text-18px font-bold ml-4 pt-1" v-if="currentStep && currentStep.code == 'bi_huo'">
          货比 {{ detail.compareNum }} 家
        </div>
        <div class="text-18px font-bold ml-4 pt-1" v-else-if="currentStep && currentStep.code !== 'xia_dan'">
          {{ currentStep.description }}
        </div>
        <div class="text-red-500 text-18px font-bold ml-4 pt-1" v-else>
          <span v-if="detail.appointDays > 0"> {{ detail.appointDays }} 天后下单 </span>
          <span v-else> 拍下宝贝 </span>
        </div>
      </div>
      <div
        v-for="(step, index) in computedSteps"
        :key="index"
        v-if="processActive == index || step.isDone"
        v-show="processActive == index"
        class="step-main-item"
      >
        <TrialProcessItem :tpl="step.templateId" :data="flowDetail" :detail="detail" :code="step.code" @update-done="updateFlowDone" />
      </div>
    </div>

    <div class="step-btn">
      <el-button
        style="float: left"
        type="primary"
        size="small"
        round
        @click="processActive -= 1"
        v-if="processActive > 0 && !backWatch"
        :loading="waitPostData"
      >
        上一步
      </el-button>
      <!-- 回看的上一步 -->
      <el-button
        style="float: left"
        type="primary"
        size="small"
        round
        @click="toBackWatch"
        v-if="processActive > 0 && backWatch"
        :loading="waitPostData"
      >
        上一步
      </el-button>
      <el-button v-if="extraCountDown > 0" size="small" round> {{ extraCountDown }} 秒倒计时 </el-button>
      <el-button v-if="computedSteps.length - processActive > 0" size="small" round>
        {{
          processActive < computedSteps.length - 1
            ? "剩余" + (computedSteps.length - 1 - processActive) + "步"
            : "恭喜，这是最后一步"
        }}
      </el-button>
      <el-button
        type="primary"
        size="small"
        round
        @click="processRunFun"
        v-if="detail.appointDays == 0 || countDown == '00:00:00' || (currentStep && currentStep.code !== 'xia_dan')"
        :disabled="
          computedSteps.length == processActive ||
          !currentStep.isDone ||
          extraCountDown !== 0 ||
          (currentStep && backWatchEnd == currentStep.code)
        "
        :loading="waitPostData"
      >
        {{
          backWatchEnd == currentStep.code
            ? "已达最后一步"
            : computedSteps.length - 1 > processActive
            ? "已完成，下一步"
            : "完成"
        }}
      </el-button>
      <el-button type="danger" size="small" round v-else disabled>
        {{ countDown }}
      </el-button>
    </div>
  </div>
</template>

<script>
import TrialProcessItem from "@/components/trial/TrialProcessItem.vue";
import { currentFlowList, taskDetail, updateFlow } from "@/api/task";

export default {
  components: {
    TrialProcessItem,
  },
  props: {
    detail: Object,
    backWatch: Boolean,
  },
  data() {
    return {
      processActive: 0,
      steps: [
        {
          name: "1",
          code: "zhan_shi_liu_yan",
          description: "商家给您的留言提醒",
          templateId: "a",
          data: {},
          isDone: false,
        },
        {
          name: "2",
          code: "sou_suo",
          description: "搜索",
          templateId: "b",
          data: {},
          isDone: false,
        },
        {
          name: "2",
          code: "sou_suo_biao_qian",
          description: "搜索标签",
          templateId: "n",
          data: {},
          isDone: false,
        },
        {
          name: "3",
          code: "que_ren_wu_liu",
          description: "物流确认",
          templateId: "c",
          data: {},
          isDone: false,
        },
        {
          name: "4",
          code: "xia_dan",
          description: "拍下宝贝",
          templateId: "d",
          data: {},
          isDone: false,
        },
        {
          name: "5",
          code: "shai_tu",
          description: "晒图",
          templateId: "e",
          data: {},
          isDone: false,
        },
        {
          name: "5",
          code: "shai_shi_pin",
          description: "晒视频",
          templateId: "o",
          data: {},
          isDone: false,
        },
        {
          name: "6",
          code: "ping_jia",
          description: "评价",
          templateId: "f",
          data: {},
          isDone: false,
        },
        {
          name: "6",
          code: "zhui_ping",
          description: "追评",
          templateId: "p",
          data: {},
          isDone: false,
        },
        {
          name: "7",
          code: "shou_cang",
          description: "收藏",
          templateId: "g",
          data: {},
          isDone: false,
        },
        {
          name: "8",
          code: "liu_lan_ping_jia",
          description: "浏览评价",
          templateId: "h",
          data: {},
          isDone: false,
        },
        {
          name: "9",
          code: "jia_gou",
          description: "加购",
          templateId: "i",
          data: {},
          isDone: false,
        },
        {
          name: "10",
          code: "bi_huo",
          description: "比货",
          templateId: "j",
          data: {},
          isDone: false,
        },
        {
          name: "11",
          code: "que_ren_shou_huo",
          description: "确认收货",
          templateId: "k",
          data: {},
          isDone: false,
        },
        {
          name: "12",
          code: "hui_da",
          description: "问答",
          templateId: "l",
          data: {},
          isDone: false,
        },
        {
          name: "13",
          code: "wan_cheng_fu_kuan",
          description: "完成付款",
          templateId: "m",
          data: {},
          isDone: false,
        },
        {
          name: "14",
          code: "liao_tian",
          description: "拍前聊天",
          templateId: "q",
          data: {},
          isDone: false,
        },
      ],
      flowData: [],
      flowDetail: {},
      countDown: "",
      countDownInterval: null,
      tmpData: {},
      waitPostData: false,
      extraCountDown: 0,
      extraCountDownInterval: null,
      backWatchEnd: "",
      loading: false
    };
  },
  async mounted() {
    this.loading = true
    await this.getFlowDetail();
    await this.getFlowList();

    setTimeout(() => {
      this.loading = false
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.countDownInterval);
  },
  watch: {
    currentStep(val, oldVal) {
      if (val.code == "xia_dan") {
        this.updateCountDown();
        this.countDownInterval = setInterval(() => {
          this.updateCountDown();
        }, 1000);
      } else {
        clearInterval(this.countDownInterval);

        if (!this.backWatch && (oldVal === undefined || val.code !== oldVal.code)) this.updateExtraCountDown();
      }

      if (oldVal && val.code == oldVal.code) {
        return;
      }
      this.tmpData = {};
    },
  },
  computed: {
    computedSteps() {
      return this.flowData
        .map((item) => {
          const mapStep = this.steps.find((step) => step.code === item.code);
          if (!mapStep) return undefined;
          return {
            name: item.name,
            code: item.code,
            description: mapStep.description,
            templateId: mapStep.templateId,
            data: mapStep.data,
            isDone: mapStep.isDone,
          };
        })
        .filter((item) => item)
        .map((item, index) => {
          return { index, ...item };
        });
    },
    currentStep() {
      return this.computedSteps.find((item) => item.index === this.processActive);
    },
    currentStepOrigin() {
      return this.steps.find((item) => item.code === this.currentStep.code);
    },
    currentFlow() {
      return this.flowData.find((item) => item.code === this.currentStep.code);
    },
    systemConfig() {
      return this.$store.state.config.config;
    },
  },
  methods: {
    toBackWatch() {
      this.extraCountDown = 0;
      this.processActive -= 1;
    },
    updateCountDown() {
      const t = this.detail.searchTime;
      if (!t) return 0;
      const now = new Date().getTime();
      const end = new Date(t).getTime();
      const time = end - now;
      if (time <= 0) {
        this.countDown = "00:00:00";
        return;
      }
      const h = Math.floor(time / 1000 / 60 / 60);
      const m = Math.floor((time / 1000 / 60 / 60 - h) * 60);
      const s = Math.floor(((time / 1000 / 60 / 60 - h) * 60 - m) * 60);
      this.countDown = `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${s < 10 ? "0" + s : s}`;
    },
    async getFlowList() {
      let {
        data: { currentFlowCode, flowList },
      } = await currentFlowList(this.detail.id);
      this.flowData = flowList;
      this.$nextTick(() => {
        if (this.backWatch && currentFlowCode == 'yi_shou_huo') {
          currentFlowCode = 'que_ren_shou_huo'
        }
        const currentStep = this.computedSteps.find((item) => item.code === currentFlowCode);
        this.processActive = currentStep.index || 0;
        if (this.backWatch) {
          this.backWatchEnd = currentStep.code;
        }
        // 在之前的都设置为完成
        this.computedSteps.forEach((item) => {
          if (item.index < this.processActive) {
            const code = item.code;
            const step = this.steps.find((item) => item.code === code);
            step.isDone = true;
          }
        });
      });
    },
    updateExtraCountDown() {
      this.extraCountDown = 10;
      if (this.systemConfig.STEP_COUNT_DOWN) {
        this.extraCountDown = this.systemConfig.STEP_COUNT_DOWN;
      }
      clearInterval(this.extraCountDownInterval);
      this.extraCountDownInterval = setInterval(() => {
        this.extraCountDown--;
        if (this.extraCountDown <= 0) {
          clearInterval(this.extraCountDownInterval);
        }
      }, 1000);
    },
    async getFlowDetail() {
      const { data } = await taskDetail(this.detail.id);
      this.flowDetail = data;
    },
    async updateFlowDone(val) {
      const step = this.currentStep;
      if (val.stepCode !== step.code) return

      this.tmpData = val;
      this.currentStepOrigin.data = val;
      console.log("完成 Step", step.code, val);

      if (step.isDone) return;

      // this.$message.success("当前步骤完成");
      const originStep = this.steps.find((item) => item.code === step.code);
      originStep.isDone = true;
    },
    async processRunFun() {
      if (this.computedSteps.length - 1 > this.processActive) {
        this.waitPostData = true;
        try {
          const step = this.currentStep;
          let params = {};

          const val = this.tmpData;
          if (step.code == "wan_cheng_fu_kuan" || typeof val === "object") {
            params = {
              ...val,
              stepCode: undefined
            };
          } else if (val !== true && typeof val === "string") {
            params = {
              imgs: val,
            };
          }
          if (!this.backWatch) {
            await updateFlow({
              taskId: this.detail.id,
              flowCode: step.code,
              ...params,
            });
          }
        } finally {
          this.waitPostData = false;
        }

        this.processActive += 1;
      } else {
        this.$message.success("已完成，待商家确认");
        this.$emit("refresh");
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.step-bar {
  margin-bottom: 1rem;
}

.step-main {
  margin: 1rem 0 2rem;

  .step-description {
    padding-bottom: 1rem;
    border-bottom: 1px dashed #ccc;
    margin-bottom: 2rem;
  }
}

.step-btn {
  padding-top: 1rem;
  border-top: 1px solid #eee;
  text-align: right;
}

/deep/ .el-step__title {
  font-size: 14px !important;
}
</style>
