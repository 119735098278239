<template>
  <div class="default-layout-container">
    <div class="default-layout-page-title">修改密码</div>
    <div class="form">
      <el-form ref="formData" :model="formData" :rules="formRules" label-width="100px">
        <el-form-item label="用户名" prop="username">
          <el-input class="form-input" v-model="formData.username" placeholder="用户名" size="small"></el-input>
        </el-form-item>

        <el-form-item label="输入新密码" prop="password">
          <el-input class="form-input" v-model="formData.password" size="small" type="password"></el-input>
        </el-form-item>

        <el-form-item label="重复新密码" prop="password2">
          <el-input class="form-input" v-model="formData.password2" size="small" type="password"></el-input>
        </el-form-item>

        <el-form-item label="手机号码" prop="mobile">
          <el-input class="form-input" v-model="formData.mobile" size="small"></el-input>

          <span class="help-text">请输入您注册时填写的手机号</span>
        </el-form-item>

        <el-form-item label="手机验证码" prop="mobileCode">
          <el-input class="form-input-sms" v-model="formData.mobileCode" size="small"></el-input>
          <el-button class="form-input-sms-btn" type="primary" @click="sendSms" size="small" :disabled="smsSent">{{
            smsSent ? smsSendTimer + "秒后重发" : "立即发送"
          }}</el-button>
        </el-form-item>

        <el-form-item>
          <el-button class="form-btn" type="primary" @click="formSubmit('formData')" size="small">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { sendCode, changePasswordByCode } from "@/api/user";
export default {
  data() {
    return {
      smsSent: false,
      smsSendTimer: 60,
      formData: {
        username: "",
        password: "",
        password2: "",
        mobile: "",
        mobileCode: "",
      },
      formRules: {
        username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        password2: [{ required: true, message: "请再次输入密码", trigger: "blur" }],
        mobile: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
        mobileCode: [{ required: true, message: "请输入手机验证码", trigger: "blur" }],
      },
    };
  },
  methods: {
    // 表单提交
    formSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await changePasswordByCode(
            this.formData.username,
            this.formData.password,
            this.formData.mobile,
            this.formData.mobileCode
          );
          this.$message.success("修改成功");
          this.$router.push("/login");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 发送验证码
    async sendSms() {
      await sendCode(this.formData.mobile);
      this.$message.success("发送成功");
      this.smsSent = true;
      this.smsSendTimer = 60;
      let timer = setInterval(() => {
        if (this.smsSendTimer == 1) {
          clearInterval(timer);
          this.smsSent = false;
        } else {
          this.smsSendTimer -= 1;
        }
      }, 1000);
    },
  },
};
</script>
<style lang="less" scoped>
.mb-2rem {
  margin-bottom: 2rem;
}
.plr-2rem {
  padding: 0 2rem;
}

.form {
  .plr-2rem;
  .form-input {
    width: 300px;
  }
  .form-input-sms {
    width: 200px;
  }

  .form-input-sms-btn {
    width: 90px;
    margin-left: 10px;
  }
  .form-btn {
    width: 300px;
  }
  .help-text {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 640px) {
  .plr-2rem {
    padding: 0 1rem;
  }
  .form {
    padding: 0;
    form-title {
      padding-bottom: 0.5rem;
      margin: 2rem 0;
    }
    /deep/ .el-form-item {
      margin-bottom: 0.2rem;
    }
    .form-input {
      width: 100%;
    }
    .form-input-sms {
      width: 100%;
      margin-bottom: 0.5rem;
    }

    .form-input-sms-btn {
      display: block;
      width: 100%;
      margin-left: 0;
      margin-bottom: 0.3rem;
    }
    .form-btn {
      width: 100%;
    }
  }
}
</style>
