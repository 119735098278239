<template>
  <div class="process-main">
    <div class="process-c-item">1.下单前和商家聊天咨询视频问题，聊天时禁止提及与销量和试用之事，否则严惩！</div>
    <div class="process-g-item" v-if="!stepData.noScreenShotMode">2.请上传聊天截图</div>
    <div class="process-g-item mt-3" v-if="!stepData.noScreenShotMode">
      <uploader v-model="data.image" :type="'dotask'"></uploader>
    </div>
  </div>
</template>

<script>
import uploader from "@/components/uploader";
export default {
  components: {
    uploader,
  },
  props: {
    stepData: {
      type: Object,
    },
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        if (newValue.image !== "") {
          this.$emit("update-done", newValue.image);
        }
      },
      deep: true,
    },
  },
  created() {
    // this.data = this.stepData
  },
  mounted() {
    if (this.stepData.noScreenShotMode) {
      setTimeout(() => {
        this.$emit("update-done", true);
      }, 0);
    } else {
      this.data.image = this.stepData.chatImgs || "";
    }
  },
  data() {
    return {
      isDone: false,
      data: {
        image: "",
      },
    };
  },
  methods: {
    copy(str) {
      this.$copyText(str).then(
        () => {
          this.$message.success("已复制");
        },
        () => {
          this.$message.error("复制失败");
        }
      );
    },
    handleAvatarSuccess(res, file) {
      this.image = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>
.process-main {
  .process-c-item {
    margin-bottom: 1rem;
  }
}
.avatar-uploader /deep/.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
