<template>
    <div class="tag" :style="{borderColor: color, color, fontSize: size+'px'}">
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: {
        color: {
            type: String,
            default: '#255'
        },
        size: {
            type: Number,
            default: 12
        }
    }
}
</script>

<style lang="less" scoped>
    .tag {
        display: inline-block;
        padding: 2px 8px;
        border: 1px solid #255;
        border-radius: 12px;
        margin: 0 5px 5px 0;
    }
</style>