// user api
import request from "@/plugin/request";

export const login = (data) => request.post("/user/passwordLogin", data);

export const agreement = () => request.post("/systemConfig/getLongTextConfig");

export const regionList = () => request.post("/region/getRegionTree");

export const provinceList = () => request.post("/region/getProvinceList");

export const loginUserInfo = () => request.post("/user/getLoginUserInfo");

export const register = (data) => request.post("/user/phoneRegister", data);

export const sendCode = (phone) => request.get("/user/sendCheckCode", { phone });

export const changePasswordByCode = (loginName, password, phone, checkCode) =>
  request.post("/user/changePasswordByCode", { loginName, password, phone, checkCode });

export const userInfo = () => request.post("/user/getLoginUserInfo");

export const getRecomList = () =>
  request.post("/user/getRecomList", {
    page: {
      current: 1,
      size: 100,
    },
    queryParams: {},
  });

export const getIndirectRecomList = () =>
  request.post("/user/getIndirectRecomList", {
    page: {
      current: 1,
      size: 100,
    },
    queryParams: {},
  });

export const updateInfo = (data) => request.post("/user/updateInfo", data);

export const changePassword = (oldPassword, newPassword) =>
  request.post("/user/changePassword", { oldPassword, newPassword });

export const changeCapitalPassword = (oldPassword, newPassword) =>
  request.post("/user/changeCapitalPassword", { oldPassword, newPassword });

export const recharge = (price, payType) =>
  request.post("/rechargeOrders/add", { price, payMethod: payType });

export const getRechargeOrders = (id) =>
  request.get("/rechargeOrders/get?id=" + id);

export const withdrawal = (price, password) =>
  request.post("/withdraw/apply", {
    amount: price,
    accountType: "ALIPAY_ACCOUNT",
    clientType: "H5",
    capitalPassword: password,
  });

export const realNameVerify = (data) => request.post("/user/realNameVerify", data);