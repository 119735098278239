<template>
  <div class="box">
    <el-select v-model="data.platform" class="mr-4" @change="update">
      <el-option label="淘宝/天猫任务" value="TAO_BAO"></el-option>
      <el-option label="拼多多任务" value="PDD"></el-option>
      <el-option label="京东任务" value="JD"></el-option>
      <el-option label="抖音任务" value="DOU_YIN"></el-option>
    </el-select>
    <div class="search mr-4 flex flex-row">
      <el-input type="number" placeholder="试用编号搜索" clearable v-model="id"> </el-input>
      <el-button
        style="background-color: #57a0ff; color: #fff; border-radius: 0"
        icon="el-icon-search"
        @click="formSubmit"
      >
      </el-button>
    </div>
    <div class="flex flex-row">
      <div class="label">商品排序：</div>
      <div class="items">
        <div :class="data.selectIndex == 1 ? 'item item-active' : 'item'" @click="change(1)">最新↓</div>
        <div :class="data.selectIndex == 2 ? 'item item-active' : 'item'" @click="change(2)">价值↓</div>
        <div :class="data.selectIndex == 3 ? 'item item-active' : 'item'" @click="change(3)">奖励↓</div>
      </div>
    </div>
    <div class="action">
      <el-checkbox label="自动刷新" v-model="data.autoUpdate" @change="update"></el-checkbox>
    </div>

    <el-dialog
      v-if="!isMobile"
      title="新手必知（情节严重直接封号）"
      :visible.sync="noticeDialogVisible"
      @close="noticeDialogVisible = false"
      width="40%"
      center
    >
      <div class="flex-row" style="align-items: center; justify-content: center">
        <div class="ql-editor px-4 border-gray-200 flex-grow" v-html="systemConfig.HOME_PAGE_NOTICE"></div>
      </div>
    </el-dialog>

    <el-dialog
      v-if="isMobile"
      title="新手必知（情节严重直接封号）"
      :visible.sync="noticeDialogVisible"
      @close="noticeDialogVisible = false"
      width="95%"
      center
    >
      <div class="flex-row" style="align-items: center; justify-content: center">
        <div class="ql-editor px-4 border-gray-200 flex-grow" v-html="systemConfig.HOME_PAGE_NOTICE"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
  },
  data() {
    return {
      data: {
        selectIndex: 0,
        platform: "TAO_BAO",
        autoUpdate: false,
        id: "",
        key: 0,
      },
      id: "",
      noticeDialogVisible: false,
      isMobile: false,
    };
  },
  computed: {
    systemConfig() {
      return this.$store.state.config.config;
    },
  },
  watch: {
    noticeDialogVisible(val) {
      if (val == false) {
        window.sessionStorage.setItem("noticeDialogWatch", "true");
      }
    },
  },
  created() {
    this.isMobile = document.documentElement.clientWidth <= 520;
    this.noticeDialogVisible = true;
    if (window.sessionStorage.getItem("noticeDialogWatch") == "true") {
      this.noticeDialogVisible = false;
    }
  },
  methods: {
    update() {
      this.$emit("input", this.data);
    },
    change(index) {
      if (this.data.selectIndex == index) {
        this.data.selectIndex = 0;
      } else {
        this.data.selectIndex = index;
      }
      this.update();
    },
    formSubmit() {
      this.data.id = this.id;
      this.data.key++;
      this.update();
    },
  },
};
</script>

<style scoped>
@import url("../../assets/quill.core.css");
</style>

<style lang="less" scoped>
.box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem;
  .label {
    width: 100px;
  }
  .items {
    margin-right: 100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    .item {
      width: 50px;
      cursor: pointer;
    }
    .item-active {
      color: #57a0ff;
    }
  }
  .item_form {
    display: none;
  }
  .search {
    /deep/ .el-form-item {
      margin: 0;
    }
    /deep/ .el-form-item__content {
      line-height: 100%;
    }
  }
  .action {
    flex: 1;
    text-align: right;
    .action-item {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 640px) {
  .box {
    display: block;
    .label,
    .action {
      display: none;
    }
    .items {
      grid-template-columns: repeat(4, auto);
      align-items: center;
      text-align: center;
      grid-gap: 5px;
      margin-right: 0;
      margin-bottom: 1rem;
      .item {
        width: auto;
        margin-right: 20px;
        margin-top: 10px;
      }

      .item_form-input {
        display: block;
        width: 50px;
        /deep/ .el-input__inner {
          background: none;
          height: 25px;
          line-height: 25px;
        }
      }

      /deep/ .el-form-item {
        margin: 0;
      }
      /deep/ .el-form-item__content {
        line-height: 100%;
      }
    }
  }
}
</style>
