import axios from "./axios";

const request = {
  get (url, params) {
    return axios.get(url, { params });
  },
  get2 (url, params, timeout) {
    return axios({
      method: 'get',
      url: url,
      data: params,
      timeout: timeout
    })
  },
  post (url, data) {
    return axios.post(url, data);
  },
  put (url, data) {
    return axios.put(url, data);
  },
  delete (url) {
    return axios.delete(url);
  },
};

export default request;
