<template>
  <div>
    <!-- <h2>HI!</h2>

        <p><router-link to="/trial">试用大厅</router-link></p>
        <p><router-link to="/buy">买号列表</router-link></p>
        <p><router-link to="/user/trial">我的试用</router-link></p>
        <p><router-link to="/user/recharge">充值</router-link></p>
        <p><router-link to="/user/withdrawal">提现</router-link></p>
        <p><router-link to="/user/billing">资金明细</router-link></p>
        <p><router-link to="/user">会员中心</router-link></p>
        <p><router-link to="/user/profile">个人资料</router-link></p>
        <p><router-link to="/user/message">站内信</router-link></p>
        <p><router-link to="/login">登录</router-link></p>
        <p><router-link to="/register">注册</router-link></p>
        <p><router-link to="/password">忘记密码</router-link></p>
        <br>
        <p><router-link to="/seller/trial">试用中心</router-link></p>
        <p><router-link to="/seller/trialCreate">发布试用</router-link></p>
        <p><router-link to="/seller/template">模板管理</router-link></p>
        <p><router-link to="/seller/blacklist">黑名单</router-link></p>
        <p><router-link to="/seller/bindshop">绑定店铺</router-link></p> -->
  </div>
</template>

<script>
export default {
  created() {
    if (this.userInfo.isLogin) {
      if (this.userInfo.role == 'SELLER') {
        this.$router.push('/seller/trial');
      } else {
        this.$router.push('/trial');
      }
    } else {
        this.$router.push('/trial');
      }
  },
  computed: {
    userInfo() {
      return this.$store.state.user;
    }
  }
}
</script>