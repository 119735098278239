<template>
  <div class="c_main">
    <el-form ref="formData" :model="formData" :rules="formRule" label-width="120px" :inline-message="true">
      <el-form-item label="所属分类：" prop="platformType">
        <el-select v-model="formData.platformType" @change="getShopList" class="form-input" size="small">
          <el-option label="天猫淘宝" value="TAO_BAO"> </el-option>
          <el-option label="拼多多" value="PDD"> </el-option>
          <el-option label="京东" value="JD"> </el-option>
          <el-option label="抖音" value="DOU_YIN"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="店铺：" prop="shopId">
        <el-select v-model="formData.shopId" class="form-input" size="small">
          <el-option :label="shop.name" :value="shop.id" v-for="shop in shops" :key="shop.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="宝贝分类：" prop="cate1Id" v-if="formData.platformType == 'TAO_BAO'">
        <el-select v-model="formData.cate1Id" class="form-input" size="small">
          <el-option
            :label="category.name"
            :value="category.id"
            v-for="category in categorys"
            :key="category.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="商品链接地址：" prop="link">
        <el-input v-model.trim="formData.link" class="form-input" size="small"></el-input>
        <span class="help">只用做验证，可写淘口令</span>
      </el-form-item>

      <el-form-item label="商品主图：" prop="goodsImage">
        <uploader-group v-model="formData.goodsImage" :type="'task'"></uploader-group>
        <div class="help_block">
          如果没有搜索排名，请上传店铺或商品链接二维码。如果用淘口令模式，下方搜索词，填上淘口令就可以。
        </div>
      </el-form-item>

      <el-form-item label="入口类型：" prop="searchMethod">
        <el-radio-group v-model="formData.searchMethod" size="small">
          <el-radio label="NORMAL_SEARCH">普通搜索</el-radio>
          <el-radio label="DIRECT_SEARCH">直通车搜索</el-radio>
          <el-radio label="SCAN_SEARCH">扫码进入</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="搜索词：" prop="searchKeyList">
        <el-button @click="addSerachKeywordFun" type="primary" size="small">新增搜索词</el-button>

        <div class="searchkeyword-form-item" v-for="(tpl, tpl_index) in formData.searchKeyList" :key="tpl_index">
          <div class="searchkeyword-form-item-left">
            <el-input v-model="tpl.words" class="form-input" size="small" placeholder="搜索词"></el-input>
          </div>
          <div class="searchkeyword-form-item-right">
            <div><span>任务数量：</span>
              <el-input-number v-model="tpl.num" class="form-input" @change="$emit('refreshPrice', formData)"
              size="small" :min="1"></el-input-number></div>
          </div>
          <div class="searchkeyword-form-item-del">
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="small"
              circle
              @click="deleteSerachKeywordFun(tpl_index)"
            ></el-button>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="搜索提示：" prop="searchPrompt">
        <el-input v-model.trim="formData.searchPrompt" class="form-input" size="small" style="width: 100%"></el-input>
        <div class="help_block">必填。商品通过搜索词搜索结果列表中大约位置，例如：搜索结果第一页大约第三个。</div>
      </el-form-item>

      <el-form-item label="魔法标签：" prop="searchTagList">
        <el-button @click="addMagicTagFun" type="primary" size="small">新增标签词</el-button>
        <span class="help">{{ systemConfig.TASK_MO_FA_BIAO_QIAN_JIA_GE }}</span>

        <div class="searchkeyword-form-item" v-for="(tpl, tpl_index) in formData.searchTagList" :key="tpl_index">
          <div class="searchkeyword-form-item-left">
            <el-input v-model.trim="tpl.words" class="form-input" size="small"></el-input>
          </div>
          <div class="searchkeyword-form-item-del">
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="small"
              circle
              @click="deleteMagicTagFun(tpl_index)"
            ></el-button>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="商品单价：" prop="goodsPrice">
        <el-input-number v-model="formData.goodsPrice" class="form-input"
          size="small" @change="$emit('refreshPrice', formData)" :min="0" :precision="2"></el-input-number>
      </el-form-item>

      <el-form-item label="购买数量：" prop="goodsNum">
        <el-input-number v-model.trim="formData.goodsNum" class="form-input"
          size="small" @change="$emit('refreshPrice', formData)" :min="1" ></el-input-number>
      </el-form-item>

      <el-form-item label="下单方式：" prop="groupBuyMethod" v-if="formData.platformType == 'PDD'">
        <el-radio-group v-model="formData.groupBuyMethod" size="small">
          <el-radio label="JOIN_OR_OPEN">有团参团，无团再开</el-radio>
          <el-radio label="SINGLE">单买</el-radio>
          <el-radio label="JOIN">参团</el-radio>
          <el-radio label="OPEN">开团</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="商品规格：" prop="spec">
        <el-input v-model.trim="formData.spec" class="form-input" size="small"></el-input>
      </el-form-item>

      <el-form-item label="附带商品：">
        <el-button @click="addAttachGoodsFun" type="primary" size="small">新增附带商品</el-button>
        <span class="help">{{ systemConfig.TASK_FU_DAI_SHANG_PING_JIA_GE }}</span>

        <div class="attach-goods-form-item" v-for="(tpl, tpl_index) in formData.attachGoodsList" :key="tpl_index">
          <div class="attach-goods-form-item-img">
            <uploader v-model="tpl.imgs" :type="'task'"></uploader>
          </div>
          <div class="attach-goods-form-item-info">
            <div>
              <span>商品单价：</span>
              <el-input-number v-model.trim="tpl.goodsPrice" @change="$emit('refreshPrice', formData)"
                class="form-input" size="small" :min="0" :precision="2"></el-input-number>
            </div>
            <div>
              <span>购买数量：</span>
              <el-input-number v-model.trim="tpl.goodsNum" @change="$emit('refreshPrice', formData)"
                class="form-input" size="small" :min="1" ></el-input-number>
            </div>
            <div><span>商品规格：</span><el-input v-model.trim="tpl.spec" class="form-input" size="small"></el-input></div>
          </div>
          <div class="attach-goods-form-item-del">
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="small"
              circle
              @click="deleteAttachGoodsFun(tpl_index)"
            ></el-button>
          </div>
        </div>
      </el-form-item>
      
      <el-form-item label="返款方式：" prop="refundMethod">
        <el-radio-group v-model="formData.refundMethod" @change="$emit('refreshPrice', formData)">
          <el-radio label="PLATFORM">平台返款</el-radio>
          <el-radio label="SELLER">商家返款</el-radio>
        </el-radio-group>
        <span class="help">注意：虚拟产品只接受平台返款，商家返现请在24小时内完成返款</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getList as shopList } from "@/api/shop";
import { goodsCateList } from "@/api/goods";
import uploaderGroup from "@/components/uploader-group.vue";
import uploader from "@/components/uploader.vue";

export default {
  props: {
    forms: Array,
  },
  components: {
    uploaderGroup,
    uploader,
  },
  data() {
    return {
      shops: [],
      categorys: [],
      formData: {
        // goodsName: "",
        platformType: "TAO_BAO",
        shopId: null,
        cate1Id: null,
        link: "",
        goodsImage: [],
        attachGoodsList: [],
        searchPrompt: "",
        searchKeyList: [],
        searchTagList: [],
        goodsPrice: 0,
        goodsNum: 1,
        groupBuyMethod: "SINGLE",
        searchMethod: "NORMAL_SEARCH",
        spec: "",
        refundMethod: 'PLATFORM',
      },
      formRule: {
        // name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        platformType: [{ required: true, message: "请选择平台", trigger: "change" }],
        shopId: [{ required: true, message: "请选择店铺", trigger: "change" }],
        // cate1Id: [{ required: true, message: "请选择分类", trigger: "change" }],
        link: [{ required: true, message: "请输入商品链接", trigger: "blur" }],
        goodsImage: [{ required: true, message: "请上传商品主图", trigger: "change" }],
        // searchPrompt: [{ required: true, message: "请输入搜索提示词", trigger: "blur" }],
        goodsPrice: [{ required: true, message: "请输入商品单价", trigger: "blur" }],
        goodsNum: [{ required: true, message: "请输入购买数量", trigger: "blur" }],
        spec: [{ required: true, message: "请输入商品规格", trigger: "blur" }],
        refundMethod: [{ required: true, message: "请选择返款方式", trigger: "change" }],
        searchMethod: [{ required: true, message: "请选择入口类型", trigger: "change" }],
        // groupBuyMethod: [{ required: true, message: "请选择下单方式", trigger: "change" }],
      },
    };
  },
  created() {
    this.loadData();
  },
  mounted() {
    if (Object.keys(this.forms[0].formData).length > 0) {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = this.forms[0].formData[key];
      });
    }
  },
  computed: {
    systemConfig() {
      return this.$store.state.config.config;
    },
    // priceConfig() {
    //   return this.$store.state.config.priceConfig;
    // },
  },
  methods: {
    async getShopList() {
      this.$emit("refreshPrice", this.formData)
      const {
        data: { list },
      } = await shopList({
        sellerId: this.$store.state.user.id,
        platformType: this.formData.platformType,
        status: 1
      });
      this.shops = list;
    },
    async loadData() {
      const {
        data: { list },
      } = await shopList({
        sellerId: this.$store.state.user.id,
        platformType: this.formData.platformType,
        status: 1
      });
      this.shops = list;

      const {
        data: { list: catesList },
      } = await goodsCateList();
      this.categorys = catesList;
    },
    // 表单提交，父组件访问此方法，如果表单验证成功返回表单数据，否则返回null
    async submitForm(formName) {
      if (!formName) formName = "formData";
      const result = await this.$refs[formName].validate();
      if (result) {
        return { ...this.formData, imgs: this.formData.goodsImage.join(",") };
      } else {
        return false;
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handler(newValue, oldValue) {
      console.log(newValue, oldValue);
    },
    handleAvatarSuccess(res, file) {
      this.image = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    addAttachGoodsFun() {
      this.formData.attachGoodsList.push({
        imgs: "",
        goodsPrice: 0,
        goodsNum: 1,
        spec: "",
      });
      this.$emit("refreshPrice", this.formData);
    },
    deleteAttachGoodsFun(i) {
      this.formData.attachGoodsList.splice(i, 1);
      this.$emit("refreshPrice", this.formData);
    },
    addSerachKeywordFun() {
      this.formData.searchKeyList.push({
        words: "",
        num: 1,
      });
      this.$emit("refreshPrice", this.formData);
    },
    deleteSerachKeywordFun(i) {
      this.formData.searchKeyList.splice(i, 1);
      this.$emit("refreshPrice", this.formData);
    },
    addMagicTagFun() {
      this.formData.searchTagList.push({ words: "" });
      this.$emit("refreshPrice", this.formData);
    },
    deleteMagicTagFun(i) {
      this.formData.searchTagList.splice(i, 1);
      this.$emit("refreshPrice", this.formData);
    },
  },
};
</script>

<style lang="less" scoped>
.c_main {
  .form-input {
    width: 200px;
  }

  .help {
    padding: 0 1rem;
    color: #999;
  }
  .help_block {
    color: #999;
  }

  .avatar-uploader /deep/.el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }

  .attach-goods-form-item {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 0.5rem;
    margin-top: 0.5rem;
    .attach-goods-form-item-img {
      width: 100px;
    }
    .attach-goods-form-item-info {
      flex: 1;
      padding: 0 2rem;
    }
    .attach-goods-form-item-del {
      width: 50px;
    }
  }

  .searchkeyword-form-item {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    .searchkeyword-form-item-left {
      width: 240px;
    }
    .searchkeyword-form-item-right {
      width: 340px;
    }
    .searchkeyword-form-item-del {
      width: 50px;
    }
  }
}

@media (max-width: 520px) {
  .c_main {
    .help {
      display: block;
      padding: 0;
    }

    .attach-goods-form-item,
    .searchkeyword-form-item {
      flex-direction: column;
    }

    .attach-goods-form-item {
      margin-left: -100px;
      .attach-goods-form-item-img,
      .attach-goods-form-item-info {
        flex: 1;
      }
    }

    .el-radio {
      margin-bottom: 15px;
    }
  }
}
</style>
