import request from "@/plugin/request";

export const getBuyAccountList = (queryParams, page = {
    size: 100,
    current: 1
}) => request.post('/buyAccount/getList', {
    page, queryParams
})

export const switchStatus = (id, status) => request.post('/buyAccount/statusSwitch', {
    id, status
})

export const deleteAccount = (id) => request.get('/buyAccount/delete?id=' + id)

export const addAccount = (data) => request.post('/buyAccount/add', data)