<template>
  <div class="process-main">
    <div class="process-k-item">1.确认以上步骤按要求完成</div>
    <div class="process-k-item">2.确认宝贝已确认收货并做出客观真实的评价</div>
    <div class="process-k-item">
      3.请点击 &nbsp;
      <el-button type="danger" size="small" round :disabled="isDone" @click="confirm" :loading="loading">
        已确认收货
      </el-button>
    </div>
  </div>
</template>

<script>
import { updateFlow } from "@/api/task";

export default {
  name: "",
  props: {
    stepData: {
      type: Object,
    },
  },
  created() {
    // this.data = this.stepData
  },
  data() {
    return {
      isDone: false,
      loading: false,
      data: {},
    };
  },
  methods: {
    confirm() {
      this.$confirm("是否确认收货？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass:'cfm-model',
        type: "warning",
      }).then(async () => {
        this.loading = true;
        try {
          await updateFlow({
            taskId: this.stepData.id,
            flowCode: 'que_ren_shou_huo',
          });
        } catch (error) {
          this.loading = false;
          return;
        }
        this.isDone = true;
        this.loading = false;
        this.$emit("update-done", true);
      });
    },
  },
};
</script>

<style>
  .cfm-model  {
    width: 360px !important;
  }
</style>

<style lang="less" scoped>
.process-main {
  .process-k-item {
    margin-bottom: 0.5rem;
  }
}
</style>
