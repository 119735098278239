<template>
  <div>{{ stepData.messagePrompt }}</div>
</template>

<script>
// 原型：商家提醒
export default {
  name: "",
  props: {
    stepData: {
      type: Object,
    },
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        console.log(newValue, oldValue);
        this.$emit("update-done", true);
      },
      deep: true,
    },
  },
  created() {
    // this.data = this.stepData
  },
  mounted() {
    setTimeout(() => {
      this.data.test = 1;
    }, 4000);
  },
  data() {
    return {
      data: {
        test: 2,
      },
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped></style>
