<template>
  <div>
    <div class="item relative">
      <div
        v-if="data.isRefunded"
        class="absolute rounded-lg px-3 py-0.5 top-[45px] right-[15%] tracking-4px text-red-500 font-bold transform rotate-10 ring-red-500 ring-4"
      >
        已返款
      </div>

      <div class="meta_bar">
        <div class="meta_item text-blue-400">
          试用编号：
          <span class="text-red-400">{{ data.id }}</span>
          【{{ data.platformType | filterPlatform }}】
        </div>
        <div class="flex right-bar">
          <div class="meta_item mr-4" v-if="data.isRefunded">
            返款方式：{{ data.refundReceivePayMethod | filterPayMethod }}
          </div>
          <div class="meta_item mr-4" v-if="data.sellerComment && data.sellerComment != ''">
            评价：{{ data.sellerComment | filterCommentLevel }}
          </div>
          <div class="meta_item mr-4" v-if="data.isRefunded">返款时间：{{ data.refundTime }}</div>
          <div class="meta_item" style='margin-right: 20px;'>发布时间：{{ data.publishTime }}</div>
          <div class="meta_item">操作时间：{{ data.updateTime }}</div>
        </div>
      </div>
      <div class="content">
        <div class="content_main">
          <div class="content_info">
            <div style="display: flex; flex-direction: column; justify-content: flex-start; ">
              <img :src="data.imgs.split(',')[0]" alt="" class="thumb self-start" />
              <span class="thumb self-start">{{ data.searchKey }}</span>
            </div>
            <div class="data">
              <div class="data_header">
                <div class="data_item">
                  店铺：<span>{{ data.shop?.name }}</span>
                </div>
                <div class="data_item">
                  宝贝单价：<span>{{ data.goodsPrice | priceFormat }}</span>
                  元
                </div>
                <div class="data_item">
                  下单总价：<span>{{ data.actualGoodsFee ? data.actualGoodsFee : data.goodsFee | priceFormat }}</span
                  >元
                </div>
                <div class="data_item">
                  奖励：<span>{{ data.buyerCommissionFeeBuyerReceived | priceFormat }}</span>
                  元
                </div>
                <div class="data_item">
                  订单号：<span>{{ data.ordersId }}</span>
                </div>
                <div class="data_item">
                  买号：<span>{{ data.buyAccount?.account }}</span>
                </div>
              </div>
              <div class="data_tag">
                <tag v-for="(item, index) in tags" :key="index">
                  {{ item }}
                </tag>
              </div>
            </div>
          </div>
          <div class="content_status">
            <div class="mob_status_label">试用状态：</div>
            <div>
              <div class="content_status_item !py-1 text-12px" @click="clickStatus">
                {{ status }}
              </div>
              <div
                class="content_status_item !py-1 text-12px"
                v-if="data.status == 'ORDERED' && data.isActualGoodsFeeConfirmed == 0"
              >
                待商家确认付款金额
              </div>
              <div
                class="content_status_item !py-1 text-12px"
                v-if="data.status == 'ORDERED' && data.isActualGoodsFeeConfirmed == 1"
              >
                商家已确认付款金额
              </div>
              <!-- <div class="content_status_item" @click="complainDetailShow = true">投诉中</div> -->
              <div
                class="content_status_item !py-1 text-12px"
                v-if="data.status == 'TOOK' && data.recycleLeftTime"
              >
                下单剩余时间：{{ data.recycleLeftTime }}
              </div>
            </div>
          </div>
          <div class="content_action sm:(flex flex-col justify-center)">
            <div class="content_action_btns">
              <el-button
                @click="processFun()"
                class="form-btn !ml-0"
                type="primary"
                size="small"
                v-if="data.status == 'ORDERED' || data.status == 'TOOK'"
              >
                继续任务
              </el-button>

              <el-button
                @click="cancelTrail"
                class="form-btn !ml-0"
                type="danger"
                size="small"
                v-if="data.status == 'TOOK' || data.status == 'WAITING_AUDIT'"
              >
                取消试用
              </el-button>

              <el-popover
                placement="bottom"
                width="200"
                trigger="click"
                v-if="data.status == 'FINISHED' && (!data.buyerComment || data.buyerComment == '')"
              >
                <el-button class="form-btn" type="primary" size="small" slot="reference"> 评价 </el-button>
                <div class="flex flex-col gap-y-1">
                  <el-button class="form-btn" type="success" size="small" slot="reference" @click="remark('GOOD')"
                    >好评</el-button
                  >
                  <el-button
                    class="form-btn !ml-0"
                    type="primary"
                    size="small"
                    slot="reference"
                    @click="remark('NORMAL')"
                    >中评</el-button
                  >
                  <el-button class="form-btn !ml-0" type="danger" size="small" slot="reference" @click="remark('BAD')"
                    >差评</el-button
                  >
                </div>
              </el-popover>

              <el-button
                class="form-btn !ml-0"
                type="info"
                size="small"
                @click="complainShow = true"
                v-if="data.status == 'ORDERED' || data.status == 'TOOK' || data.status == 'GOODS_RECEIVED'"
                >投诉对方
              </el-button>

              <el-button
                class="form-btn !ml-0"
                type="danger"
                size="small"
                @click="cancelComplain"
                v-if="data.status == 'COMPLAIN' && role == data.complainUserRole"
              >
                关闭投诉
              </el-button>
              <el-button
                class="form-btn !ml-0"
                type="info"
                size="small"
                plain
                @click="showComplainDetail"
                v-if="data.complainList != undefined && data.complainList.length > 0"
              >
                查看投诉
              </el-button>
              <el-button
                class="form-btn !ml-0"
                type="info"
                size="small"
                plain
                @click="replyComplainShow = true"
                v-if="data.complainList != undefined && data.complainList.length > 0"
              >
                回复投诉
              </el-button>
              <el-button
                class="form-btn !ml-0"
                type="warning"
                plain
                size="small"
                @click="complainToPlatform"
                v-if="data.status == 'COMPLAIN' && !data.complainNeedPlatformHandle"
              >
                客服介入
              </el-button>
              <el-button class="form-btn !ml-0" type="danger" size="small" v-else-if="data.status == 'COMPLAIN'">
                客服已介入
              </el-button>
              <el-button
                @click="showSnapshots = true"
                class="form-btn !ml-0"
                type="primary"
                size="small"
                v-if="data.status == 'ORDERED' || data.status == 'GOODS_RECEIVED' || data.status == 'FINISHED'"
              >
                试用截图
              </el-button>
              <el-button
                @click="processFun('backWatch')"
                class="form-btn !ml-0"
                type="info"
                plain
                size="small"
                v-if="data.status == 'TOOK' || data.status == 'ORDERED' || data.status == 'GOODS_RECEIVED'
                  || data.status == 'FINISHED' || data.status == 'COMPLAIN'"
              >
                回看
              </el-button>
            </div>
          </div>
        </div>
        <div class="content_footer">
          <div>商家：{{ data.seller?.loginName }}</div>
          <div>
            <el-form :inline="true" :model="formData" class="form">
              <el-form-item label="备注" class="form-item">
                <el-input class="form-input" v-model="formData.remarks" size="small"> </el-input>
              </el-form-item>
              <el-form-item class="form-item">
                <el-button @click="remarksSubmit" type="primary" size="small">保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹窗 START -->
    <el-dialog
      title="试用流程"
      :visible.sync="processShow"
      class="dialog"
      center
      width="50%"
      :fullscreen="processDialogFull"
    >
      <div>
        <TrialProcess
          v-if="processShow"
          :detail="data"
          :backWatch="backWatch"
          @refresh="$emit('refresh')"
          @close="processShow = false"
        />
      </div>
    </el-dialog>

    <el-dialog
      title="投诉对方"
      :visible.sync="complainShow"
      class="dialog"
      width="40%"
      center
      :fullscreen="processDialogFull"
    >
      <div>
        <el-form ref="complainFormData" :model="complainFormData" :rules="complainFormRules" label-position="top">
          <el-form-item label="投诉内容：" prop="content">
            <el-input type="textarea" v-model="complainFormData.content" :rows="4"></el-input>
          </el-form-item>

          <el-form-item label="投诉图片：" prop="image">
            <uploader-group v-model="complainFormData.image" :type="'task'"></uploader-group>
          </el-form-item>

          <el-form-item class="w-120px">
            <el-button type="primary" @click="complainSubmit('complainFormData')" size="small" style="width: 100%">
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog
      title="回复投诉"
      :visible.sync="replyComplainShow"
      class="dialog"
      width="40%"
      center
      :fullscreen="processDialogFull"
    >
      <div>
        <el-form ref="complainFormData" :model="complainFormData" :rules="complainFormRules" label-position="top">
          <el-form-item label="回复内容：" prop="content">
            <el-input type="textarea" v-model="complainFormData.content" :rows="4"></el-input>
          </el-form-item>

          <el-form-item label="回复图片：" prop="image">
            <uploader-group v-model="complainFormData.image" :type="'task'"></uploader-group>
          </el-form-item>

          <el-form-item class="w-120px">
            <el-button type="primary" @click="replyComplainSubmit('complainFormData')" size="small" style="width: 100%">
              回复
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="complainDetailShow" class="dialog" width="40%" center :fullscreen="processDialogFull">
      <div>
        <div class="complain-detail" v-for="(item, index) in data.complainList" :key="item.id">
          <div class="complain-detail-item" style="font-weight: bold;">
            <div class="complain-detail-item-label">用户：</div>
            <div class="complain-detail-item-value" v-if="item.userRole == 'BUYER'">试客</div>
            <div class="complain-detail-item-value" v-if="item.userRole == 'SELLER'">商家</div>
          </div>
          <div class="complain-detail-item">
            <div class="complain-detail-item-label">内容：</div>
            <div class="complain-detail-item-value">
              {{ item.content }}
            </div>
          </div>
          <div class="complain-detail-item">
            <div class="complain-detail-item-label">图片：</div>
            <div class="complain-detail-item-value" v-if="item.imgs">
              <el-image
                style="width: 100px; height: 100px; margin-right: 5px"
                :src="imgSrc"
                fit="cover"
                :key="imgSrc"
                v-for="imgSrc in item.imgs.split(',')"
              ></el-image>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    
    <TrialSnapshot
      v-if="data.status == 'ORDERED' || data.status == 'GOODS_RECEIVED' || data.status == 'FINISHED'"
      v-model="showSnapshots"
      :id="data.id"
    />
    <!-- 弹窗 END -->
  </div>
</template>

<script>
import TrialProcess from "@/components/trial/TrialProcess.vue";
import tag from "@/components/tag/Tag.vue";
import filter from "@/plugin/filter";
import uploaderGroup from "@/components/uploader-group.vue";
import TrialSnapshot from "@/components/seller/TrialSnapshot.vue";
import { saveRemark, cancelTrial, complain, replyComplain, cancelComplain, 
  closeComplain, complainToPlatform, comment } from "@/api/task";
export default {
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    tag,
    TrialProcess,
    uploaderGroup,
    TrialSnapshot
  },
  data() {
    return {
      processDialogFull: false,
      formData: {
        remarks: "",
      },
      processShow: false,
      backWatch: false,
      complainShow: false, // 投诉弹窗
      replyComplainShow: false,
      complainDetailShow: false, // 投诉查看
      showSnapshots: false,
      complainFormData: {
        content: "",
        image: [],
      },
      complainFormRules: {
        content: [
          {
            required: true,
            message: "请输入投诉内容",
            trigger: "blur",
          },
        ],
        // image: [
        //   {
        //     required: true,
        //     message: "请上传至少一张图片",
        //     trigger: "change",
        //   },
        // ],
      },
    };
  },
  watch: {
    processShow(val) {
      if (!val) {
        this.backWatch = false;
      }
    },
  },
  computed: {
    role() {
      return this.$store.state.user.role;
    },
    tags() {
      return filter.filterTags(this.data);
    },
    status() {
      const statusMap = {
        WAITING_AUDIT: "待审核",
        TOOK: "已接单",
        ORDERED: "已下单",
        GOODS_RECEIVED: "已确认收货",
        FINISHED: "已完成",
        COMPLAIN: "投诉中",
      };
      // 根据当前状态返回当前状态以及之前的状态
      return statusMap[this.data.status];
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.formData.remarks = this.role == "BUYER" ? this.data.buyerRemark : this.data.sellerRemark;
    });
    if (document.documentElement.clientWidth <= 520) this.processDialogFull = true;
  },
  methods: {
    // 备注按钮
    async remarksSubmit() {
      if (this.formData.remarks == "") {
        this.$message.error("请输入备注内容");
        return false;
      }

      const params =
        this.role == "SELLER"
          ? { id: this.data.id, sellerRemark: this.formData.remarks }
          : { id: this.data.id, buyerRemark: this.formData.remarks };
      await saveRemark(params);
      this.$message.success("备注成功");
    },
    async cancelTrail() {
      this.$confirm("确定取消试用吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await cancelTrial(this.data.id);
        this.$message.success("取消成功");
        this.$emit("refresh");
      });
    },
    // 试用流程弹窗
    processFun(backWatch = "") {
      if (this.data.status == "ORDERED" && this.data.isActualGoodsFeeConfirmed == 0) {
        this.$message.warning("请等待商家确认付款金额");
        return;
      }
      if (backWatch != "") {
        this.backWatch = true;
      }
      this.processShow = true;
    },
    // 投诉表单提交
    complainSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await complain({
            taskId: this.data.id,
            userRole: this.role,
            content: this.complainFormData.content,
            imgs: this.complainFormData.image.join(","),
          });
          this.$message.success("投诉成功");
          this.$emit("refresh");
          this.complainShow = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 投诉回复表单提交
    replyComplainSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await replyComplain({
            taskId: this.data.id,
            userRole: this.role,
            content: this.complainFormData.content,
            imgs: this.complainFormData.image.join(","),
          });
          this.$message.success("回复成功");
          this.$emit("refresh");
          this.replyComplainShow = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancelComplain() {
      this.$confirm("确定关闭投诉吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await closeComplain(this.data.id);
        this.$message.success("关闭成功");
        this.$emit("refresh");
      });
    },
    complainToPlatform() {
      this.$confirm("确定投诉到平台吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await complainToPlatform(this.data.id);
        this.$message.success("投诉成功");
        this.$emit("refresh");
      });
    },
    clickStatus() {
    },
    showComplainDetail() {
      this.complainDetailShow = true;
    },
    remark(level) {
      const remarkLevel = level === "GOOD" ? "好评" : level === "NORMAL" ? "中评" : "差评";
      this.$confirm(`确定要给出${remarkLevel}吗？`, "提示", {
        showCancelButton: true,
      }).then(async ({ value }) => {
        await comment(this.data.id, "BUYER", level);
        this.$message.success("评价成功");
        this.$emit("refresh");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  margin-bottom: 1rem;
  background-color: #fff;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
  .meta_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #dadada;
    padding: 1rem;
    margin-bottom: 0.5rem;
    // .meta_item {}
  }
  .right-bar {
    display: flex;
    flex-direction: row;
  }

  .content {
    padding: 0 1rem;

    .content_main {
      display: flex;
      border-bottom: 1px solid #dadada;
      align-items: stretch;

      .content_info {
        flex: 1;
        display: flex;
        align-items: center;
        border-right: 1px solid #dadada;
        padding: 1rem 0;

        .thumb {
          width: 140px;
        }
        .data {
          padding-left: 1rem;
          flex: 1;

          .data_header {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 5px;
            justify-content: space-between;
            margin-bottom: 1rem;
            // .data_item {}
            .mob_meta_item {
              display: none;
            }
          }
          .data_tag {
            padding: 0.5rem 0;
          }
        }
      }
      .content_status {
        width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-right: 1px solid #dadada;
        font-size: 0.5rem;
        .mob_status_label {
          display: none;
        }
        .content_status_item,
        .content_status_item_t {
          margin: 0 auto 5px;
          padding: 6px 0;
          border-radius: 22px;
          font-size: 12px;
          color: #e9b679;
          width: 140px;
        }
        .content_status_item_t {
          color: #fff;
          border: 1px solid #6c81ae;
          background-color: #666666;
        }
      }
      .content_action {
        text-align: center;
        width: 200px;
        .content_action_btns {
          line-height: 2.2rem;
        }
        .mob-content_action_btns {
          display: none;
        }
        .form-btn {
          padding: 8px 15px;
          width: 140px;
          border-radius: 12px;
        }
      }
    }

    .content_footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;

      .form {
        /deep/ .el-form-item {
          margin: 0;
        }
        .form-input {
          width: 200px;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.complain-detail {
  margin-top: 10px;
  .complain-detail-item {
    display: flex;
    margin-bottom: 1rem;
    .complain-detail-item-label {
      width: 100px;
      text-align: right;
    }
    .complain-detail-item-value {
      margin-left: 1rem;
      flex: 1;
    }
  }
}

@media (max-width: 520px) {
  .item {
    .meta_bar {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      // justify-content: space-between;
      // align-items: center;
      // border-bottom: 2px solid #dadada;
      padding: 10px 15px 5px;
      // margin-bottom: 0.5rem;
    }

    .right-bar {
      display: flex;
      flex-direction: column;
    }

    .content {
      padding: 0 1rem;

      .content_main {
        border-bottom: none;
        flex-direction: column;
        align-items: flex-start;

        .content_info {
          align-items: flex-start;
          border-right: none;

          .thumb {
            width: 60px;
          }
          .data {
            .data_header {
              grid-template-columns: repeat(2, 1fr);

              .mob_meta_item {
                display: block;
              }
            }
            .data_tag {
              padding: 0;
            }
          }
        }
        .content_status {
          width: 100%;
          text-align: left;
          border-right: none;
          align-items: center;
          justify-content: left;
          flex-flow: row nowrap;
          display: flex;
          .mob_status_label {
            display: block;
            margin-right: 0.5rem;
            font-size: 16px;
          }

          .content_status_item,
          .content_status_item_t {
            font-size: 16px;
            padding: 0;
            margin: 0;
            text-align: center;
            width: fit-content;
          }
        }
        .content_action {
          width: 100%;
          margin: 1rem 0;
          .content_action_btns {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1rem;
            justify-content: space-between;
            align-items: center;
            line-height: 0;
          }
          .pc-content_action_btns {
            display: none;
          }
          .mob-content_action_btns {
            display: block;
          }
          .form-btn {
            padding: 10px 15px;
            width: 100%;
            border-radius: 3px;
          }
        }
      }

      .content_footer {
        display: none;
      }
    }
  }
}
</style>
