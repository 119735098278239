import request from "@/plugin/request";

export const noticeList = (page, queryParams) => request.post('/notice/getUserNoticeList', {
    page, queryParams
})

export const readNotice = (ids) => request.post('/noticeRead/addRead?ids=' + ids, { ids })

export const noticeRemindReceive = (id) => request.post('/notice/remindReceive?taskId=' + id)

export const unreadNotice = () => request.get('/notice/getUnreadNotice')