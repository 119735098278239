export default {
  namespaced: true,
  state: () => ({
    config: {},
    priceConfig: {},
    tagColors: {}
  }),
  getters: {},
  mutations: {
    SET_CONFIG (state, payload) {
      state.config = payload;
    },
    SET_PRICE_CONFIG (state, payload) {
      state.priceConfig = payload;
    }
  },
};