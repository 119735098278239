<template>
  <div class="p_main">
    <div class="notice"><span>提现提示：</span>50起提不收任何费</div>

    <div class="handle">
      <div class="handle-item">
        <div class="handle-item_label">我的账户</div>
        <div class="handle-item_content">可用金额 {{ user.cash }} 元</div>
      </div>
      <div class="handle-item !h-120px">
        <div class="handle-item_label pt-3">提现说明</div>
        <div class="handle-item_content">
          <span class="item_content_item">- 24小时可提现次数1次</span>
          <span class="item_content_item">- 单次提现最大金额{{ systemConfig.WITHDRAW_MAX_AMOUNT }}元</span>
          <span class="item_content_item">- 单次提现最小金额{{ systemConfig.WITHDRAW_MIN_AMOUNT }}元</span>
        </div>
      </div>
      <div class="handle-item">
        <div class="handle-item_label">提现金额</div>
        <div class="handle-item_content pr-4">
          <el-input v-model.number="money" size="small" style="max-width: 240px"></el-input>
        </div>
      </div>
      <div class="handle-item">
        <div class="handle-item_label">支付密码</div>
        <div class="handle-item_content pr-4">
          <el-input v-model="password" type="password" size="small" style="max-width: 240px"></el-input>
        </div>
      </div>
      <div class="handle-item">
        <div class="handle-item_label">&nbsp;</div>
        <div class="handle-item_content">
          <el-button class="form-btn" type="primary" size="small" @click="withdrawal">确定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { withdrawal, loginUserInfo } from "@/api/user";
export default {
  data() {
    return {
      password: "",
      money: 0,
    };
  },
  computed: {
    systemConfig() {
      return this.$store.state.config.config;
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    async getLoginUserInfo() {
      const { data } = await loginUserInfo();
      this.$store.commit("user/LOGIN", data)
    },
    async withdrawal() {
      if (this.money < this.systemConfig.WITHDRAW_MIN_AMOUNT) {
        this.$message.error(`单次提现最小金额${this.systemConfig.WITHDRAW_MIN_AMOUNT}元`);
        return;
      }
      if (this.money > this.systemConfig.WITHDRAW_MAX_AMOUNT) {
        this.$message.error(`单次提现最大金额${this.systemConfig.WITHDRAW_MAX_AMOUNT}元`);
        return;
      }
      if (this.money > this.user.cash) {
        this.$message.error("可用金额不足");
        return;
      }
      if (this.password == "") {
        this.$message.error("请输入支付密码");
        return;
      }
      const { data } = await withdrawal(this.money, this.password);
      this.$message.success("提现申请发起成功");
      this.getLoginUserInfo()
    },
  },
};
</script>

<style lang="less" scoped>
.p_main {
  padding: 1rem;
  background-color: #fff;

  .notice {
    padding: 1rem;
    border: 1px solid #ccc;
    color: #666;
    margin-bottom: 1rem;
    span {
      color: inherit;
      font-weight: bold;
      font-size: 1rem;
    }
  }

  .tab {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    .tab-item {
      cursor: pointer;
      text-align: center;
      border: 1px solid #57a0ff;
      color: #57a0ff;
      width: 140px;
      height: 40px;
      line-height: 40px;
      &:last-child {
        border-left: none;
      }
      &:first-child {
        border-right: none;
      }
    }

    .tab-item_active {
      background-color: #57a0ff;
      color: #fff;
    }
  }

  .handle {
    border: 1px solid #ccc;

    .handle-item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ccc;
      height: 50px;
      line-height: 50px;

      &:last-child {
        border-bottom: none;
      }
      .handle-item_label {
        width: 30%;
        text-align: right;
        border-right: 1px solid #ccc;
        padding-right: 1rem;
        min-height: 100%;
      }
      .handle-item_content {
        padding-left: 1rem;
        flex: 1;

        .item_content_item {
          display: block;
          line-height: 25px;
        }
      }
    }
    .form-btn {
      border-radius: 12px;
      padding: 8px 20px;
    }
  }
}
</style>
