<template>
  <div>
    <el-container class="min-h-[100%] absolute w-full overflow-y-scroll" :style="{paddingBottom: isMobile?'50px':0}"> 
      <!-- 头部公共组件 START -->
      <el-header id="header" height="auto">
        <Header :shadowBox="false" />
      </el-header>
      <!-- 头部公共组件 END -->

      <!-- 动态区域 START -->
      <el-main>
        <div id="main"><router-view></router-view></div>
      </el-main>
      <!-- 动态区域 END -->

      <!-- 底部公共组件 START -->
      <el-footer id="footer" style="height: auto">
        <Footer />
      </el-footer>
      <!-- 底部公共组件 END -->
    </el-container>
    <MobileNav v-if="isMobile" />
  </div>
</template>

<script>
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";
import MobileNav from "@/components/public/MobileNav.vue";

export default {
    components: {
        Header,
        Footer,
        MobileNav,
    },
    data() {
        return {
            isMobile: false
        }
    },
    mounted() {
        this.isMobile = document.documentElement.clientWidth <= 520;
    },
};
</script>

<style lang="less" scoped>
#header {
  padding: 0;
  height: auto;
}

#main {
  padding-top: 140px;
}

#footer {
  padding: 0;
}
@media (max-width: 520px) {
  #main {
    padding-top: 70px;
  }
}
</style>

<style scoped>
.el-main {
  padding: 0;
}
</style>
