<template>
  <div>
    <div class="p_main">
      <!-- <div class="header justify-center flex mb-4 relative" @click="popularizeDialogShow = true">
        <el-image :src="require('@/assets/image/tg.png')" class="w-[100%] h-auto" />
      </div> -->
      <div class="v-container">
        <div class="main">
          <div class="tab">
            <div class="tab-item" @click="popularizeDialogShow = true">推广链接</div>
            <div :class="currentTabIndex == 0 ? 'tab-item tab-item_active' : 'tab-item'" @click="currentTabIndex = 0">
              直接推荐人
            </div>
            <div :class="currentTabIndex == 1 ? 'tab-item tab-item_active' : 'tab-item'" @click="currentTabIndex = 1">
              间接推荐人
            </div>
          </div>
          <div class="tab-content">
            <div class="explan">
              <div>
                <span style="font-size: 1.2rem; font-weight: bold; color: #6c7071">推广奖励标准</span>
                <span style="font-size: 1.2rem; font-weight: bold"
                  >每个任务获{{systemConfig.DIRECT_RECOM_REWARD}}元，间接推荐人每个人物获{{systemConfig.INDIRECT_RECOM_REWARD}}元。具体了解点击：</span
                ><span style="font-size: 1.2rem; font-weight: bold; color: #64a4ff">立刻查看</span>
              </div>
              <div style="color: #4074bf; font-weight: bold; margin-top: 5px">
                推广执行行业规则，有相应义务，同时要报备客服开通，否则无效。
              </div>
            </div>
            <div class="table w-[100%]" v-if="userInfo.isLogin">
              <el-table :data="tableData[currentTabIndex]" border style="width: 100%" v-loading="loading">
                <el-table-column prop="vipType.name" label="会员组" align="center" />
                <el-table-column prop="loginName" label="用户名" align="center" />
                <!-- <el-table-column prop="name" label="姓名" align="center" /> -->
                <!-- <el-table-column prop="isPay" label="是否缴费" align="center" width="100">
                  <template slot-scope="scope">
                    {{ scope.row.isPay == 1 ? "是" : "否" }}
                  </template>
                </el-table-column> -->
                <el-table-column prop="sex" label="性别" align="center" width="100">
                  <template slot-scope="scope">
                    {{ scope.row.sex == 'MALE' ? "男" : "女" }}
                  </template>
                </el-table-column>
                <el-table-column prop="createTime" label="注册时间" align="center" width="180" />
                <!-- <el-table-column prop="loginCount" label="登录次数" align="center" width="100" />
                <el-table-column prop="doneCount" label="完成试用数" align="center" width="100" /> -->
              </el-table>
            </div>
            <div v-else class="text-center p-4 text-lg">请先登录</div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="推广链接" width="30%" :visible.sync="popularizeDialogShow" :fullscreen="isMobile" class="dialog" center>
      <div class="popularize-dialog">
        <!-- <div class="popularize-dialog-item">
          <div class="popularize-dialog-item-label">推广资格</div>
          <div class="popularize-dialog-item-content">已开通推广功能</div>
        </div> -->
        <div class="popularize-dialog-item">
          <div class="popularize-dialog-item-label">引导至</div>
          <div class="popularize-dialog-item-content">
            <el-radio v-model="popularizeUrlData.page" label="register">会员注册</el-radio>
            <el-radio v-model="popularizeUrlData.page" label="trial">网站首页</el-radio>
          </div>
        </div>
        <div class="popularize-dialog-item">
          <div class="popularize-dialog-item-label">推广网址</div>
          <div class="popularize-dialog-item-content">
            <el-input size="small" v-model="url" readonly />
          </div>
        </div>
        <div class="popularize-dialog-item">
          <div class="popularize-dialog-item-label"></div>
          <div class="popularize-dialog-item-content" style="margin: 10px 0;">
            <el-button type="success" size="small" round @click="copy(url)"> 复制链接 </el-button>
          </div>
        </div>
        <!-- <div class="popularize-dialog-item">
          <div class="popularize-dialog-item-label">推广记录</div>
          <div class="popularize-dialog-item-content">
            <router-link to="">点击查看</router-link>
          </div>
        </div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRecomList, getIndirectRecomList } from "@/api/user";
export default {
  data() {
    return {
      popularizeDialogShow: false,
      currentTabIndex: 0,
      popularizeUrlData: {
        page: "register",
      },
      isMobile: false,
      tableData: [[], []],
      loading: false,
    };
  },
  computed: {
    systemConfig() {
      return this.$store.state.config.config;
    },
    userInfo() {
      return this.$store.state.user;
    },
    url() {
      var curUrl = window.location.href
      console.log('curUrl ' + curUrl)
      var index = curUrl.indexOf('popularize')
      console.log('find index ' + index)
      var domain = curUrl.substring(0, index - 1)
      return domain + `/${this.popularizeUrlData.page}?pid=${this.$store.state.user.id}`;
    },
  },
  mounted() {
    this.isMobile = document.documentElement.clientWidth <= 520;
    if (this.userInfo.isLogin) {
      this.getRecomList();
      this.getIndirectRecomList();
    }
  },
  methods: {
    copy(str) {
      this.$copyText(str).then(
        () => {
          this.$message.success("已复制");
        },
        () => {
          this.$message.error("复制失败");
        }
      );
    },
    async getIndirectRecomList() {
      const {
        data: { list },
      } = await getIndirectRecomList();
      this.tableData[1] = list;
    },
    async getRecomList() {
      this.loading = true;
      try {
        const {
          data: { list },
        } = await getRecomList();
        this.tableData[0] = list;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.p_main {
  .header {
    width: 100%;
    height: 800px;
  }
  .main {
    padding: 1rem;
    background-color: #fff;
    margin-bottom: 1rem;

    .tab {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
      .tab-item {
        cursor: pointer;
        text-align: center;
        border: 1px solid #57a0ff;
        color: #57a0ff;
        width: 140px;
        height: 40px;
        line-height: 40px;
        &:nth-child(even) {
          border-right: none;
          border-left: none;
        }
      }

      .tab-item_active {
        background-color: #57a0ff;
        color: #fff;
      }
    }

    .tab-content {
      margin-top: 2rem;
      .explan {
        padding: 1rem;
        border: 1px solid #eee;
      }
      .table {
        padding: 1rem 2rem;
      }
    }
  }
}

.popularize-dialog {
  border: 1px solid #eee;
  .popularize-dialog-item {
    display: flex;
    border-bottom: 1px solid #eee;
    align-items: center;
    &:last-child {
      border-bottom: none;
    }
    .popularize-dialog-item-label {
      width: 100px;
      text-align: right;
      border-right: 1px solid #eee;
      padding: 1rem 0.5rem;
    }
    .popularize-dialog-item-content {
      flex: 1;
      padding: 0 0.5rem;
    }
  }
}

@media (max-width: 520px) {
  .p_main {
    .header {
      display: none;
    }
    .main {
      .tab-content {
        .table {
          padding: 1rem 0;
        }
      }
    }
  }
}
</style>
