<template>
  <div>
    <div class="status-fixed">
      <div class="v-container">
        <div class="status-bar">
          <div
            class="status-item"
            :class="{ 'status-item-active': currentStatusIndex == index }"
            v-for="(item, index) in tabList"
            @click="currentStatusIndex = index"
          >
            <div class="status-item_text">
              {{ item.label }}
              <el-badge style="line-height: 100%" :value="statis[item.key]" v-if="statis[item.key]" />
            </div>
          </div>
          <div
            class="status-item"
            :class="{ 'status-item-active': currentStatusIndex == 6 }"
            @click="currentStatusIndex = 6"
          >
            <div class="status-item_text">全部任务</div>
          </div>
        </div>
      </div>
    </div>

    <div class="v-container">
      <div class="p_main">
        <div class="filter">
          <el-form :model="filterData" class="form">
            <el-form-item class="form-item">
              <el-select class="form-input" size="small" v-model="filterData.platformType" placeholder="平台类型">
                <el-option v-for="item in categoryOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item class="form-item">
              <el-input class="form-input" v-model.trim="filterData.no" size="small" placeholder="试用编号"> </el-input>
            </el-form-item>

            <!-- <el-form-item class="form-item" label="">
              <el-select class="form-input" size="small" v-model="filterData.isRefunded" placeholder="返款状态">
                <el-option v-for="item in refundStatusOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item> -->

            <el-form-item class="form-item">
              <el-select class="form-input" size="small" v-model="filterData.condition" placeholder="搜索条件">
                <el-option v-for="item in conditionOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item class="form-item">
              <el-input class="form-input" v-model.trim="filterData.value" size="small" placeholder="搜索值"> </el-input>
            </el-form-item>

            <el-form-item class="form-item" style="display: flex; flex-direction: row; width: 180px; padding-left: 10px;" >
              <el-button class="form-btn" type="primary" @click="formSubmit" size="small">搜索</el-button>
              <el-button class="form-btn" type="info" @click="resetForm" size="small">重置</el-button>
            </el-form-item>
          </el-form>
        </div>

        <div class="info">
          <div class="info-item">{{ systemConfig.TASK_JIE_DAN_TI_SHI }}</div>
        </div>

        <div class="trial-list">
          <TrialItem v-for="item in recordList" :key="item.id" :data="item" @refresh="updateData" />
        </div>

        <!-- <div class="trial-statistics">
          <div>统计：试用数量：{{ totalNum }}个</div>
          <div>任务总奖励：{{ totalPrice }}元</div>
        </div> -->
        <div class="pagination float-right">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            :page-sizes="[5, 30, 300, 1000]"
            :total="page.total"
            :page-size="page.size"
            :current-page.sync="page.current"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TrialItem from "@/components/my/TrialItem.vue";
import { getTaskList, statisBuyerTaskNum } from "@/api/task";

export default {
  components: {
    TrialItem,
  },
  data() {
    return {
      currentStatusIndex: 0,
      filterData: {
        platformType: null,
        no: "",
        condition: null,
        value: "",
        isRefunded: "",
      },
      refundStatusOptions: [
        { value: "", label: "" },
        { value: "1", label: "已返款" },
        { value: "0", label: "未返款" },
      ],
      categoryOptions: [
        { value: "", label: "全部" },
        { value: "TAO_BAO", label: "淘宝/天猫" },
        { value: "PDD", label: "拼多多" },
        { value: "JD", label: "京东" },
        { value: "DOU_YIN", label: "抖音" },
      ],
      conditionOptions: [
        { value: "shopName", label: "店铺" },
        { value: "buyAccountAccount", label: "买号" },
        { value: "ordersId", label: "订单号" },
        { value: "sellerQQ", label: "商家 QQ" },
        { value: "buyerQQ", label: "试客 QQ" },
      ],
      recordList: [],
      statis: {},
      tabList: [
        { label: "未审核", key: "WAITING_AUDIT" },
        { label: "已申请", key: "TOOK" },
        { label: "已下单", key: "ORDERED" },
        { label: "已确认收货", key: "GOODS_RECEIVED" },
        { label: "试用完成", key: "FINISHED" },
        { label: "投诉中", key: "COMPLAIN" },
      ],
      page: {
        current: 1,
        size: 5,
        total: 0,
      },
      loading: false,
      countdownInterval: '',
    };
  },
  mounted() {
    if (this.userInfo.isLogin) {
      this.updateData();
    } else {
      this.$router.push("/login");
    }

    this.countdownInterval = setInterval(() => {
      this.calcRecycleLeftTime()
    }, 1000)
  },
  beforeDestroy () {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval)
    }
  },
  watch: {
    currentStatusIndex() {
      this.recordList = [];
      const originPage = this.page;
      this.page.current = 1

      this.updateData(false);
    },
    "page.current"(val, oldVal) {
      if (val == null || oldVal == null) return;
      this.updateData(true);
    },
  },
  computed: {
    systemConfig() {
      return this.$store.state.config.config;
    },
    userInfo() {
      return this.$store.state.user;
    },
    totalNum() {
      return this.recordList.length;
    },
    totalPrice() {
      let total = 0;
      this.recordList.forEach((item) => {
        total += item.buyerCommissionFee;
      });
      return total;
    },
  },
  methods: {
    handleSizeChange(pageSize) {
      this.page.size = pageSize;
      this.getList();
    },
    calcRecycleLeftTime() {
      // console.log('------ calcRecycleLeftTime')
      this.recordList.forEach(item => {
        var leftTime = this.calcLeftTime(item.applyTime)
        this.$set(item, 'recycleLeftTime', leftTime)
      })
    },
    calcLeftTime(applyTime) {
      var applySec = new Date(applyTime).getTime() / 1000
      var addSec = this.systemConfig.TASK_NO_ORDER_AUTO_CANCEL_TIME * 60
      var sysSec = (new Date()).getTime() / 1000
      var leftSec = applySec + addSec - sysSec
      // console.log('sysSec ' + sysSec + ' addSec ' + addSec + ' applySec ' + applySec + ' leftSec ' + leftSec)
      if (leftSec > 0) {
        return this.formatSeconds(leftSec) 
      } else {
        return ''
      }
    },
    formatSeconds(value) {
      // console.log('formatSeconds ' + value)
      let result = parseInt(value)
      let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
      let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
      let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
  
      let res = '';
      // if(h !== '00') res += `${h}:`;
      res += `${h}:`;
      if(m !== '00') res += `${m}:`;
      res += `${s}`;
      return res;
    },
    // 表单提交
    formSubmit() {
      this.updateData();
    },
    // 重置表单
    resetForm() {
      this.filterData = {
        category: null,
        no: "",
        condition: null,
        value: "",
      };
    },
    async updateData(noStatis = false) {
      if (!noStatis) {
        this.getStatis();
      }

      // status
      // WAITING_AUDIT(2, "待审核"),
      // TOOK(3, "已接单"),
      // ORDERED(4, "已下单"),
      // GOODS_RECEIVED(5, "已确认收货"),
      // FINISHED(6, "已完成"),
      // COMPLAIN(7, "投诉中"),
      const statusMap = {
        0: "WAITING_AUDIT",
        1: "TOOK",
        2: "ORDERED",
        3: "GOODS_RECEIVED",
        4: "FINISHED",
        5: "COMPLAIN",
      };

      const params = {
        status: statusMap[this.currentStatusIndex],
        id: this.filterData.no == "" ? undefined : this.filterData.no,
        platformType: this.filterData.platformType == "" ? undefined : this.filterData.platformType,
      };
      params[this.filterData.condition] = this.filterData.value == "" ? undefined : this.filterData.value;

      this.loading = true;
      try {
        const {
          data: { list: recordList, page },
        } = await getTaskList(params, this.page);
        this.recordList = recordList;
        this.page = page;
      } finally {
        this.loading = false;
      }
    },

    async getStatis() {
      const { data } = await statisBuyerTaskNum();
      // [{status, num}] 转为对象
      const statis = {};
      data.forEach((item) => {
        statis[item.status] = item.num;
      });
      this.statis = statis;
    },
  },
};
</script>

<style lang="less" scoped>
.status-fixed {
  width: 100%;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 998;
  margin-top: 140px;
  background-color: #fff;
  margin-bottom: 1rem;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
}
.status-bar {
  position: relative;
  padding: 1.5rem 0;
  display: flex;

  &::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 17px;
    width: 100%;
    height: 2px;
    background-color: #57a0ff;
    z-index: 8;
  }
  .status-item {
    cursor: pointer;
    height: 30px;
    text-align: center;

    .status-item_text {
      color: #fff;
      width: 120px;
      height: 35px;
      line-height: 35px;
      margin-right: 7px;
      background-color: #57a0ff;
    }
  }
  .status-item-active {
    .status-item_text {
      position: relative;
      background-color: #fff;
      color: inherit;
      border: 2px solid #57a0ff;
      border-bottom: none;
      &::after {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #fff;
        z-index: 9;
      }
    }
  }
}
._box {
  margin-bottom: 1rem;
  background-color: #fff;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
}

.form-item {
  // width: 180px;
}
.p_main {
  padding-top: 100px;

  .filter {
    text-align: center;
    padding: 1rem;
    ._box;

    .form {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      align-items: center;
      .fomr-item {
        width: 100%;
      }
      // .form-input {}

      .form-btn {
        border-radius: 22px;
        width: 80px;
      }
      /deep/ .el-form-item {
        margin-bottom: 0;
      }
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    ._box;
  }

  // .trial-list {}

  .trial-statistics {
    float: left;
    display: flex;
    padding: 1rem;
    width: 30%;
    color: #57a0ff;
    justify-content: space-between;
    ._box;
  }
}

@media (max-width: 520px) {
  ::-webkit-scrollbar {
    display: none;
  }
  .status-fixed {
    margin-top: 60px;
    margin-bottom: 0;
    box-shadow: none;
    .status-bar {
      display: flex;
      white-space: nowrap;
      overflow: auto;

      &::after {
        content: none;
      }
      .status-item {
        .status-item_text {
          color: #57a0ff;
          margin: 0;
          background: none;
        }
      }
      .status-item-active {
        .status-item_text {
          background: none;
          border: none;

          &::after {
            left: 10%;
            width: 80%;
            background-color: #57a0ff;
          }
        }
      }
    }
  }
  ._box_r {
    box-shadow: none;
  }
  .p_main {
    padding-top: 68px;

    .filter {
      text-align: center;
      padding: 1rem;
      ._box_r;

      .form {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 0.5rem;
      }
    }

    .info {
      display: none;
    }

    .trial-list {
      overflow: hidden;
    }

    .trial-statistics {
      width: auto;
    }
  }
}
</style>
