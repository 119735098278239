<template>
  <div class="p_main">
    <div class="tab">
      <div
        :class="currentTabIndex == index ? 'tab-item tab-item-active' : 'tab-item'"
        v-for="(tab, index) in tabData"
        :key="index"
        @click="tabClick(index)"
      >
        {{ index + 1 }}.{{ tab.name }}
      </div>
      <div @click="userLatestConfig" style="margin-left: auto; padding-right: 40px; text-decoration: underline">
        上次发布选项
      </div>
    </div>

    <div class="tab-content" v-if="!loadTemplate">
      <trialCreateProcess1
        :forms="tabData"
        ref="trialCreateProcess1"
        v-show="currentTabIndex == 0"
        @refreshPrice="calcPrice"
      />
      <trialCreateProcess2
        :forms="tabData"
        ref="trialCreateProcess2"
        v-show="currentTabIndex == 1"
        @refreshPrice="calcPrice"
      />
      <trialCreateProcess3
        :forms="tabData"
        ref="trialCreateProcess3"
        v-show="currentTabIndex == 2"
        @refreshPrice="calcPrice"
      />
      <trialCreateProcess4
        :forms="tabData"
        ref="trialCreateProcess4"
        v-show="currentTabIndex == 3"
        @refreshPrice="calcPrice"
      />
    </div>

    <div class="tab-footer">
      <div class="footer-text">
        <div>
          平台服务费：
          <span class="text-red-500">{{ price.publishServiceFee | priceFormat }}</span>
          元
        </div>
        <div>
          单个佣金：
          <span class="text-red-500">{{ price.buyerCommissionFee | priceFormat }}</span>
          元
        </div>
        <div>
          单个任务总价：
          <span class="text-red-500">{{ price.totalAmount | priceFormat }}</span>
          元
        </div>
        <div>
          任务数量：
          <span class="text-red-500">{{ price.taskNum ? price.taskNum.toFixed(0) : 0 }}</span>
        </div>
        <div>
          本次发布总费用：
          <span class="text-red-500">{{ price.multiTaskTotalAmount | priceFormat }}</span>
          元
        </div>
        <div>
          我的余额：<span class="text-red-500">{{ userInfo.cash | priceFormat }}</span
          >元 [<router-link to="/user/recharge">充值</router-link>]
        </div>
      </div>
      <div class="footer-action">
        <div>
          <el-button v-if="currentTabIndex > 0" @click="currentTabIndex -= 1" type="primary" size="small">
            上一步：{{ tabData[currentTabIndex - 1].name }}
          </el-button>
        </div>
        <div>
          <el-button v-if="currentTabIndex < tabData.length - 1" @click="nextStep" type="primary" size="small">
            下一步：{{ tabData[currentTabIndex + 1].name }}
          </el-button>
          <el-button v-else type="primary" size="small" @click="publish" :loading="publishing">发布试用</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import trialCreateProcess1 from "@/components/seller/trialCreate/Process1.vue";
import trialCreateProcess2 from "@/components/seller/trialCreate/Process2.vue";
import trialCreateProcess3 from "@/components/seller/trialCreate/Process3.vue";
import trialCreateProcess4 from "@/components/seller/trialCreate/Process4.vue";
import { calcPrice, publish, taskDetail, getLatestId } from "@/api/task";
// import { getPriceConfig } from "@/api/config";
import moment from "moment";
export default {
  components: {
    trialCreateProcess1,
    trialCreateProcess2,
    trialCreateProcess3,
    trialCreateProcess4,
  },
  data() {
    return {
      currentTabIndex: 0,
      tabData: [
        { name: "商品信息", formData: {} },
        { name: "试用要求", formData: {} },
        { name: "卖家要求", formData: {} },
        { name: "发布统计", formData: {} },
      ],
      price: {
        publishServiceFee: 0,
        baseFee: 0,
        extraFee: 0,
        rushFee: 0,
        goodsFee: 0,
        buyerCommissionFee: 0,
        totalAmount: 0,
        multiTaskTotalAmount: 0,
        taskNum: 0,
      },
      loadTemplate: false,
      publishing: false,
      latestTaskId: "",
    };
  },
  created() {
    // const priceConfig = this.$store.state.config.priceConfig;
    // if (Object.keys(priceConfig).length == 0) {
    //   this.updatePriceConfig();
    // }
  },
  computed: {
    userInfo() {
      return this.$store.state.user;
    },
  },
  mounted() {
    const templateId = this.$route.query.templateId;
    if (templateId) {
      this.getTemplate(templateId);
    } else {
      const ref1 = this.$refs.trialCreateProcess1;
      const ref2 = this.$refs.trialCreateProcess2;
      const ref3 = this.$refs.trialCreateProcess3;
      const ref4 = this.$refs.trialCreateProcess4;
      this.tabData[0].formData = { ...ref1.formData, goodsImage: [] };
      this.tabData[1].formData = ref2.formData;
      this.tabData[2].formData = ref3.formData;
      this.tabData[3].formData = {
        ...ref4.formData,
        publishTime: moment(ref4.formData.publishTime).format("YYYY-MM-DD HH:mm:ss"),
      };
    }
    this.getLatestId();
  },
  methods: {
    tabClick(index) {
      this.currentTabIndex = index;
    },
    async getLatestId() {
      const { data } = await getLatestId(); // 即使没有发布过任务，也会返回成功，但data为空
      this.latestTaskId = data;
      console.log("--- latestTaskId " + this.latestTaskId);
    },
    getData() {
      const data = {
        ...this.tabData[0].formData,
        ...this.tabData[1].formData,
        ...this.tabData[2].formData,
        ...this.tabData[3].formData,
      };
      return data;
    },
    async userLatestConfig() {
      if (this.latestTaskId == undefined || this.latestTaskId == "") {
        this.$message.error("未查到最新任务配置");
        return;
      }
      this.loadTemplate = true;
      const { data } = await taskDetail(this.latestTaskId);
      this.loadTemplate = false;
      if (data == null) return;
      this.tabData[0].formData = { ...data, goodsImage: data.imgs.split(",") };
      this.tabData[1].formData = data;
      this.tabData[2].formData = data;
      this.tabData[3].formData = { ...data, publishTime: moment().format("YYYY-MM-DD HH:mm:ss") };
      this.calcPrice();
      // 这段代码一定要执行，否则在使用模板发布时，如果修改商品价格，再去第2个tab改任何一个配置，接口会传模板中的旧商品价格，而非最新修改的商品价格
      this.syncFormData();
    },
    async getTemplate(templateId) {
      this.loadTemplate = true;
      const { data } = await taskDetail(templateId);
      this.loadTemplate = false;
      if (data == null) return;
      this.tabData[0].formData = { ...data, goodsImage: data.imgs.split(",") };
      this.tabData[1].formData = data;
      this.tabData[2].formData = data;
      this.tabData[3].formData = { ...data, publishTime: moment().format("YYYY-MM-DD HH:mm:ss") };
      this.calcPrice();
      // 这段代码一定要执行，否则在使用模板发布时，如果修改商品价格，再去第2个tab改任何一个配置，接口会传模板中的旧商品价格，而非最新修改的商品价格
      this.syncFormData();
    },
    async syncFormData() {
      await this.$nextTick();
      const ref1 = this.$refs.trialCreateProcess1;
      const ref2 = this.$refs.trialCreateProcess2;
      const ref3 = this.$refs.trialCreateProcess3;
      const ref4 = this.$refs.trialCreateProcess4;
      this.tabData[0].formData = ref1.formData;
      this.tabData[1].formData = ref2.formData;
      this.tabData[2].formData = ref3.formData;
      this.tabData[3].formData = ref4.formData;
    },
    async calcPrice($e = {}) {
      const { data } = await calcPrice({ ...this.getData(), ...$e });
      if (data == null) return;
      this.price = data;

      // const priceConfig = this.$store.state.config.priceConfig;
      // this.price.publishServiceFee = Number(priceConfig.PUBLISH_SERVICE_FEE);
    },
    async nextStep() {
      let childrenRefName = "trialCreateProcess" + (this.currentTabIndex + 1);

      // 调子组件方法
      let result = await this.$refs[childrenRefName].submitForm();

      if (result == null || result == false) {
        this.$message.error("请完善表单");
        return false;
      }

      // console.log('表单内容：', result);
      this.tabData[this.currentTabIndex].formData = result;

      this.currentTabIndex += 1;

      // 回到顶部
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    async publish() {
      for (let i = 1; i <= 4; i++) {
        let childrenRefName = "trialCreateProcess" + i;
        let result = await this.$refs[childrenRefName].submitForm();

        if (result == null || result == false) {
          this.$message.error("请完善表单");
          return false;
        }

        this.tabData[i - 1].formData = result;
      }

      const notFinish = this.tabData.find((item) => Object.keys(item.formData).length == 0);
      if (notFinish) {
        this.$message.error(`您${notFinish.name}阶段表单未填写完成，请检查`);
        return false;
      }

      this.publishing = true;
      try {
        const { data } = await publish({ ...this.getData(), ...this.price });
        // if (data == null) {
        //   this.$message.error("发布失败, 内部错误");
        //   return false;
        // }
        this.latestTaskId = data;
        console.log("=== latestTaskId " + this.latestTaskId);
      } finally {
        this.publishing = false;
      }

      this.$message.success("发布成功");
      this.$router.push("/seller/trial");
    },
    // async updatePriceConfig() {
    //   const { data } = await getPriceConfig();
    //   this.$store.commit("config/SET_PRICE_CONFIG", data);
    // },
  },
};
</script>

<style lang="less" scoped>
.p_main {
  .tab {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #f3f6f9;
    .tab-item {
      padding: 1.5rem;
    }
    .tab-item-active {
      background-color: #fff;
      border: 1px solid #6fb5ff;
      color: #6fb5ff;
    }
  }

  .tab-content {
    padding: 2rem;
    margin-bottom: 1rem;
    background-color: #fff;
  }

  .tab-footer {
    padding: 1rem 2rem;
    background-color: #fff;

    .footer-text {
      margin-bottom: 1rem;
    }

    .footer-text,
    .footer-action {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media (max-width: 520px) {
  .p_main {
    .tab {
      overflow-x: auto;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .tab-item {
        padding: 1rem;
        text-align: center;
        width: 80px;
      }
    }
    ::-webkit-scrollbar {
      display: none;
    }

    .tab-footer {
      padding: 1rem;

      .footer-text {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5px;
      }
    }
  }
}
</style>
