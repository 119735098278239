import request from "@/plugin/request";

export const get = (queryParams = {}, page = {
    size: 100,
    current: 1
}) => request.post('/blackList/getList', {
    page, queryParams
})

export const del = (id) => request.get('/blackList/delete?id=' + id, { id })

export const add = (buyAccountAccount, reason) => request.post('/blackList/add', { buyAccountAccount, reason })

export default {
    get, del, add
}