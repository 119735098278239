<template>
  <div>
    <div class="filter">
      <TrialCategory :list="categories" :onchange="categoryOnChange" />
      <TrialWhere style="background-color: #f3f3f3" v-model="selectWhere" />
    </div>

    <div class="list" v-loading="loading">
      <el-row type="flex" :gutter="20" style="flex-wrap: wrap; flex-direction: row">
        <el-col v-for="(goods, index) in serverData.goodsList" :key="index" :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <TrialItem :data="goods" @refresh="getData" />
        </el-col>
      </el-row>
      <div v-if="serverData.goodsList.length == 0" class="text-center bg-white p-4 text-lg">
        <img src="@/assets/image/logo.png" class="mx-auto mb-4" />
        <div>暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import TrialCategory from "@/components/trial/TrialCategory.vue";
import TrialWhere from "@/components/trial/TrialWhere.vue";
import TrialItem from "@/components/trial/TrialItem.vue";
import { goodsCateList, onSellList } from "@/api/goods";
import { loginUserInfo } from "@/api/user";
const originPage = {
  current: 1,
  size: 8,
  total: 0,
  pages: 0,
};
export default {
  components: {
    TrialItem,
    TrialCategory,
    TrialWhere,
  },
  data() {
    return {
      serverData: {
        goodsList: [],
      },
      categories: [],
      selectCategory: {},
      selectWhere: {
        selectIndex: 0,
        platform: "TAO_BAO",
        autoUpdate: false,
        id: "",
      },
      page: originPage,
      autoUpdateInterval: null,
      loading: false,
    };
  },
  watch: {
    selectCategory: {
      handler: function (val, oldVal) {
        this.page = originPage;
        this.serverData.goodsList = [];
        this.getData();
      },
      deep: true,
    },
    selectWhere: {
      handler: function (val, oldVal) {
        this.page = originPage;
        this.serverData.goodsList = [];
        if (val.autoUpdate) {
          this.enableAutoUpdate();
        } else {
          this.disableAutoUpdate();
        }
        this.getData();
      },
      deep: true,
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  created() {
    if (!this.user.isLogin) {
      this.$router.push("/login");
    }
    var pid = this.$route.query.pid
    if (pid != undefined && pid != '') {
      console.log('trial page pid ' + pid)
      localStorage.setItem('pid', pid)
    }
  },
  methods: {
    async getCategory() {
      const {
        data: { list: categories },
      } = await goodsCateList();
      this.categories = categories;
    },
    async getData() {
      const sortModeMap = {
        1: "create_time desc",
        2: "buyer_commission_fee desc",
        3: "rush_fee desc",
      };
      const sortMode = sortModeMap[this.selectWhere.selectIndex];
      this.loading = true;
      try {
        const {
          data: { list: goods, page: pages },
        } = await onSellList(this.page, {
          status: "WAITING_APPLY",
          platformType: this.selectWhere.platform,
          isOnSell: 1,
          cate1Id: this.selectCategory.id == 0 ? undefined : this.selectCategory.id,
          sortMode,
          id: this.selectWhere.id == "" ? undefined : this.selectWhere.id || undefined,
        });
        if (this.page.current == 1) {
          this.serverData.goodsList = goods;
        } else {
          this.serverData.goodsList = this.serverData.goodsList.concat(goods);
        }
        this.page = pages;
      } finally {
        this.loading = false;
      }
    },
    categoryOnChange(e) {
      this.selectCategory = e;
    },
    enableAutoUpdate() {
      this.autoUpdateInterval = setInterval(
        () => {
          this.page = originPage;
          this.getData();
        },
        5000,
        5000
      );
    },
    disableAutoUpdate() {
      clearInterval(this.autoUpdateInterval);
      this.autoUpdateInterval = null;
    },
    handleScroll() {
      // 滚动条距离顶部的距离
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // 滚动条的高度
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      // 浏览器窗口的高度
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      // 滚动条距离底部的距离
      const toBottom = scrollHeight - scrollTop - clientHeight;
      if (toBottom <= 10 && this.autoUpdateInterval === null) {
        if (this.page.pages > this.page.current && !this.loading) {
          this.page.current++;
          this.getData();
        }
      }
    },
  },
  async mounted() {
    // 试客首页
    const urlParams = new URLSearchParams(window.location.search);
    var token = urlParams.get('token')
    var userRole = urlParams.get('userRole')
    console.log('token ' + token)
    if (token != undefined && token != '') {
      this.user.token = token
      const { data } = await loginUserInfo();
      this.$store.commit("user/LOGIN", data)
    }

    this.getCategory();
    this.getData();
    // 注册滚动事件
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    // 销毁滚动事件
    window.removeEventListener("scroll", this.handleScroll);
    this.disableAutoUpdate();
  },
};
</script>

<style lang="less" scoped>
.filter {
  margin-bottom: 1rem;
  width: 100%;
  background-color: #fff;
}
// .list {}
</style>
