import axios from "axios";
import router from "../router"; // 导入路由实例
import store from "../store"; // 导入 Vuex 实例
import { Message } from 'element-ui';

// 服务器地址
export const baseURL = "https://www.aiwkk.com/api"
// export const baseURL = "http://47.107.42.182:10830"
// export const baseURL = "http://localhost:10830"
const instance = axios.create({
	baseURL, // 设置 API 根路径
	timeout: 15000, // 设置请求超时时间
	withCredentials: true
});

// 添加请求拦截器
instance.interceptors.request.use(
	(config) => {
		// 在请求头中添加 Authorization 字段，值为 Vuex 中的 token
		config.headers.token = `${store.state.user.token}`;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// 添加响应拦截器
instance.interceptors.response.use(
	(response) => {
		// 如果返回状态码为 401，跳转到登录页面
		if (response.status === 401) {
			router.push("/login");
		}
		const returnCode = response.data.returnCode
		if (returnCode == -99) {
			router.push('/login')
			return
		} else if (returnCode == -98) {// 实名认证
			Message.error(response.data.returnMsg)
			router.push('/user/certify')
			return Promise.reject(response.data.returnMsg)
		} else if (returnCode == -97) {// 设置收款码
			Message.error(response.data.returnMsg)
			router.push('/user/profile')
			return Promise.reject(response.data.returnMsg)
		} else if (returnCode == 1) {
			return response.data
		} else {
			Message.error(response.data.returnMsg)
			return Promise.reject(response.data.returnMsg)
		}
	},
	(error) => {
		console.log('error ' + JSON.stringify(error))
		Message.error(error.message)
		return Promise.reject(error);
	}
);

export default instance;
