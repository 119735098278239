<template>
  <div>
    <div class="item relative">
      <div
        v-if="data.isRefunded"
        class="absolute rounded-lg px-3 py-0.5 top-[45px] right-[15%] tracking-4px text-red-500 font-bold transform rotate-10 ring-red-500 ring-4"
      >
        已返款
      </div>
      <div class="meta_bar">
        <div class="flex-row">
          <el-checkbox v-model="checked" @change="$emit('switchChecked')"></el-checkbox>
          <div class="meta_item text-blue-400 ml-4">
            试用编号：
            <span class="text-red-400">{{ data.id }}</span>
            【{{ data.platformType | filterPlatform }}】
          </div>
        </div>
        <div class="flex right-bar">
          <div class="meta_item mr-4" v-if="data.buyerComment && data.buyerComment != ''">
            评价：{{ data.buyerComment | filterCommentLevel }}
          </div>
          <div class="meta_item mr-4" v-if="data.isRefunded == 1">返款方式：{{ data.refundReceivePayMethod | filterPayMethod }}</div>
          <div class="meta_item mr-4" v-if="data.isRefunded == 1">返款时间：{{ data.refundTime }}</div>
          <div class="meta_item" style='margin-right: 20px;'>发布时间：{{ data.publishTime }}</div>
          <div class="meta_item">操作时间：{{ data.updateTime }}</div>
        </div>
      </div>
      <div class="content">
        <div class="content_main">
          <div class="content_info">
            <div style="display: flex; flex-direction: column; justify-content: flex-start; ">
              <img :src="data.imgs.split(',')[0]" alt="" class="thumb self-start" />
              <span class="thumb self-start">{{ data.searchKey }}</span>
            </div>
            <div class="data">
              <div class="data_header">
                <div class="data_item">
                  店铺：<span>{{ data.shop?.name }}</span>
                </div>
                <div class="data_item">
                  宝贝单价：<span>{{ data.goodsPrice | priceFormat}}</span>
                  元
                </div>
                <div class="data_item">
                  下单总价：<span>{{ data.actualGoodsFee ? data.actualGoodsFee : data.goodsFee | priceFormat }}</span
                  >元
                </div>
                <div class="data_item">
                  奖励：<span>{{ data.buyerCommissionFee | priceFormat}}</span>
                  元
                </div>
                <div class="data_item">
                  订单号：<span>{{ data.ordersId }}</span>
                </div>
                <div class="data_item">
                  买号：<span>{{ data.buyAccount?.account }}</span>
                </div>
              </div>
              <div class="data_tag">
                <tag v-for="(item, index) in tags" :key="index">
                  {{ item }}
                </tag>
              </div>
            </div>
          </div>
          <div class="content_status">
            <div class="mob_status_label">试用状态：</div>
            <div>
              <div class="content_status_item !py-1 text-12px" @click="clickStatus">
                {{ status }}
              </div>
            </div>
          </div>
          <div class="content_action">
            <div class="content_action_btns">
              <el-button
                @click="addRecycleTime(data.id)"
                class="form-btn !ml-0"
                type="primary"
                size="small"
                v-if="data.status == 'WAITING_APPLY'"
              >
                增加时间
              </el-button>
              <el-button
                @click="addRushFeeClick(data.id)"
                class="form-btn !ml-0"
                type="primary"
                size="small"
                v-if="data.status == 'WAITING_APPLY'"
              >
                追加奖励
              </el-button>
              <el-button
                @click="applyAudit(data.id, true)"
                class="form-btn !ml-0"
                type="success"
                size="small"
                v-if="data.status == 'WAITING_AUDIT'"
              >
                审核通过
              </el-button>
              <el-button
                @click="applyAudit(data.id, false)"
                class="form-btn !ml-0"
                type="danger"
                size="small"
                v-if="data.status == 'WAITING_AUDIT'"
              >
                审核拒绝
              </el-button>
              <el-button
                @click="recycleClick(data.id)"
                class="form-btn !ml-0"
                type="danger" plain
                size="small"
                v-if="data.status == 'WAITING_APPLY' || data.status == 'WAITING_AUDIT' 
                || data.status == 'TOOK'"
              >
                回收试用
              </el-button>

              <el-button
                @click="confirmActualPrice"
                class="form-btn !ml-0"
                type="warning"
                size="small"
                v-if="data.status == 'ORDERED' && data.isActualGoodsFeeConfirmed == 0"
              >
                确认付款金额
              </el-button>
              <!-- <el-button
                class="form-btn !ml-0"
                type="danger"
                size="small"
                v-if="data.status == 'ORDERED' && data.isActualGoodsFeeConfirmed == 1"
              >
                已确认付款金额
              </el-button> -->
              <el-button
                @click="setRefunded"
                class="form-btn !ml-0"
                type="danger"
                size="small"
                v-if="data.status == 'ORDERED' && data.isActualGoodsFeeConfirmed == 1 && data.isRefunded == 0"
              >
                确认已返款
              </el-button>
              <!-- <el-button
                class="form-btn !ml-0"
                type="danger"
                size="small"
                v-if="data.status == 'ORDERED' && data.isRefunded == 1"
              >
                当前已返款
              </el-button> -->
              <el-button
                @click="noticeRemindReceive"
                class="form-btn !ml-0"
                type="success"
                size="small"
                v-if="data.status == 'ORDERED'"
              >
                提醒收货
              </el-button>
              <el-button
                @click="expressAgent"
                class="form-btn !ml-0"
                type="primary"
                size="small"
                v-if="data.status == 'ORDERED'"
              >
                代发网推荐
              </el-button>

              <el-button
                @click="setFinished"
                class="form-btn !ml-0"
                type="danger"
                size="small"
                v-if="data.status == 'GOODS_RECEIVED'"
              >
                确认完成
              </el-button>
              <el-popover
                placement="bottom"
                width="200"
                trigger="click"
                v-if="data.status == 'FINISHED' && (!data.sellerComment || data.sellerComment == '')"
              >
                <el-button class="form-btn" type="primary" size="small" slot="reference"> 评价 </el-button>
                <div class="flex flex-col gap-y-1">
                  <el-button class="form-btn" type="success" size="small" slot="reference" @click="remark('GOOD')"
                    >好评</el-button
                  >
                  <el-button
                    class="form-btn !ml-0"
                    type="primary"
                    size="small"
                    slot="reference"
                    @click="remark('NORMAL')"
                    >中评</el-button
                  >
                  <el-button class="form-btn !ml-0" type="danger" size="small" slot="reference" @click="remark('BAD')"
                    >差评</el-button
                  >
                </div>
              </el-popover>

              <el-button
                class="form-btn !ml-0"
                type="info"
                size="small"
                @click="complainShow = true"
                v-if="data.status == 'ORDERED' || data.status == 'TOOK' || data.status == 'GOODS_RECEIVED'"
                >投诉对方
              </el-button>
              <el-button
                class="form-btn !ml-0"
                type="danger"
                size="small"
                @click="cancelComplain"
                v-if="data.status == 'COMPLAIN'"
              >
                关闭投诉
              </el-button>
              <el-button
                class="form-btn !ml-0"
                type="info"
                size="small"
                plain
                @click="showComplainDetail"
                v-if="data.complainList != undefined && data.complainList.length > 0"
              >
                查看投诉
              </el-button>
              <el-button
                class="form-btn !ml-0"
                type="info"
                size="small"
                plain
                @click="replyComplainShow = true"
                v-if="data.complainList != undefined && data.complainList.length > 0"
              >
                回复投诉
              </el-button>
              <el-button
                class="form-btn !ml-0"
                type="warning"
                size="small"
                plain
                @click="complainToPlatform"
                v-if="data.status == 'COMPLAIN' && !data.complainNeedPlatformHandle"
              >
                客服介入
              </el-button>
              <el-button class="form-btn !ml-0" type="danger" size="small" v-else-if="data.status == 'COMPLAIN'">
                客服已介入
              </el-button>

              <el-button
                @click="showSnapshots = true"
                class="form-btn !ml-0"
                type="primary"
                size="small"
                v-if="data.status == 'ORDERED' || data.status == 'GOODS_RECEIVED' || data.status == 'FINISHED'"
              >
                试用截图
              </el-button>
              <el-button
                @click="processShow = true"
                class="form-btn !ml-0"
                type="info"
                plain
                size="small"
                v-if="data.status == 'TOOK' || data.status == 'ORDERED' || data.status == 'GOODS_RECEIVED'
                  || data.status == 'FINISHED' || data.status == 'COMPLAIN'"
              >
                回看
              </el-button>
            </div>
          </div>
        </div>
        <div class="content_footer">
          <div>
            试客：{{ data.buyer?.loginName }}
            <el-button size="small" class="ml-4" type="primary" round @click="showUserInfo(1)">查看</el-button>
          </div>
          <div class="flex-row">
            <div class="flex flex-row items-center" v-if="data.buyer" style="margin-right: 10px;">
              <el-button size="small" type="primary" round plain
                :disabled="data.refundReceivePayMethod != undefined && data.refundReceivePayMethod != ''"
                @click="setAlipayRefunded"
                >设置支付宝返款
              </el-button>
              <el-popover placement="top-start" width="250" trigger="hover">
                <el-image slot="reference" class="qr" :src="data.buyer?.alipayReceiveQrcode" alt="" />
                <el-image class="qr" :src="data.buyer?.alipayReceiveQrcode" alt="" />
              </el-popover>
            </div>
            
            <div class="flex flex-row items-center" v-if="data.buyer">
              <el-button size="small" type="primary" round plain
                :disabled="data.refundReceivePayMethod != undefined && data.refundReceivePayMethod != ''"
                @click="setWxRefunded"
                >设置微信返款
              </el-button>
              <el-popover placement="top-start" width="200" trigger="hover">
                <el-image slot="reference" class="qr" :src="data.buyer?.wxReceiveQrcode" alt="" />
                <el-image class="qr" :src="data.buyer?.wxReceiveQrcode" alt="" />
              </el-popover>
            </div>
          </div>
          <div>
            <el-form :inline="true" :model="formData" class="form">
              <el-form-item label="备注" class="form-item">
                <el-input class="form-input" v-model="formData.remarks" size="small"> </el-input>
              </el-form-item>
              <el-form-item class="form-item">
                <el-button @click="remarksSubmit" type="primary" size="small">保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="试客详情" :visible.sync="showUserInfoDialog" class="dialog" center :fullscreen="isMobile">
      <div>
        <div>收款人姓名：{{ data.buyer?.realName }}</div>
        <br />
        <div>支付宝账户：{{ data.buyer?.alipayAccount }}</div>
      </div>
    </el-dialog>

    <el-dialog
      title="投诉对方"
      :visible.sync="complainShow"
      class="dialog"
      width="40%"
      center
      :fullscreen="processDialogFull"
    >
      <div>
        <el-form ref="complainFormData" :model="complainFormData" :rules="complainFormRules" label-position="top">
          <el-form-item label="投诉内容：" prop="content">
            <el-input type="textarea" v-model="complainFormData.content" :rows="4"></el-input>
          </el-form-item>

          <el-form-item label="投诉图片：" prop="image">
            <uploader-group v-model="complainFormData.image" :type="'task'"></uploader-group>
          </el-form-item>

          <el-form-item class="w-120px">
            <el-button type="primary" @click="complainSubmit('complainFormData')" size="small" style="width: 100%"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog
      title="回复投诉"
      :visible.sync="replyComplainShow"
      class="dialog"
      width="40%"
      center
      :fullscreen="processDialogFull"
    >
      <div>
        <el-form ref="complainFormData" :model="complainFormData" :rules="complainFormRules" label-position="top">
          <el-form-item label="回复内容：" prop="content">
            <el-input type="textarea" v-model="complainFormData.content" :rows="4"></el-input>
          </el-form-item>

          <el-form-item label="回复图片：" prop="image">
            <uploader-group v-model="complainFormData.image" :type="'task'"></uploader-group>
          </el-form-item>

          <el-form-item class="w-120px">
            <el-button type="primary" @click="replyComplainSubmit('complainFormData')" size="small" style="width: 100%">
              回复
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="complainDetailShow" class="dialog" width="40%" center :fullscreen="processDialogFull">
      <div>
        <div class="complain-detail" v-for="(item, index) in data.complainList" :key="item.id">
          <div class="complain-detail-item" style="font-weight: bold;">
            <div class="complain-detail-item-label">用户：</div>
            <div class="complain-detail-item-value" v-if="item.userRole == 'BUYER'">试客</div>
            <div class="complain-detail-item-value" v-if="item.userRole == 'SELLER'">商家</div>
          </div>
          <div class="complain-detail-item">
            <div class="complain-detail-item-label">内容：</div>
            <div class="complain-detail-item-value">
              {{ item.content }}
            </div>
          </div>
          <div class="complain-detail-item">
            <div class="complain-detail-item-label">图片：</div>
            <div class="complain-detail-item-value" v-if='item.imgs'>
              <el-image
                style="width: 100px; height: 100px; margin-right: 5px"
                :src="imgSrc"
                fit="cover"
                :key="imgSrc"
                v-for="imgSrc in item.imgs.split(',')"
              ></el-image>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <TrialSnapshot
      v-if="data.status == 'ORDERED' || data.status == 'GOODS_RECEIVED' || data.status == 'FINISHED'"
      v-model="showSnapshots"
      :id="data.id"
    />

    
    <el-dialog
      title="试用流程"
      :visible.sync="processShow"
      class="dialog"
      center
      width="50%"
      :fullscreen="processDialogFull"
    >
      <div>
        <TrialProcess
          v-if="processShow"
          :detail="data"
          backWatch
          @refresh="$emit('refresh')"
          @close="processShow = false"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tag from "@/components/tag/Tag.vue";
import filter from "@/plugin/filter";
import uploaderGroup from "@/components/uploader-group.vue";
import TrialSnapshot from "./TrialSnapshot.vue";
import {
  saveRemark,
  complain,
  replyComplain,
  cancelComplain,
  closeComplain,
  complainToPlatform,
  addRecycleTime,
  addRushFee,
  recycle,
  applyAudit,
  confirmActualPrice,
  setRefunded,
  setRefundAccountType,
  setFinished,
  comment,
} from "@/api/task";
import { noticeRemindReceive } from "@/api/notice";
import TrialProcess from "@/components/trial/TrialProcess.vue";

export default {
  props: {
    data: {
      type: Object,
    },
    selected: Boolean
  },
  components: {
    tag,
    uploaderGroup,
    TrialSnapshot,
    TrialProcess
  },
  data() {
    return {
      isMobile: false,
      formData: {
        remarks: "",
      },
      showUserInfoDialog: false,
      complainShow: false, // 投诉弹窗
      replyComplainShow: false,
      complainDetailShow: false,
      complainFormData: {
        content: "",
        image: [],
      },
      complainFormRules: {
        content: [
          {
            required: true,
            message: "请输入投诉内容",
            trigger: "blur",
          },
        ],
        // image: [
        //   {
        //     required: true,
        //     message: "请上传至少一张图片",
        //     trigger: "change",
        //   },
        // ],
      },
      checked: false,
      showSnapshots: false,
      processDialogFull: false,
      processShow: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.formData.remarks = this.data.sellerRemark;
    });
    if (window.screen.width < 768) {
      this.processDialogFull = true;
    }
    this.checked = this.selected;
  },
  watch: {
    selected(val) {
      this.checked = val;
    },
  },
  computed: {
    role() {
      return this.$store.state.user.role;
    },
    tags() {
      return filter.filterTags(this.data);
    },
    status() {
      const statusMap = {
        WAITING_APPLY: "待申请",
        WAITING_AUDIT: "待审核",
        TOOK: "已接单",
        ORDERED: "已下单",
        GOODS_RECEIVED: "已确认收货",
        FINISHED: "已完成",
        COMPLAIN: "投诉中",
      };
      // 返回当前状态
      return statusMap[this.data.status];
    },
  },
  methods: {
    // 备注按钮
    async remarksSubmit() {
      if (this.formData.remarks == "") {
        this.$message.error("请输入备注内容");
        return false;
      }

      const params = { id: this.data.id, sellerRemark: this.formData.remarks };
      await saveRemark(params);
      this.$message.success("备注成功");
    },
    // 查看试客信息
    showUserInfo(id) {
      this.showUserInfoDialog = true;
      console.log(id);
    },
    // 投诉表单提交
    complainSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await complain({
            taskId: this.data.id,
            userRole: "SELLER",
            content: this.complainFormData.content,
            imgs: this.complainFormData.image.join(","),
          });
          this.$message.success("投诉成功");
          this.$emit("refresh");
          this.complainShow = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 投诉回复表单提交
    replyComplainSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await replyComplain({
            taskId: this.data.id,
            userRole: this.role,
            content: this.complainFormData.content,
            imgs: this.complainFormData.image.join(","),
          });
          this.$message.success("回复成功");
          this.$emit("refresh");
          this.replyComplainShow = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancelComplain() {
      this.$confirm("确定关闭投诉吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await closeComplain(this.data.id);
        this.$message.success("关闭成功");
        this.$emit("refresh");
      });
    },
    complainToPlatform() {
      this.$confirm("确定投诉到平台吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await complainToPlatform(this.data.id);
        this.$message.success("投诉成功");
        this.$emit("refresh");
      });
    },
    addRecycleTime(ids) {
      this.$prompt("请输入延长时间（小时）", "提示", {
        showCancelButton: true,
        inputType: "number",
        inputPlaceholder: "请输入延长时间",
        inputValidator: (value) => {
          if (!value) {
            return "请输入延长时间";
          }
        },
      }).then(async ({ value }) => {
        await addRecycleTime(ids, value);
        this.$message.success("延长成功");
        this.$emit("refresh");
      });
    },
    addRushFeeClick(ids) {
      this.$prompt("请输入追加费用", "提示", {
        showCancelButton: true,
        inputType: "number",
        inputPlaceholder: "请输入追加费用",
        inputValidator: (value) => {
          if (!value) {
            return "请输入追加费用";
          }
        },
      }).then(async ({ value }) => {
        await addRushFee(ids, value);
        this.$message.success("追加成功");
        this.$emit("refresh");
      });
    },
    recycleClick(ids) {
      this.$confirm("确定回收吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await recycle(ids);
        this.$message.success("回收成功");
        this.$emit("refresh");
      });
    },
    applyAudit(id, agree) {
      this.$confirm(`确定${agree ? "同意" : "拒绝"}审核吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await applyAudit(id, agree ? "SUCCESS" : "FAIL");
        this.$message.success((agree ? "同意" : "拒绝") + "审核成功");
        this.$emit("refresh");
      });
    },
    expressAgent() {
      const url = this.$store.state.config.config.EXPRESS_AGENT_URL;
      window.open(url, "_blank");
    },
    setWxRefunded() {
      this.$confirm("确定设置使用微信返款吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await setRefundAccountType(this.data.id, "WX");
        this.$message.success("设置成功");
        this.$emit("refresh");
      });
    },
    setAlipayRefunded() {
      this.$confirm("确定设置使用支付宝返款吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await setRefundAccountType(this.data.id, "ALIPAY");
        this.$message.success("设置成功");
        this.$emit("refresh");
      });
    },
    setRefunded() {
      this.$confirm("确定当前已返款吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await setRefunded(this.data.id);
        this.$message.success("成功");
        this.$emit("refresh");
      });
    },
    confirmActualPrice() {
      this.$confirm(`确定实际付款金额为${this.data.actualGoodsFee}元？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass:'cfm-model',
        type: "warning",
      }).then(async () => {
        await confirmActualPrice(this.data.id);
        this.$message.success("确认成功");
        this.$emit("refresh");
      });
    },
    setFinished() {
      this.$confirm("确认对方已完成试用？ 点击确认，平台会放款给试客", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await setFinished(this.data.id);
        this.$message.success("确认成功");
        this.$emit("refresh");
      });
    },
    async noticeRemindReceive() {
      await noticeRemindReceive(this.data.id);
      this.$message.success("提醒成功");
    },
    remark(level) {
      const remarkLevel = level === "GOOD" ? "好评" : level === "NORMAL" ? "中评" : "差评";
      this.$confirm(`确定要给出${remarkLevel}吗？`, "提示", {
        showCancelButton: true,
      }).then(async ({ value }) => {
        await comment(this.data.id, "SELLER", level);
        this.$message.success("评价成功");
        this.$emit("refresh");
      });
    },
    clickStatus() {
    },
    showComplainDetail() {
      this.complainDetailShow = true;
    },
  },
};
</script>

<style>
  .cfm-model  {
    width: 360px !important;
  }
</style>

<style lang="less" scoped>
.item {
  margin-bottom: 1rem;
  background-color: #fff;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
  .meta_bar {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #dadada;
    padding: 1rem;
    margin-bottom: 0.5rem;
    // .meta_item {}
  }

  .right-bar {
    display: flex;
    flex-direction: row;
    margin-left: auto;
  }

  .content {
    padding: 0 1rem;

    .content_main {
      display: flex;
      border-bottom: 1px solid #dadada;
      align-items: stretch;

      .content_info {
        flex: 1;
        display: flex;
        align-items: center;
        border-right: 1px solid #dadada;
        padding: 1rem 0;

        .thumb {
          width: 140px;
        }
        .data {
          padding-left: 1rem;
          flex: 1;

          .data_header {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 5px;
            justify-content: space-between;
            margin-bottom: 1rem;
            // .data_item {}
            .mob_meta_item {
              display: none;
            }
          }
          .data_tag {
            padding: 0.5rem 0;
          }
        }
      }
      .content_status {
        width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-right: 1px solid #dadada;
        font-size: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .mob_status_label {
          display: none;
        }
        .content_status_item,
        .content_status_item_t {
          margin: 0 auto 5px;
          padding: 10px 0;
          border-radius: 22px;
          color: #e9b679;
          width: 140px;
        }
        .content_status_item_t {
          color: #fff;
          border: 1px solid #6c81ae;
          background-color: #666666;
        }
      }
      .content_action {
        text-align: center;
        width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .content_action_btns {
          line-height: 2.2rem;
        }
        .mob-content_action_btns {
          display: none;
        }
        .form-btn {
          padding: 8px 15px;
          width: 140px;
          border-radius: 12px;
        }
      }
    }

    .content_footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;
      .qr {
        width: 30px;
        height: 30px;
        margin: 0 5px;
        vertical-align: middle;
      }

      .form {
        /deep/ .el-form-item {
          margin: 0;
        }
        .form-input {
          width: 200px;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.complain-detail {
  .complain-detail-item {
    display: flex;
    margin-bottom: 1rem;
    .complain-detail-item-label {
      width: 100px;
      text-align: right;
    }
    .complain-detail-item-value {
      margin-left: 1rem;
      flex: 1;
    }
  }
}

@media (max-width: 520px) {
  .item {
    .meta_bar {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-bottom: 2px solid #dadada;
      padding: 10px 15px 5px;
      // margin-bottom: 0.5rem;
    }

    .right-bar {
      display: flex;
      flex-direction: column;
      margin-left: 0;
    }
    .content {
      padding: 0 1rem;

      .content_main {
        border-bottom: none;
        flex-direction: column;
        align-items: flex-start;

        .content_info {
          align-items: flex-start;
          border-right: none;

          .thumb {
            width: 60px;
          }
          .data {
            .data_header {
              grid-template-columns: repeat(2, 1fr);

              .mob_meta_item {
                display: block;
              }
            }
            .data_tag {
              padding: 0;
            }
          }
        }
        .content_status {
          width: 100%;
          text-align: left;
          border-right: none;
          align-items: center;
          justify-content: left;
          flex-flow: row nowrap;
          display: flex;
          .mob_status_label {
            display: block;
            margin-right: 0.5rem;
            font-size: 16px;
          }

          .content_status_item,
          .content_status_item_t {
            font-size: 16px;
            padding: 0;
            margin: 0;
            text-align: center;
            width: fit-content;
          }
        }
        .content_action {
          width: 100%;
          margin: 1rem 0;
          .content_action_btns {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1rem;
            justify-content: space-between;
            align-items: center;
            line-height: 0;
          }
          .pc-content_action_btns {
            display: none;
          }
          .mob-content_action_btns {
            display: block;
          }
          .form-btn {
            padding: 10px 15px;
            width: 100%;
            border-radius: 3px;
          }
        }
      }

      .content_footer {
        display: block;
        line-height: 2.5rem;
      }
    }
  }
}
</style>
