<template>
  <div class="trialLevel">
    <div class="flex flex-row flex-nowrap justify-center" v-if="platformType == 'JD'">
      <span v-if="creditLevel == 1">
        <img :src="铜牌" />
      </span>
      <span v-if="creditLevel == 2">
        <img :src="银牌" />
      </span>
      <span v-if="creditLevel == 3">
        <img :src="金牌" />
      </span>
      <span v-if="creditLevel == 4">
        <img :src="钻石" />
      </span>
    </div>
    <div class="flex flex-row flex-nowrap justify-center" v-if="platformType == 'TAO_BAO'">
      <div v-if="creditLevel >= 1 && creditLevel <= 5" class="flex flex-row">
        <img :src="爱心" v-for="i in creditLevel" :key="i" />
      </div>
      <div v-if="creditLevel >= 6 && creditLevel <= 10" class="flex flex-row">
        <img :src="钻石" v-for="i in (creditLevel - 5)" :key="i" />
      </div>
      <span v-if="creditLevel == 11">
        <img :src="皇冠" />
      </span>
    </div>
    <div class="flex flex-row flex-nowrap justify-center" v-if="platformType == 'DOU_YIN'">
      <div v-if="creditLevel >= 1 && creditLevel <= 5" class="flex flex-row">
        <img :src="爱心" v-for="i in creditLevel" :key="i" />
      </div>
      <span v-if="creditLevel == 6">
        <img :src="钻石" />
      </span>
    </div>
    <span v-if="creditLevel == 0 && platformType !== 'PDD'"> 无 </span>
  </div>
</template>

<script>
import 铜牌 from "@/assets/icon/jd1.png";
import 银牌 from "@/assets/icon/jd2.png";
import 金牌 from "@/assets/icon/jd3.png";
import 爱心 from "@/assets/icon/lv1.svg";
import 钻石 from "@/assets/icon/lv2.svg";
import 皇冠 from "@/assets/icon/lv3.svg";

export default {
  props: {
    platformType: String,
    creditLevel: Number,
  },
  data: () => ({
    铜牌,
    银牌,
    金牌,
    爱心,
    钻石,
    皇冠,
  }),
};
</script>

<style lang="less" scope>
.trialLevel {
  img {
    width: 20px;
    height: 20px;
  }
}
</style>
