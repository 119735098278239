<template>
  <div class="process-main">
    <div class="process-c-item">请将下面的视频保存到手机相册</div>
    <div class="process-c-item">在淘宝中作为评价视频上传！</div>
    <div class="process-c-item flex flex-col">
      <video :src="stepData.specifiedVideo" controls class="w-400px" />
      <el-button class="w-20 mt-2" type="success" size="small" round @click="downloadImg(stepData.specifiedVideo)">
        下载视频
      </el-button>
    </div>
    <div class="process-c-item">
      评价：
      <span class="mr-4">{{ stepData.commentPrompt }}</span>
      <el-button type="success" size="small" round @click="copy(stepData.commentPrompt)"> 复制评价 </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    stepData: {
      type: Object,
    },
  },
  mounted() {
    setTimeout(() => {
      this.$emit("update-done", true);
    }, 0);
  },
  data() {
    return {
      isDone: false,
      data: {
        content: "非常好，服务满意",
      },
    };
  },
  methods: {
    downloadImg(url) {
      let a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.download = url.split("/").pop() || "评价视频.mp4";
      a.click();
    },
    copy(str) {
      this.$copyText(str).then(
        () => {
          this.$message.success("已复制");
        },
        () => {
          this.$message.error("复制失败");
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.process-main {
  .process-c-item {
    margin-bottom: 1rem;
  }
}
</style>
