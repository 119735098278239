<template>
  <div>
    <div class="p_main">
      <div class="info">
        <div class="info-item">
          {{ systemConfig.TASK_FA_DAN_TI_SHI }}
        </div>
      </div>

      <div class="trial-list">
        <TemplateItem :data="item" v-for="item in data" :key="item.id" @refresh="getList" />
      </div>
    </div>
  </div>
</template>
<script>
import TemplateItem from "@/components/seller/TemplateItem.vue";
import { getSellerTemplateList } from "@/api/task";
export default {
  components: {
    TemplateItem,
  },
  data() {
    return {
      data: [],
    };
  },
  computed: {
    systemConfig() {
      return this.$store.state.config.config;
    },
  },
  methods: {
    async getList() {
      const {
        data: { list },
      } = await getSellerTemplateList();
      this.data = list;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
._box {
  margin-bottom: 1rem;
  background-color: #fff;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
}
.p_main {
  .filter {
    text-align: center;
    padding: 1rem;
    ._box;

    .form {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      align-items: center;
      .fomr-item {
        width: 100%;
      }
      // .form-input {}

      .form-btn {
        border-radius: 22px;
        width: 80px;
      }
      /deep/ .el-form-item {
        margin-bottom: 0;
      }
    }
  }

  .info {
    padding: 1rem;
    ._box;

    line-height: 2rem;
  }

  // .trial-list {}

  .trial-statistics {
    float: right;
    display: flex;
    padding: 1rem;
    width: 30%;
    color: #57a0ff;
    justify-content: space-between;
    ._box;
  }
}

@media (max-width: 520px) {
  ::-webkit-scrollbar {
    display: none;
  }
  .status-fixed {
    margin-top: 60px;
    margin-bottom: 0;
    box-shadow: none;
    .status-bar {
      display: flex;
      white-space: nowrap;
      overflow: auto;

      &::after {
        content: none;
      }
      .status-item {
        .status-item_text {
          color: #57a0ff;
          margin: 0;
          background: none;
        }
      }
      .status-item-active {
        .status-item_text {
          background: none;
          border: none;

          &::after {
            left: 10%;
            width: 80%;
            background-color: #57a0ff;
          }
        }
      }
    }
  }
  ._box_r {
    box-shadow: none;
  }
  .p_main {
    padding-top: 68px;

    .filter {
      text-align: center;
      padding: 1rem;
      ._box_r;

      .form {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 0.5rem;
      }
    }

    .info {
      display: none;
    }

    .trial-list {
      overflow: hidden;
    }

    .trial-statistics {
      width: auto;
    }
  }
}
</style>
