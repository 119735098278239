<template>
  <div class="process-main">
    请根据物流情况确认收货，并按下一步要求提交晒图或客观的宝贝评价，注意看下商家给的评价内容再下一页，
    <span class="text-red-500">不要复制不应该有的内容，不然要承担相应的责任。</span>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    stepData: {
      type: Object,
    },
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        console.log(newValue, oldValue);
        this.$emit("update-done", true);
      },
      deep: true,
    },
  },
  created() {
    // this.data = this.stepData
  },
  mounted() {
    setTimeout(() => {
      this.$emit("update-done", true);
    }, 0);
  },
  data() {
    return {
      isDone: false,
      data: {},
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped></style>
