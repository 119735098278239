const originUser = {
    id: 0,
    role: "BUYER",
    username: "",
    avatar: require("@/assets/image/avatar.jpg"),
    token: "",
    cash: 0,
    isLogin: false,
    nickName: "",
    idcardNo: "",
    realName: "",
    phone: "",
    wxAccount: "",
    alipayAccount: "",
    wxReceiveQrcode: "",
    alipayReceiveQrcode: "",
    isRecomRewardOpen: 0,
}

export default {
    namespaced: true,
    state: () => (originUser),
    getters: {},
    mutations: {
        LOGIN (state, payload) {
            const originState = state
            Object.assign(state, payload);
            state.avatar = payload.img || require("@/assets/image/avatar.jpg");
            state.token = payload.token || originState.token;
            state.role = payload.userRole
            state.username = payload.loginName
            state.isLogin = true;

            // 将用户信息保存到本地存储
            localStorage.setItem("user", JSON.stringify(payload));
        },

    },
    actions: {
        LOGOUT ({ state }) {
            // 所有 state 的 key 赋值 undefined
            Object.keys(state).forEach(key => {
                state[key] = undefined;
            });
            console.log('originUser ' + JSON.stringify(originUser))
            Object.assign(state, originUser);
            state.token = ""
            state.username = ""
            state.isLogin = false;
            state.role = "BUYER"
            state.id = 0
            state.avatar = require("@/assets/image/avatar.jpg");

            // 将用户信息从本地存储中删除
            localStorage.removeItem("user");
        }
    },
};
