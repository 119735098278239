<template>
  <div class="p_main">
    <div class="filter">
      <el-form :inline="true" class="flex flex-row justify-start pl-4">
        <el-form-item label="标题">
          <el-input class="form-input" size="small" v-model="searchTitle" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="updateData">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table w-[100%]">
      <el-table :data="tableData" border style="width: 100%" v-loading="loading">
        <el-table-column prop="scope" label="类型" width="80" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.scope == 'PERSONAL'">个人</span>
            <span v-else-if="scope.row.scope == 'SYSTEM'">系统</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="标题">
          <template slot-scope="scope">
            <div class="flex flex-row items-center">
              <div @click="toDetail(scope.row)" class="cursor-pointer mr-auto">{{ scope.row.name }}</div>
              <div class="mr-4" v-if="!scope.row.isRead">
                <div class="w-3 h-3 bg-red-500 rounded-1/1"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="日期" width="180" align="center" />
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :current-page.sync="page.current"
      ></el-pagination>
    </div>

    <!-- <div class="explain">
      收件箱容量 <span>500</span> 条，当前接收 <span>{{ page.total }}</span> 条，还可以接收
      <span>{{ 500 - page.total }}</span> 条
    </div> -->
  </div>
</template>

<script>
import Tabbar from "@/components/message/Tabbar.vue";
import { noticeList, noticeRemindReceive } from "@/api/notice";
export default {
  components: {
    Tabbar,
  },
  data() {
    return {
      searchTitle: "",
      tableData: [],
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      loading: false,
    };
  },
  watch: {
    "page.current"() {
      this.updateData();
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  created() {
    if (!this.user.isLogin) {
      this.$router.push("/login");
    }
  },
  mounted() {
    this.updateData();
  },
  methods: {
    async updateData() {
      this.loading = true;
      try {
        const {
          data: { list, page },
        } = await noticeList(this.page, {
          name: this.searchTitle == "" ? undefined : this.searchTitle,
          scope: 'PERSONAL'
        });
        this.loading = false;
        this.tableData = list;
        this.page = page;
      } finally {
        this.loading = false;
      }
    },
    toDetail(item) {
      const path = "/user/message/" + item.id;
      this.$router.push({
        path,
        query: {
          item,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.p_main {
  padding: 1rem;
  background-color: #fff;

  .filter {
    text-align: center;
    .form-input {
      width: 200px;
    }
  }

  .table {
    margin-bottom: 1rem;
  }

  .pagination {
    display: flex;
    align-items: center;
  }

  .explain {
    padding: 1rem 0;
    text-align: center;
    span {
      color: #57a0ff;
    }
  }
}

@media (max-width: 520px) {
  .p_main {
    .tab {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      justify-content: flex-start;
      overflow-x: auto;
      .tab-item {
        width: 100px;
      }
    }
    ::-webkit-scrollbar {
      display: none;
    }

    .filter {
      text-align: left;
    }

    .pagination {
      text-align: center;
      display: block;
      .btns {
        margin-top: 1rem;
        .el-button {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
